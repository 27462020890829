/* eslint-disable no-whitespace-before-property */
/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import React, {useState, useEffect} from "react";
import {Store, Record} from "objectum-client";
import {setLocale, ObjectumApp, ObjectumRoute, Fade, Loading} from "objectum-react";
//import {useHistory} from "react-router-dom";
import {Link} from "react-router-dom";
import Sidebar from "react-sidebar";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Login from "./components/Login";
import Office from "./components/Office";
import Users from "./components/Users";
import User from "./components/User";
import Orgs from "./components/Orgs";
import Org from "./components/Org";
import AllNews from "./components/AllNews";
import News from "./components/News";
import New from "./components/New";
import Docs from "./components/Docs";
import Doc from "./components/Doc";
import Groups from "./components/Groups";
import Group from "./components/Group";
import Dicts from "./components/Dicts";
import Declarations from "./components/Declarations";
import Declaration from "./components/Declaration";
import PedagogDeclarations from "./components/PedagogDeclarations";
import ExpertDeclarations from "./components/ExpertDeclarations";
import ChiefDeclarations from "./components/ChiefDeclarations";
import Exams from "./components/Exams";
import Exam from "./components/Exam";
import ExamEmp from "./components/ExamEmp";
import Chat from "./components/Chat";
import Messages from "./components/Messages";
import PostMap from "./components/PostMap";
import Options from "./components/Options";
import ExamMove from "./components/ExamMove";
import Reports from "./components/Reports";
import Dashboard from "./components/Dashboard";

import ExamModel from "./models/ExamModel";
import EmpModel from "./models/EmpModel";
import DeclarationModel from "./models/DeclarationModel";
import DFounderModel from "./models/DFounderModel";

import mediator from "./modules/mediator";

import "./css/bootstrap.css";
import "objectum-react/lib/css/objectum.css";
import "objectum-react/lib/fontawesome/css/all.css";
import "./css/sapr.css";

const store = new Store ();
store.setUrl ("/api");
store.register ("exam", ExamModel);
store.register ("emp", EmpModel);
store.register ("declaration", DeclarationModel);
store.register ("d.founder", DFounderModel);
window.store = store;

Record.prototype.getLabel = function () {
	return this.name || this.id;
};

function LogoutButton ({store, setLoading}) {
	//let history = useHistory ();

	async function handleClick () {
		localStorage.setItem ("saprRefreshToken", "")
		document.location.href = '/login'
/*
		setLoading (true);
		store.map.record = {};
		localStorage.setItem ("saprRefreshToken", "");

		await store.auth ({
			username: "guest",
			password: require ("crypto").createHash ("sha1").update ("guest").digest ("hex").toUpperCase ()
		});
		history.push ("/login");
		setLoading (false);
*/
	};
	return <button className="btn btn-sm btn-link p-0 text-white" onClick={handleClick}>Выйти</button>;
};

/*
function LoginButton () {
	let history = useHistory ();

	async function handleClick () {
		history.push ("/login");
	};
	return <div className="d-flex align-items-center"><button className="btn btn-link px-3 text-white" onClick={handleClick}><i className="fas fa-user mr-2" />Вход / Регистрация</button></div>;
};
*/

export default function App () {
	let [loading, setLoading] = useState (true);
	let [username, setUsername] = useState ("");
	let [declarationStat, setDeclarationStat] = useState ({ total: 0 });
	let [unreadCount, setUnreadCount] = useState (null);
	let [refresh, setRefresh] = useState (false);
	let user = null;//<LoginButton />;

	setLocale ("ru");

	useEffect (() => {
		async function init () {
			window.OBJECTUM_APP.hideSeconds = true;
			window.OBJECTUM_APP.hidePrevNextMonthDaysFromCalendar = true
			store.requestWithoutQueue = true

			store.addListener ("connect", onConnect);
			store.addListener ("tokens", ({refreshToken}) => {
				if (store.username != "guest") {
					localStorage.setItem ("saprRefreshToken", refreshToken);
				}
			});
			let refreshToken = localStorage.getItem ("saprRefreshToken");

			if (refreshToken) {
				try {
					await store.auth ({refreshToken});
				} catch (err) {
					console.error ("refresh auth error", err);
				}
			}
			if (!store.accessToken) {
				if (document.location.pathname != "/" && document.location.pathname != "/login") {
					document.location.pathname = "/";
				}
				await store.auth ({
					username: "guest",
					password: require ("crypto").createHash ("sha1").update ("guest").digest ("hex").toUpperCase ()
				});
			}
			setLoading (false);
		};
		init ();

/*
		if (process.env.NODE_ENV !== "development" && document.location.pathname != "/" && document.location.pathname != "/login") {
			document.location.pathname = "/";
		}
*/
		// eslint-disable-next-line
	}, []);

	if (username != "guest") {
		user = <div className="mx-3 d-flex align-items-center text-white">
			<div className="border sapr-shadow rounded py-1 px-2 d-flex align-items-center">
				<div className="">Пользователь:</div>
				<div className="px-1 mx-2 border rounded">
					<div className="py-1"><strong>{store.username}</strong></div>
				</div>
				<div className="">
					<LogoutButton store={store} setLoading={setLoading} />
				</div>
			</div>
		</div>;
	}
	async function onConnect (opts) {
		mediator.init ({store});

		store.requestWithoutQueue = true

		let role = "is not null"

		if (!store.dict ["objectum.role"]) {
			await store.getDict('objectum.role')
		}
		if (store.roleCode == "pedmod") {
			role = `=${store.dict ["objectum.role"].pedagog.id}`;
		}
		if (store.roleCode == "chiefmod") {
			role = `=${store.dict ["objectum.role"].chief.id}`
		}
		let [userRecord, roleRecord, declarationStat, unreadRecs] = await Promise.all([
			store.userId ? store.getRecord (store.userId) : null,
			store.userId ? store.getRecord (store.roleId) : null,
			store.remote({ model: "declaration", method: "getStat" }),
			store.getRecs({ query: 'message.unread', role }),
			store.getDict ("org"),
			EmpModel.loadDicts (store)
		])
		if (userRecord) {
			mediator.record.user = userRecord
		}
		if (roleRecord) {
			mediator.record.role = roleRecord
		}
		setDeclarationStat(declarationStat);

		if (["admin", "pedmod", "chiefmod"].includes(store.roleCode)) {
			setUnreadCount(unreadRecs.reduce((sum, rec) => sum + rec.num, 0))
		}
		setInterval(async () => {
			setDeclarationStat(await store.remote({ model: "declaration", method: "getStat" }));
		}, 10 * 1000);
		setUsername (store.username);
	};
	function getMenuItems () {
		let items = [
			{label: "Вход / Регистрация", path: "/login", icon: "fas fa-user"}
		];
		if (store.roleCode == "admin") {
			items = [
				{label: "Личный кабинет", path: "/office", icon: "fas fa-user"},
				{label: "Организации", path: "/orgs", icon: "fas fa-school"},
//				{label: "Группы", path: "/groups", icon: "fas fa-address-book"},
				{label: "Заявления", path: "/declarations", icon: "fas fa-file"},
				{label: "Архив", path: "/declarations_archive", icon: "fas fa-file"},
				{label: "Архив КТ", path: "/declarations_archive_kt", icon: "fas fa-file"},
				{label: "Справочники", path: "/dicts", icon: "fas fa-book"},
				{label: "Экзамены", path: "/exams", icon: "fas fa-calendar-check"},
				{label: "Сообщения", path: "/messages", icon: "fas fa-comments"},
				{label: "Новости", path: "/news", icon: "fas fa-newspaper"},
				{label: "Документы", path: "/docs", icon: "fas fa-file"},
				{label: "Отчеты", path: "/reports", icon: "fas fa-print"},
				{label: "Пользователи", path: "/sapr_users", icon: "fas fa-user-check"},
				{label: "Параметры", path: "/options", icon: "fas fa-cogs"},
				{label: "Модели", path: "/models", icon: "fas fa-cogs"},
				{label: "Запросы", path: "/queries", icon: "fas fa-cogs"},
				{label: "Импорт CSS", path: "/import_css", icon: "fas fa-cogs"}
			];
		}
		if (store.roleCode == "chiefmod") {
			items = [
				{label: "Личный кабинет", path: "/office", icon: "fas fa-user"},
				{label: "Организации", path: "/orgs", icon: "fas fa-school"},
				{label: "Заявления", path: encodeURI (`/declarations#{"declarations":{"showFilters":true,"dockFilters":"top","filters":[["terr","=",""],["orgName","like",""]],"selected":null}}`), icon: "fas fa-file"},
				{label: "Архив", path: "/declarations_archive", icon: "fas fa-file"},
				{label: "Архив КТ", path: "/declarations_archive_kt", icon: "fas fa-file"},
				{label: "Справочники", path: "/dicts", icon: "fas fa-book"},
				{label: "Экзамены", path: "/exams", icon: "fas fa-calendar-check"},
				{label: "Сообщения", path: "/messages", icon: "fas fa-comments"},
				{label: "Новости", path: "/news", icon: "fas fa-newspaper"},
				{label: "Документы", path: "/docs", icon: "fas fa-file"},
				{label: "Отчеты", path: "/reports", icon: "fas fa-print"},
				{label: "Пользователи", path: "/sapr_users", icon: "fas fa-user-check"},
				{label: "Параметры", path: "/options", icon: "fas fa-cogs"},
			];
		}
		if (store.roleCode == "pedmod") {
			items = [
				{label: "Личный кабинет", path: "/office", icon: "fas fa-user"},
				{label: "Организации", path: "/orgs", icon: "fas fa-school"},
				{label: "Заявления", path: "/declarations", icon: "fas fa-file"},
				{label: "Архив", path: "/declarations_archive", icon: "fas fa-file"},
				{label: "Справочники", path: "/dicts", icon: "fas fa-book"},
				{label: "Экзамены", path: "/exams", icon: "fas fa-calendar-check"},
				{label: "Сообщения", path: "/messages", icon: "fas fa-comments"},
				{label: "Новости", path: "/news", icon: "fas fa-newspaper"},
				{label: "Документы", path: "/docs", icon: "fas fa-file"},
				{label: "Отчеты", path: "/reports", icon: "fas fa-print"},
				{label: "Пользователи", path: "/sapr_users", icon: "fas fa-user-check"},
				{label: "Параметры", path: "/options", icon: "fas fa-cogs"},
			];
		}
		if (store.roleCode == "terrmod") {
			items = [
				{label: "Личный кабинет", path: "/office", icon: "fas fa-user"},
				{label: "Заявления", path: encodeURI (`/declarations#{"declarations":{"showFilters":true,"dockFilters":"top","filters":[["terr","=",""],["orgName","like",""]],"selected":null}}`), icon: "fas fa-file"},
				{label: "Сообщения", path: "/messages", icon: "fas fa-comments"},
				{label: "Пользователи", path: "/sapr_users", icon: "fas fa-user-check"},
			];
		}
		if (store.roleCode == "observer") {
			items = [
				{label: "Личный кабинет", path: "/office", icon: "fas fa-user"},
				{label: "Заявления", path: encodeURI (`/declarations#{"declarations":{"showFilters":true,"dockFilters":"top","filters":[["terr","=",""],["orgName","like",""]],"selected":null}}`), icon: "fas fa-file"},
				{label: "Архив", path: "/declarations_archive", icon: "fas fa-file"},
				{label: "Экзамены", path: "/exams", icon: "fas fa-calendar-check"},
				{label: "Пользователи", path: "/sapr_users", icon: "fas fa-user-check"},
			];
		}
		if (store.roleCode == "expert") {
			items = [
				{label: "Личный кабинет", path: "/office", icon: "fas fa-user"},
				{label: "Основные сведения", path: `/sapr_user/${store.userId}`, icon: "fas fa-user"},
				{label: "Заявления", path: "/expert_declarations", icon: "fas fa-file"}
			];
		}
		if (store.roleCode == "chief") {
			items = [
				{label: "Личный кабинет", path: "/office", icon: "fas fa-user"},
				{label: "Основные сведения", path: `/sapr_user/${store.userId}`, icon: "fas fa-user"},
				{label: "Заявления", path: "/chief_declarations", icon: "fas fa-file"},
				{label: "Чат", path: "/chat", icon: "fas fa-comments"}
			];
		}
		if (store.roleCode == "pedagog") {
			items = [
				{label: "Личный кабинет", path: "/office", icon: "fas fa-user"},
				{label: "Основные сведения", path: `/sapr_user/${store.userId}`, icon: "fas fa-user"},
				{label: "Заявления", path: "/pedagog_declarations", icon: "fas fa-file"},
				{label: "Чат", path: "/chat", icon: "fas fa-comments"}
			];
		}
		if (store.roleCode == "pedObserver") {
			items = [
				{label: "Личный кабинет", path: "/office", icon: "fas fa-user"},
				{label: "Заявления", path: "/declarations", icon: "fas fa-file"},
				{label: "Архив", path: "/declarations_archive", icon: "fas fa-file"}
			];
		}
		return items;
	}

	function renderMenu () {
		let items = getMenuItems ();
		let elements = [];

		function hashChange () {
			setRefresh (!refresh);
			setTimeout (() => window.dispatchEvent (new HashChangeEvent ("hashchange")), 200);
		}
		items.forEach ((item, i) => {
			let selected = false;

			if (`${window.location.pathname}${window.location.hash}` == item.path) {
				selected = true;
			}
			elements.push (<Link
				key={i}
				className={`dropdown-item nav-item nav-link font-weight-bold ${selected ? "text-white bg-info" : "text-dark"} pr-4`} to={item.path}
				onClick={hashChange}
			>
				<i className={`${item.icon || ""} menu-icon mr-2`} /> {item.label}
				{item.label == "Заявления" && declarationStat.total
					? <span className="badge badge-primary ml-1">{declarationStat.total}</span>
					: item.label == "Сообщения" && unreadCount
						? <span className="badge badge-primary ml-1">{unreadCount}</span>
						: null
				}
			</Link>);

			if (["chiefmod", "terrmod"].indexOf (store.roleCode) > -1 && item.label == "Заявления") {
				elements = [...elements, [{
					code: "await", name: "Поданные"
				}, {
					code: "editing", name: "Редактируется"
				}, {
					code: "match", name: "Актуальные"
				}, {
					code: "commission", name: `Направлен на ком.`
				}, {
					code: "chief", name: "Руководители ОО"
				}, {
					code: "certificationFailed", name: "Аттестация не сост."
				}, {
					code: "reserve", name: "Кадровый резерв"
				}].map ((o, j) => {
					let path = encodeURI (`/declarations#{"declarations":{"showFilters":true,"dockFilters":"top","filters":[["state","=",${store.dict ["d.declaration.state"][o.code].id}]],"selected":null}}`);
					let selected = false;

					if (`${window.location.pathname}${window.location.hash}` == path) {
						selected = true;
					}
					let num = declarationStat[store.dict ["d.declaration.state"][o.code].id];

					return <Link
						key={`d-${j}`} className={`dropdown-item nav-item nav-link ${selected ? "text-white bg-info" : "text-dark"} pr-4`} style={{marginLeft: "3em"}}
						to={path}
						onClick={hashChange}
					>{o.name} {num ? <span className="badge badge-primary ml-1">{num}</span> : null}</Link>;
				})];
			}
		});
		return <div className="sapr-sidebar">
			<div className="p-1 text-info sapr-shadow border-bottom border-primary d-flex align-items-center justify-content-center mb-2" style={{height: "3em"}}>
				<div><h4>ИС САПР</h4></div>
			</div>
			{elements}
		</div>;
	};
	function renderPosition () {
		let items = getMenuItems ();
		let position = [], path = document.location.pathname, positionEl = [];

		if (path != "/") {
			if (path == "/office") {
				position = [
					{label: "Главная", path: "/"},
					{label: "Личный кабинет", path: "/office"}
				];
			} else
			if (path == "/login") {
				position = [
					{label: "Главная", path: "/"},
					{label: "Вход / Регистрация", path: "/login"}
				];
			} else
			if (path == "/all_news") {
				position = [
					{label: "Главная", path: "/"},
					{label: "Новости", path: "/all_news"}
				];
			} else {
				let item = items.find (item => item.path == path);

				position = [
					{label: "Главная", path: "/"},
					{label: "Личный кабинет", path: "/office"}
				];
				if (item) {
					position = [...position, item];
				} else {
					path = path.split ("/")[1];

					if (path == "model_list") {
						position = [
							...position,
							{label: "Справочники", path: "/dicts"},
							{label: "Справочник"}
						];
					}
					if (path == "org") {
						position = [
							...position,
							{label: "Организации", path: "/orgs", icon: "fas fa-school"},
							{label: "Организация"}
						];
					}
					if (path == "exam") {
						position = [
							...position,
							{label: "Экзамены", path: "/exams"},
							{label: "Экзамен"}
						];
					}
					if (path == "exam_emp") {
						position = [
							...position,
							{label: "Экзамены", path: "/exams"},
							{label: "Участники экзамена"}
						];
					}
					if (path == "exam_move") {
						position = [
							...position,
							{label: "Экзамены", path: "/exams"},
							{label: "Перемещение участников"}
						];
					}
					if (path == "group") {
						position = [
							...position,
							{label: "Группы", path: "/groups", icon: "fas fa-address-book"},
							{label: "Группа"}
						];
					}
					if (path == "emp") {
						if (store.roleCode == "pedagog") {
							position = [
								...position,
								{label: "Основные сведения"}
							];
						} else
						if (store.roleCode == "manager" || store.roleCode == "expert") {
							position = [
								...position,
								{label: "Основные сведения"}
							];
						} else {
							position = [
								...position,
								{label: "Педагоги", path: "/emps", icon: "fas fa-user-friends"},
								{label: "Педагог"}
							];
						}
					}
					if (path == "declaration") {
						position = [
							...position,
							{label: "Заявления", path: ["manager", "expert"].indexOf (store.roleCode) > -1 ? "/expert_declarations" : "/declarations", icon: "fas fa-file"},
							{label: "Заявление"}
						];
					}
					if (path == "exam_declaration") {
						position = [
							...position,
							{label: "Экзамены", path: "/exams"},
							{label: "Участники экзамена", path: `/exam_emp/${mediator.get ("exam")}`, icon: "fas fa-calendar-check"},
							{label: "Заявление"}
						];
					}
					if (path == "sapr_user") {
						if (store.roleCode == "pedagog" || store.roleCode == "chief") {
							position = [
								...position,
								{label: "Основные сведения"}
							];
						} else {
							position = [
								...position,
								{label: "Пользователи", path: "/sapr_users"},
								{label: "Пользователь"}
							];
						}
					}
					if (["pedagog_declarations", "expert_declarations", "chief_declarations"].indexOf (path) > -1) {
						position = [
							...position,
							{label: "Заявления"}
						];
					}
					if (path == "chat") {
						position = [
							...position,
							{label: "Чат"}
						];
					}
					if (path == "messages") {
						position = [
							...position,
							{label: "Сообщения"}
						];
					}
					if (path == "post_map") {
						position = [
							...position,
							{label: "Карта должностей"}
						];
					}
				}
			}
		}
		if (!position.length) {
			position = [	{label: "Главная"}];
		}
		position.forEach ((item, i) => {
			let disabled = i == position.length - 1;

			if (i) {
				positionEl.push (<span key={"d" + i} className={disabled ? "position-gray" : "text-white"}><i className="fas fa-chevron-right mx-2" /></span>);
			}
			positionEl.push (disabled ? <span className="position-gray" key={i}>{item.label}</span> : <Link key={i} to={item.path} className="text-white">{item.label}</Link>);
		});
		return <div className="text-white">
			{positionEl}
		</div>;
	};
	function onCustomRender ({content}) {
		if (!username) {
			return <div />;
		}
		let pathname = window.location.pathname;
		let div = <div>
			<div className={`fixed-top ${username == "guest" ? "" : "sapr-header-sidebar"}`}>
				{username == "guest" ? <h2 className="m-0 p-3 bg-white">Система аттестации педагогических работников</h2> : ""}
				<div className="bg-primary sapr-shadow d-flex align-items-center justify-content-between pl-3" style={{height: "3em"}}>
					{renderPosition ()}
					{user}
				</div>
			</div>
			<div className={`${["/declarations", "/declarations_archive", "/declarations_archive_kt", "/expert_declarations", "/messages", "/post_map"].indexOf (pathname) > -1 ? "container-fluid" : "container"}`}>
				<div className="bg-white sapr-shadow p-4 rounded" style={{marginBottom: "5em", marginTop: username == "guest" ? "9em" : "4em"}}>
					<Fade>{content}</Fade>
				</div>
			</div>
			<Footer />
		</div>;

		if (username == "guest") {
			window.OBJECTUM_APP.sidebar = false;
			return div;
		} else {
			window.OBJECTUM_APP.sidebar = true;
			return <Sidebar
				sidebar={renderMenu ()}
				open={true}
				docked={true}
				sidebarClassName="bg-white"
				shadow={true}
				contentId="objectum-sidebar-content"
			>
				{div}
			</Sidebar>;
		}
	};
	let opts = {
		store,
		name: process.env.REACT_APP_NAME || "Система аттестации педагогических работников",
		version: process.env.REACT_APP_VERSION,
		locale: "ru",
		onCustomRender
	};
	if (process.env.NODE_ENV === "development") {
/*
		opts.username = "admin";
		opts.password = require ("crypto").createHash ("sha1").update ("admin").digest ("hex").toUpperCase ();
*/
/*
		opts.username = "expert";
		opts.password = require ("crypto").createHash ("sha1").update ("1").digest ("hex").toUpperCase ();
*/
/*
		opts.username = "ped";
		opts.password = require ("crypto").createHash ("sha1").update ("1").digest ("hex").toUpperCase ();
*/
/*
		opts.username = "oo";
		opts.password = require ("crypto").createHash ("sha1").update ("1").digest ("hex").toUpperCase ();
*/
/*
		opts.username = "pedmod";
		opts.password = require ("crypto").createHash ("sha1").update ("1").digest ("hex").toUpperCase ();
*/
/*
		opts.username = "chiefmod";
		opts.password = require ("crypto").createHash ("sha1").update ("1").digest ("hex").toUpperCase ();
*/
/*
		opts.username = "048-258-859-99";
		opts.password = require ("crypto").createHash ("sha1").update ("1").digest ("hex").toUpperCase ();
*/
	}
	if (loading) {
		return <Loading container />;
	}
	return <ObjectumApp {...opts}>
		<ObjectumRoute exact path="/" render={props => <Home {...props} siteKey="6LdFC7IbAAAAAN8i5EwifeJgYyG1iOsgHSC8M-1P" store={store} />} />
		<ObjectumRoute path="/login" render={props => <Login {...props} siteKey="6LdFC7IbAAAAAN8i5EwifeJgYyG1iOsgHSC8M-1P" store={store} />} />
		<ObjectumRoute path="/office" render={props => store.roleCode == "chiefmod" ? <Dashboard {...props} store={store} /> : <Office {...props} store={store} />} />
		<ObjectumRoute path="/sapr_user/:rid" render={props => <User {...props} store={store} />} />
		<ObjectumRoute path="/declaration/:rid" render={props => <Declaration {...props} store={store} />} />
		<ObjectumRoute path="/pedagog_declarations" render={props => <PedagogDeclarations {...props} store={store} />} />
		<ObjectumRoute path="/expert_declarations" render={props => <ExpertDeclarations {...props} store={store} />} />
		<ObjectumRoute path="/chat" render={props => <Chat {...props} store={store} />} />
		<ObjectumRoute path="/chief_declarations" render={props => <ChiefDeclarations {...props} store={store} />} />
		<ObjectumRoute path="/all_news" render={props => <AllNews {...props} store={store} />} />

		{["expert", "chief", "pedagog", "guest"].indexOf (store.roleCode) == -1 ? <div>
			<ObjectumRoute path="/news" render={props => <News {...props} store={store} />} />
			<ObjectumRoute path="/new/:rid" render={props => <New {...props} store={store} />} />
			<ObjectumRoute path="/docs" render={props => <Docs {...props} store={store} />} />
			<ObjectumRoute path="/doc/:rid" render={props => <Doc {...props} store={store} />} />
			<ObjectumRoute path="/sapr_users" render={props => <Users {...props} store={store} />} />
			<ObjectumRoute path="/orgs" render={props => <Orgs {...props} store={store} />} />
			<ObjectumRoute path="/org/:rid" render={props => <Org {...props} store={store} />} />
			<ObjectumRoute path="/groups" render={props => <Groups {...props} store={store} />} />
			<ObjectumRoute path="/group/:rid" render={props => <Group {...props} store={store} />} />
			<ObjectumRoute path="/dicts" render={props => <Dicts {...props} store={store} />} />
			<ObjectumRoute path="/declarations" render={props => <Declarations {...props} store={store} archivePlace="is null" />} />
			<ObjectumRoute path="/declarations_archive" render={props => <Declarations {...props} store={store} archivePlace="=1" />} />
			<ObjectumRoute path="/declarations_archive_kt" render={props => <Declarations {...props} store={store} archivePlace="=2" />} />
			<ObjectumRoute path="/exams" render={props => <Exams {...props} store={store} />} />
			<ObjectumRoute path="/exam/:rid" render={props => <Exam {...props} store={store} />} />
			<ObjectumRoute path="/exam_emp/:rid" render={props => <ExamEmp {...props} store={store} />} />
			<ObjectumRoute path="/messages" render={props => <Messages {...props} store={store} />} />
			<ObjectumRoute path="/post_map" render={props => <PostMap {...props} store={store} />} />
			<ObjectumRoute path="/options" render={props => <Options {...props} />} />
			<ObjectumRoute path="/exam_move/:rid" render={props => <ExamMove {...props} store={store} />} />
			<ObjectumRoute path="/reports" render={props => <Reports {...props} />} />
		</div> : null}
	</ObjectumApp>;
};
