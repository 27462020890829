/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {useState} from "react";
import {Field, Form, goRidLocation} from "objectum-react";
import {store} from "../modules/mediator";
import PageTitle from "./PageTitle";

export default function Exam (props) {
	let [rid, setRid] = useState ((rid => rid == "new" ? null : rid) (props.match.params.rid.split ("#")[0]));

	return <div>
		<PageTitle>Экзамен</PageTitle>
		<div className="bg-white border shadow-sm">
			<Form store={store} rsc="record" rid={rid} mid="exam" onCreate={rid => {
				setRid (rid);
				goRidLocation (props, rid);
			}} disabled={store.roleCode == "observer"}>
				<Field property="date" showTime />
				<Field property="capacity" />
				<Field property="type" />
			</Form>
		</div>
	</div>;
};
