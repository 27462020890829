/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import PageTitle from "./PageTitle";
import ExamField from "./ExamField";
import {Action, Field, Grid, Loading, Form, Group} from "objectum-react";
import mediator, {store, hasDeclaration, getOptions, getJulianDay} from "../modules/mediator";

export default function ChiefDeclarations (props) {
	let [loading, setLoading] = useState (true);
	let [emp, setEmp] = useState (null);
	let [examRecords, setExamRecords] = useState ([]);
	let [refresh, setRefresh] = useState (false);
	let [declarationRecord, setDeclarationRecord] = useState (null);
	let [stateDescription, setStateDescription] = useState ("");
	let [hasDeclarationMessage, setHasDeclarationMessage] = useState ("");
	let [options, setOptions] = useState ({});
	let history = useHistory ();

	useEffect (() => {
		async function load () {
			await store.getDict ("d.declaration.state");
			let record = await store.getRecord (store.userId);
			setEmp (record.emp);

			setOptions (await getOptions ());
			await store.getDict ("d.exam.type");
			let examRecords = await store.getRecords ({
				model: "exam"
/*
				filters: [
					["date", ">", new Date ().toLocaleDateString ()]
				]
*/
			});
			let declarationRecords = await store.getRecords ({
				model: "declaration",
				filters: [
					["chiefExam", "in", examRecords.map (record => record.id)]
				]
			});
			examRecords = examRecords.filter (examRecord => {
				let empNum = declarationRecords.filter (declarationRecord => declarationRecord.chiefExam == examRecord.id).length;

				if (examRecord.capacity <= empNum) {
					return false;
				}
				examRecord.empNum = empNum;

				return true;
			});
			setExamRecords (examRecords);

			await checkHasDeclaration();

			let recs = await store.getRecs ({query: "declaration.chief", emp: record.emp});

			if (recs.length) {
				recs.forEach (rec => delete store.map ["record"][rec.id]);
				history.push ("/chief_declarations#" + encodeURI (`{"declarations":{"selected":0}}`));
			}
			setLoading (false);
		}
		load ();
		// eslint-disable-next-line
	}, []);

	async function checkHasDeclaration() {
		let hasDeclarationId = await hasDeclaration ();

		if (hasDeclarationId) {
			let record = await store.getRecord (hasDeclarationId);
			let year = new Date ().getMonth () > 8 ? new Date ().getFullYear () + 1 : new Date ().getFullYear ();
			let chiefExam = "";

			if (record.chiefExam) {
				let chiefExamRecord = await store.getRecord (record.chiefExam);
				chiefExam = chiefExamRecord.date.toLocaleDateString ();
			}
			setHasDeclarationMessage (<div>
				<div>Вы уже подавали заявление в текущем учебном году</div>
				<div>id: {hasDeclarationId}, дата: {record.date.toLocaleDateString ()}{chiefExam ? `, экзамен: ${chiefExam}` : ""}</div>
				<div>Повторно заявление Вы можете подать {`01.09.${year}`}</div>
			</div>);
			return true;
		}
		return false;
	}

	async function onCreate ({confirm}) {
		let userRecord = await store.getRecord (store.userId);

		if (!userRecord.email) {
			throw new Error ("Заполните: Электронная почта");
		}
		let errorFields = await store.remote ({
			model: "emp",
			method: "checkFields",
			id: emp,
			fields: ["surname", "forename", "sex", "citizenship", "address", "confirmFile", "drugsFile", "noCrimeFile", "programFile", "pedExp"]
		});
		if (errorFields.length) {
			throw new Error ("Заполните: " + errorFields.join (", "));
		}
		if (await checkHasDeclaration()) {
			return;
		}
		if (!(await confirm ("Пожалуйста, убедитесь что все данные заполнены правильно. В случае отказа повторная подача на аттестацию возможна лишь через год. Подать заявление сейчас?"))) {
			return;
		}
		await store.remote ({
			model: "declaration",
			method: "submitChief",
			userId: mediator.record.user.id,
			stateId: store.dict ["d.declaration.state"]["await"].id
		});
		setRefresh (!refresh);
/*
		let now = new Date (await store.remote ({model: "admin", method: "currentUTCTimestamp"}));
		await store.startTransaction ("Подача заявления");

		try {
			await store.createRecord ({
				_model: "declaration",
				date: now,
				emp,
				state: store.dict ["d.declaration.state"]["await"].id,
				chief: true
			});
			await store.commitTransaction ();
			setRefresh (!refresh);
		} catch (err) {
			await store.rollbackTransaction ();
			throw err;
		}
*/
	}
	async function onFinish () {
		await store.remote ({
			model: "declaration",
			method: "canSubmitChief",
			userId: mediator.record.user.id
		});
		await store.transaction (async () => {
			declarationRecord.state = store.dict ["d.declaration.state"]["await"].id;
			await declarationRecord.sync ();
		});
		setRefresh (!refresh);
	}
	if (loading) {
		return <Loading />;
	}
	if (!emp) {
		return <div className="alert alert-danger">Заполните в основных сведениях Ф.И.О. и др.</div>
	}
	return <div>
		<PageTitle>Заявления</PageTitle>
		<div className="mb-2 mt-4 d-flex">
			<Action
				icon="fas fa-plus"
				label="Направить документы на проверку"
				onClick={onCreate}
				disabled={!!hasDeclarationMessage}
			/>
			{declarationRecord?.state == store.dict ["d.declaration.state"]["editing"].id && <div className="ml-1">
				<Action
					icon="fas fa-plus"
					label="Завершить редактирование заявления"
					onClick={onFinish}
					confirm
				/>
			</div>}
		</div>
		{hasDeclarationMessage ? <div className="my-2 alert alert-danger">{hasDeclarationMessage}</div>: null}
		{declarationRecord ? <div className="my-2 border rounded">
			<Form store={store} rsc="record" rid={declarationRecord.id} mid="declaration" hideLogButton onSave={() => setRefresh (!refresh)} onValidate={async ({values, errors}) => {
				if (values.hasOwnProperty ("chiefExam")) {
					let records = await store.getRecords ({model: "declaration", filters: [["id", "=", declarationRecord.id]]});

/*
					if (records [0].examScore && values.chiefExam != records [0].chiefExam) {
						errors.chiefExam = "Нельзя изменить дату экзамена после выставления баллов";
					}
*/
					if (values.chiefExam && values.chiefExam != records [0].chiefExam) {
						let examRecord = await store.getRecord (values.chiefExam);
						let declarationRecords = await examRecord.getDeclarationRecords ();

						if (declarationRecords.length >= examRecord.capacity) {
							errors.chiefExam = "Отсутствуют места на выбранную дату экзамена";
						}
					}
				}
			}}>
				{(declarationRecord?.state == store.dict ["d.declaration.state"]["match"].id || declarationRecord?.state == store.dict ["d.declaration.state"]["editing"].id) ? <div className="row">
					<div className="col-8">
						{(declarationRecord.chiefExam || declarationRecord.state != store.dict ["d.declaration.state"]["match"].id)
							? <Field property="chiefExam" disabled />
							: <div>
								<ExamField label="Выберите дату прохождения диагностики управленческих компетенций" property="chiefExam" records={examRecords.filter (record => {
									let valid = record.type == store.dict ["d.exam.type"]["chief"].id;

									if (valid) {
										valid = (options.chiefExamDays && (getJulianDay (record.date) - getJulianDay (declarationRecord.date)) <= options.chiefExamDays);
									}
									return valid;
								})} />
								<div className="alert alert-danger">После выбора и сохранения даты экзамена, ее нельзя изменить</div>
							</div>
						}
					</div>
				</div> : null}
				<Group label={"Статус рассмотрения заявления"}>
					<div className="row">
						<div className="col-4">
							<Field property="state" disabled />
							<p>{stateDescription}</p>
						</div>
						<div className="col-3">
							<Field property="stateDate" showTime disabled />
						</div>
						<div className="col">
							<Field property="comment" disabled />
						</div>
					</div>
				</Group>
				<Group label={"Результаты диагностики управленческих компетенций"}>
					<div className="row">
						<div className="col-4">
							<Field property="chiefExam" label="Дата сдачи" disabled />
						</div>
						<div className="col-3">
							<Field property="examScore" label={"Количество баллов"} disabled />
						</div>
						<div className="col">
							<Field property="examFile" disabled />
						</div>
					</div>
				</Group>
				<div className="border rounded mt-1 p-1">
					<div className="row">
						<div className="col-4">
							<Field property="chiefResult" disabled />
						</div>
						<div className="col">
							<Field property="chiefResultFile" disabled />
						</div>
					</div>
				</div>
			</Form>
		</div> : null}
		<Grid {...props} id="declarations" store={store} query="declaration.chief" params={{emp}} refresh={refresh} onSelect={async (id) => {
			let record = await store.getRecord (id);
			setDeclarationRecord (record);
			let stateRecord = await store.getRecord (record.state);
			setStateDescription (stateRecord.description);
		}} />
	</div>;
};
