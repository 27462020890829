/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {useState, useEffect} from "react";
import {DictField} from "objectum-react";
import Month from "./Month";


export default function ExamField ({property, label, records, value:originalValue, bg, onChange, error}) {
	let [value, setValue] = useState (originalValue);
	let cd = new Date ();
	cd.setDate (1);
	let cd2 = new Date (cd);
	cd2.setMonth (cd2.getMonth () + 1);
	let cd3 = new Date (cd);
	cd3.setMonth (cd3.getMonth () + 2);
	let cd4 = new Date (cd);
	cd4.setMonth (cd4.getMonth () + 3);

	useEffect (() => {
		setValue (originalValue);
	}, [originalValue]);

	function onChangeLocal (value) {
		setValue (value);
		onChange ({code: property, value});
	};
	return <div className="border rounded p-1">
		<DictField label={label} property={property} records={records.filter (record => {
			let valueDate = records.find (record => record.id == value)?.date;
			if (valueDate &&
				valueDate.getMonth () == record.date.getMonth () &&
				valueDate.getDate () == record.date.getDate () &&
				valueDate.getFullYear () == record.date.getFullYear ()
			) {
				return true;
			}
			return false;
		})} value={value} onChange={({value}) => {
			setValue (value);
			onChange ({code: property, value});
		}} />
		<div className="d-flex">
			<Month date={cd} records={records} bg={bg} value={value} onChange={onChangeLocal} />
			<Month date={cd2} records={records} bg={bg} value={value} onChange={onChangeLocal} />
		</div>
		<div className="d-flex">
			<Month date={cd3} records={records} bg={bg} value={value} onChange={onChangeLocal} />
			<Month date={cd4} records={records} bg={bg} value={value} onChange={onChangeLocal} />
		</div>
		{error && <div className="alert alert-danger">{error}</div>}
	</div>;
}
