import { useState, useContext } from 'react'
import DashboardContext from './DashboardContext'
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts'

const COLORS = ['#0088FE', '#00C49F']
const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    )
}

export default function DashboardNoSubmit() {
    const [listVisible, setListVisible] = useState(false)
    const { userRecs, declarationRecs, startDate, endDate, founder } = useContext(DashboardContext)
    let submitCount = 0,
        noSubmitCount = 0
    const filteredDeclarationRecs = declarationRecs.filter(rec => rec.date >= startDate && rec.date <= endDate)
    const empHasDeclaration = filteredDeclarationRecs.reduce((m, rec) => {
        m[rec.emp] = true
        return m
    }, {})
    const filteredUserRecs = userRecs.filter(rec => {
        let result = rec.regDate >= startDate && rec.regDate <= endDate

        if (founder && rec.founder !== founder) {
            result = false
        }
        return result
    })
    const noSubmitUserRecs = []

    filteredUserRecs.forEach(userRec => {
        if (empHasDeclaration[userRec.emp]) {
            submitCount++
        } else {
            noSubmitCount++
            noSubmitUserRecs.push(userRec)
        }
    })
    const data = [
        { name: 'Подали', value: submitCount },
        { name: 'Не подали', value: noSubmitCount },
    ]

    return (
        <div className="p-1 border bg-white rounded">
            <div style={{ height: '16em' }}>
                <h5>Не подавшие заявления</h5>
                <div className="d-flex align-items-center">
                    <div style={{ height: '14em', width: '60%' }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart width={400} height={400}>
                                <Pie
                                    data={data}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    label={renderCustomizedLabel}
                                    outerRadius={80}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {data.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                    <div>
                        <div className="mr-2" style={{ color: '#0088FE' }}>
                            <b>{submitCount}</b> подали
                        </div>
                        <div className="mr-2" style={{ color: '#00C49F' }}>
                            <b>{noSubmitCount}</b> не подали
                        </div>
                        <div>
                            <a
                                href="/"
                                onClick={event => {
                                    event.preventDefault()

                                    if (!listVisible && !noSubmitUserRecs.length) {
                                        return
                                    }
                                    setListVisible(!listVisible)
                                }}
                                style={{color: 'red'}}
                            >
                                <u>Список</u>
                            </a>
                            {listVisible ? (
                                <div style={{ position: 'absolute', zIndex: 999 }} className="border bg-white p-2 shadow-sm mb-2">
                                    {noSubmitUserRecs.map(userRec => {
                                        return (
                                            <div>
                                                <a href={`/sapr_user/${userRec.id}`} target="_blank" rel="noreferrer">
                                                    {userRec.fio}
                                                </a>
                                            </div>
                                        )
                                    })}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
