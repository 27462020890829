/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {useContext} from 'react'
import DashboardContext from "./DashboardContext"
import {getJulianDay} from "../modules/mediator"

export default function DashboardFounder () {
	const {founderRecs} = useContext(DashboardContext)

	return <div className="border p-1 bg-white rounded" style={{width: "67%"}}>
		<h5>Сроки подачи заявлений по учредителям</h5>
		<div className="overflow-auto border" style={{height: '25em'}}>
			{founderRecs.map(rec => {
				let value = 0
				let cd = new Date()

				if (rec.startDate && rec.endDate && cd > rec.startDate && cd < rec.endDate) {
					const jd1 = getJulianDay(rec.startDate)
					const jd2 = getJulianDay(rec.endDate)
					const jd = getJulianDay(cd)

					value = (jd - jd1) / (jd2 - jd1)
				}
				return <div key={rec.id}>
					<div className="d-flex justify-content-between">
						<div>{rec.startDate ? rec.startDate.toLocaleDateString () : null}</div>
						<div>{rec.name}</div>
						<div>{rec.endDate ? rec.endDate.toLocaleDateString () : null}</div>
					</div>
					<progress value={value} style={{width: '100%'}} />
				</div>
			})}
		</div>
	</div>;
}
