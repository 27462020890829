/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {useState} from "react";
import {Field, Form, goRidLocation} from "objectum-react";
import {store} from "../modules/mediator";
import PageTitle from "./PageTitle";

export default function Doc (props) {
	let [rid, setRid] = useState ((rid => rid == "new" ? null : rid) (props.match.params.rid.split ("#")[0]));

	async function onCreate (rid) {
		setRid (rid);
		goRidLocation (props, rid);
	};

	return <div>
		<PageTitle>Документ</PageTitle>
		<div className="bg-white border shadow-sm">
			<Form store={store} rsc="record" rid={rid} mid="doc" onCreate={onCreate} defaults={{chief: store.roleCode == "chiefmod" ? 1 : 0}}>
				<Field property="file" />
				<Field property="chief" disabled={store.roleCode != "admin"} />
				<Field property="type" disabled={store.roleCode != "admin"} />
			</Form>
		</div>
	</div>;
};
