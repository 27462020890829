/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import { useState, useContext } from 'react'
import Month from "./Month"
import { Action } from "objectum-react";
import DashboardContext from "./DashboardContext";

export default function DashboardExam () {
	let cd = new Date ()
	cd.setDate (1)
	const [date, setDate] = useState(cd)
	const { examRecords } = useContext(DashboardContext)

	return <div className="border p-1 mr-1 bg-white rounded" style={{width: "33%"}}>
		<div className="d-flex align-items-center justify-content-between mb-1">
			<h5 className="mr-5">Экзамены</h5>
			<div className="d-flex">
				<Action icon="fas fa-angle-left" onClick={() => {
					const d = new Date(date)
					d.setMonth(d.getMonth() - 1)
					setDate(d)
				}} />
				<Action icon="fas fa-angle-right" onClick={() => {
					const d = new Date(date)
					d.setMonth(d.getMonth() + 1)
					setDate(d)
				}} />
			</div>
		</div>
		<Month date={date} records={examRecords} onChange={() => {}} />
	</div>;
}
