/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {Link} from "react-router-dom";
import {Fade} from "objectum-react";
import {store} from "../modules/mediator";

export default function Office () {
	function renderCard ({icon, label, to, cls}) {
		return <div className="col-6">
			<div className={"office-card d-flex " + cls}>
				<div className="office-card-image d-flex align-items-center justify-content-center">
					<Link className="nav-link" to={to}>
						<div className="office-card-icon d-flex align-items-center justify-content-center"><i className={`text-white fas fa-${icon} fa-4x`} /></div>
					</Link>
				</div>
				<div className="px-2 d-flex align-items-center">
					<Link className="nav-link" to={to}>
						<strong><h4>{label}</h4></strong>
					</Link>
				</div>
			</div>
		</div>;
	}

	function renderOffice (items) {
		items = items.reduce ((result, value, index, array) => {
			if (index % 2 === 0) {
				result.push (array.slice (index, index + 2));
			}
			return result;
		}, []);

		let elements = [];

		items.forEach ((item, i) => {
			elements.push (<div className="my-3" key={i}>
				<div className="row no-gutters justify-content-between">
					{renderCard ({...item [0], cls: "mr-4"})}
					{item.length == 2 ? renderCard ({...item [1], cls: "ml-4"}) : <div />}
				</div>
			</div>);
		});
		return <div><Fade>
			{elements}
		</Fade></div>;
	}

	if (store.username == "guest") {
		return <div>guest</div>;
	}
	if (store.roleCode == "pedmod" || store.roleCode == "chiefmod" || store.roleCode == "admin") {
		return renderOffice ([
			{
				icon: "school",
				label: <span>Организации</span>,
				to: "/orgs"
			},
			{
				icon: "file",
				label: <span>Заявления</span>,
				to: "/declarations"
			},
			{
				icon: "book",
				label: <span>Справочники</span>,
				to: "/dicts"
			},
			{
				icon: "calendar-check",
				label: <span>Экзамены</span>,
				to: "/exams"
			},
			{
				icon: "fas fa-comments",
				label: <span>Сообщения</span>,
				to: "/messages"
			},
			{
				icon: "newspaper",
				label: <span>Новости</span>,
				to: "/news"
			},
			{
				icon: "print",
				label: <span>Отчеты</span>,
				to: "/reports"
			},
			{
				icon: "user-friends",
				label: <span>Пользователи</span>,
				to: "/sapr_users"
			},
			{
				icon: "cogs",
				label: <span>Параметры</span>,
				to: "/options"
			}
		]);
	}
	if (store.roleCode == "terrmod") {
		return renderOffice ([
			{
				icon: "file",
				label: <span>Заявления</span>,
				to: "/declarations"
			},
			{
				icon: "fas fa-comments",
				label: <span>Сообщения</span>,
				to: "/messages"
			},
			{
				icon: "user-friends",
				label: <span>Пользователи</span>,
				to: "/sapr_users"
			}
		]);
	}
	if (store.roleCode == "observer") {
		return renderOffice ([
			{
				icon: "file",
				label: <span>Заявления</span>,
				to: "/declarations"
			},
			{
				icon: "calendar-check",
				label: <span>Экзамены</span>,
				to: "/exams"
			},
			{
				icon: "user-friends",
				label: <span>Пользователи</span>,
				to: "/sapr_users"
			}
		]);
	}
	if (store.roleCode == "pedObserver") {
		return renderOffice ([
			{
				icon: "file",
				label: <span>Заявления</span>,
				to: "/declarations"
			}
		]);
	}
	if (store.roleCode == "pedagog") {
		return renderOffice ([
			{
				icon: "user",
				label: <span>Основные сведения</span>,
				to: `/sapr_user/${store.userId}`
			},
			{
				icon: "file",
				label: <span>Заявления</span>,
				to: "/pedagog_declarations"
			},
			{
				icon: "comments",
				label: <span>Чат</span>,
				to: "/chat"
			}
		]);
	}
	if (store.roleCode == "chief") {
		return renderOffice ([
			{
				icon: "user",
				label: <span>Основные сведения</span>,
				to: `/sapr_user/${store.userId}`
			},
			{
				icon: "file",
				label: <span>Заявления</span>,
				to: "/chief_declarations"
			},
			{
				icon: "comments",
				label: <span>Чат</span>,
				to: "/chat"
			}
		]);
	}
	if (store.roleCode == "expert") {
		return renderOffice ([
			{
				icon: "user",
				label: <span>Основные сведения</span>,
				to: `/sapr_user/${store.userId}`
			},
			{
				icon: "file",
				label: <span>Заявления</span>,
				to: "/expert_declarations"
			}
		]);
	}
	return <div>{store.roleCode}</div>;
};
