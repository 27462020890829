/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {Record} from "objectum-client";
import {store} from "../modules/mediator.js";
import {createReport} from "objectum-react";

export default class ExamModel  extends Record {
	getLabel () {
		return `${this.date.toLocaleString ().substr (0, 17)}${this.hasOwnProperty ("empNum") ? ` (места ${this.empNum} / ${this.capacity})` : ""}`;
	}

	async getDeclarationRecords () {
		let pedagogRecords = await this.store.getRecords ({
			model: "declaration",
			filters: [
				["exam", "=", this.id]
			]
		});
		let chiefRecords = await this.store.getRecords ({
			model: "declaration",
			filters: [
				["chiefExam", "=", this.id]
			]
		});
		return [...pedagogRecords, ...chiefRecords];
	}

	async report () {
		let records = await store.getRecords ({
			model: "declaration",
			filters: [
				["exam", "=", this.id]
			]
		});
		let chiefRecords = await store.getRecords ({
			model: "declaration",
			filters: [
				["chiefExam", "=", this.id]
			]
		});
		let declarations = [];

		if (store.roleCode == "admin") {
			declarations = [0, ...records.map (record => record.id), ...chiefRecords.map (record => record.id)];
		}
		if (store.roleCode == "pedmod") {
			declarations = [0, ...records.map (record => record.id)];
		}
		if (store.roleCode == "chiefmod") {
			declarations = [0, ...chiefRecords.map (record => record.id)];
		}
		let recs = await store.getRecs ({
			query: "declaration.list",
			emp: `is not null`,
			state: "is not null",
			id: ` in (${declarations})`,
			archivePlace: "is null"
		});
		let rows = [
			[
				{text: `Участники экзамена: ${this.getLabel ()}`}
			],
			[],
			[
				{text: "Ф.И.О.", style: "border"},
				{text: "Подано на категорию", style: "border"},
				{text: "Статус", style: "border"},
				{text: "Дата", style: "border"},
				{text: "Текущее место работы", style: "border"},
				{text: "Территория", style: "border"}
			]
		];
		await store.getDict ("d.emp.category");
		await store.getDict ("d.declaration.state");
		await store.getDict ("d.terr");

		recs.forEach (rec => {
			rows.push ([
				{text: rec.fio, style: "border"},
				{text: store.dict ["d.emp.category"][rec.category]?.name, style: "border"},
				{text: store.dict ["d.declaration.state"][rec.state].name, style: "border"},
				{text: rec.date.toLocaleString (), style: "border"},
				{text: rec.orgName, style: "border"},
				{text: store.dict ["d.terr"][rec.terr]?.name, style: "border"}
			]);
		});
		createReport ({
			rows,
			columns: [40, 20, 20, 20, 40, 20],
			font: {
				name: "Arial",
				size: 10
			}
		});
	}

	async remove () {
		let records = await store.getRecords ({
			model: "declaration",
			filters: [
				["exam", "=", this.id]
			]
		});
		let chiefRecords = await store.getRecords ({
			model: "declaration",
			filters: [
				["chiefExam", "=", this.id]
			]
		});
		if (records.length || chiefRecords.length) {
			throw new Error ("В экзамене есть участники.");
		}
		await this.store.removeRecord (this.id);
	}
}
