/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import PageTitle from './PageTitle'
import DashboardExam from './DashboardExam'
import DashboardFounder from './DashboardFounder'
import DashboardNewUsers from './DashboardNewUsers'
import DashboardNoSubmit from './DashboardNoSubmit'
import DashboardProvider from './DashboardProvider'
import DashboardFilter from './DashboardFilter'
import DashboardExamScore from './DashboardExamScore'
import DashboardAge from './DashboardAge'
import DashboardState from './DashboardState'

export default function Dashboard() {
    return (
        <div>
            <PageTitle>Дашборд</PageTitle>
            <DashboardProvider>
                <div className='bg-edu p-1'>
                    <div className="d-flex">
                        <DashboardExam />
                        <DashboardFounder />
                    </div>
                    <DashboardNewUsers />
                    <div>
                        <DashboardFilter />
                        <div className="d-flex">
                            <div style={{ width: '33%' }} className="mr-1">
                                <DashboardNoSubmit />
                            </div>
                            <div style={{ width: '33%' }} className="mr-1">
                                <DashboardExamScore />
                            </div>
                            <div style={{ width: '34%' }}>
                                <DashboardAge />
                            </div>
                        </div>
                        <div>
                            <DashboardState />
                        </div>
                    </div>
                </div>
            </DashboardProvider>
        </div>
    )
}
