import {Link} from "react-router-dom";
import PageTitle from "./PageTitle";
import {store} from "../modules/mediator";

export default function Dicts (props) {
	let recs = [
		{code: "d.terr"},
		{code: "d.person.citizenship"},
		{code: "d.emp.category"},
		{code: "d.emp.eduActResult"},
		{code: "d.emp.personalContribution"},
		{code: "d.emp.post"},
		{code: "d.emp.allegedPost"},
		{code: "d.emp.reward"},
		{code: "d.level"},
		{code: "d.person.language"},
		{code: "d.person.languageLevel"},
		{code: "d.founder"}
	];
	return <div>
		<PageTitle>Справочники</PageTitle>
		{recs.map ((rec, i) => {
			let model = store.getModel (rec.code);

			return <div key={i} className="border mb-2">
				<Link className="nav-link" to={`/model_list/${model.getPath ().split (".").join ("_")}`}>
					<strong><h5>{model.name}</h5></strong>
				</Link>
			</div>;
		})}
		<div className="border mb-2">
			<Link className="nav-link" to="/post_map">
				<strong><h5>Карта должностей</h5></strong>
			</Link>
		</div>
	</div>;
};
