/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import { useContext } from 'react'
import DashboardContext from './DashboardContext'
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts'

const COLORS = ['#7FB3D5', '#D98880', '#F7DC6F', '#BB8FCE']
const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    )
}

export default function DashboardExamScore() {
    const { userRecs, declarationRecs, startDate, endDate, founder } = useContext(DashboardContext)
    const filteredUserRecs = userRecs.filter(rec => !founder || (founder && rec.founder == founder))
    const empMap = filteredUserRecs.reduce((m, rec) => {
        m[rec.emp] = rec
        return m
    }, {})
    const filteredDeclarationRecs = declarationRecs.filter((rec, i) => {
        return rec.examDate && rec.examDate >= startDate && rec.examDate <= endDate && empMap[rec.emp]
    })
    const score = filteredDeclarationRecs.reduce(
        (m, rec) => {
            if (rec.examScore) {
                if (rec.examScore <= 29) {
                    m.s0++
                } else if (rec.examScore <= 49) {
                    m.s30++
                } else if (rec.examScore <= 69) {
                    m.s50++
                } else if (rec.examScore <= 100) {
                    m.s70++
                }
            }
            return m
        },
        {
            s70: 0,
            s50: 0,
            s30: 0,
            s0: 0,
        },
    )
    const data = [
        { name: '70-100', value: score.s70 },
        { name: '50-69', value: score.s50 },
        { name: '30-49', value: score.s30 },
        { name: '0-29', value: score.s0 },
    ]

    return (
        <div className="p-1 border bg-white rounded">
            <div style={{ height: '16em' }}>
                <h5>Баллы за экзамены</h5>
                <div className="d-flex align-items-center">
                    <div style={{ height: '14em', width: '60%' }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart width={400} height={400}>
                                <Pie
                                    data={data}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    label={renderCustomizedLabel}
                                    outerRadius={80}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {data.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                    <div>
                        <div style={{ color: COLORS[0] }}>
                            <b>70-100</b>
                            <span className="ml-3">{score.s70} ч.</span>
                        </div>
                        <div style={{ color: COLORS[1] }}>
                            <b>50-69</b>
                            <span className="ml-3">{score.s50} ч.</span>
                        </div>
                        <div style={{ color: COLORS[2] }}>
                            <b>30-49</b>
                            <span className="ml-3">{score.s30} ч.</span>
                        </div>
                        <div style={{ color: COLORS[3] }}>
                            <b>0-29</b>
                            <span className="ml-3">{score.s0} ч.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
