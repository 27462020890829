/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {useState, useEffect} from "react";
import {Action, Grid, Loading, Tabs, Tab, createReport, reportStyles, DateField, DictField} from "objectum-react";
import {store} from "../modules/mediator";
import PageTitle from "./PageTitle.js";

export default function Reports (props) {
    let [type, setType] = useState ("= 0");
    let [startDate, setStartDate] = useState (null)
    let [endDate, setEndDate] = useState (null)
    let [empRecs, setEmpRecs] = useState ([])
    let [expert, setExpert] = useState (null)
    let [loading, setLoading] = useState (false);

    useEffect (() => {
        async function load () {
            setLoading (true);

            await store.getDict ("d.emp.post");
            await store.getDict ("d.declaration.expertState");
            await store.getDict ("d.exam.type");

            if (store.roleCode == "admin") {
                setType ("is not null");
            }
            if (store.roleCode == "pedmod") {
                setType ("=" + store.dict ["d.exam.type"]["ped"].id);
            }
            if (store.roleCode == "chiefmod") {
                setType ("=" + store.dict ["d.exam.type"]["chief"].id);
            }
            let userRecords = await store.getRecords ({model: "objectum.user"})
            let empRecords = await store.getRecords ({model: "emp"})
            let roleRecords = await store.getRecords ({model: "objectum.role"})
            let empRecs = []

            userRecords.forEach (userRecord => {
                let roleRecord = roleRecords.find (record => record.id == userRecord.role)
                let empRecord = empRecords.find (record => record.id == userRecord.emp)

                if (empRecord && roleRecord && ["expert", "manager"].indexOf (roleRecord.code) > -1) {
                    empRecs.push ({
                        id: empRecord.id,
                        name: `${empRecord.surname} ${empRecord.forename} ${empRecord.patronymic || ""} (${roleRecord.name})`
                    })
                }
            })
            setEmpRecs(empRecs)
            setLoading (false)
        }
        load ();
        // eslint-disable-next-line
    }, []);

    if (!type) {
        return <Loading />;
    }
    async function ExpertsReport () {
        Object.assign (reportStyles, {
            border_bold: {
                font: {name: "Arial", size: 10, bold: true},
                border: {
                    top: {style: "thin"},
                    left: {style: "thin"},
                    bottom: {style: "thin"},
                    right: {style: "thin"}
                },
                alignment: {
                    vertical: "top",
                    horizontal: "left",
                    wrapText: true
                }
            },
            center_bold: {
                font: {name: "Arial", size: 10, bold: true},
                alignment: {
                    vertical: "top",
                    horizontal: "middle",
                    wrapText: true
                }
            }
        });
        let expertRecs = await store.getRecs({
            query: "user.expert",
            role: `=${store.dict ["objectum.role"]["expert"].id}`,
            offset: 0,
            limit: 1000000
        })
        expertRecs.sort ((a, b) => a.fio > b.fio ? -1 : 1)

        let expertName

        if (expert) {
            expertRecs = expertRecs.filter(rec => rec.emp == expert)
            let record = await store.getRecord(expert)
            expertName = `${record.surname} ${record.forename} ${record.patronymic || ""}`
        }
        let declarationEmpRecords = await store.getRecords({
            model: "t.declaration.emp",
            filters: [
                ['endDate', 'is not null']
            ]
        })

        if (startDate) {
            declarationEmpRecords = declarationEmpRecords.filter(rec => rec.endDate >= startDate)
        }
        if (endDate) {
            declarationEmpRecords = declarationEmpRecords.filter(rec => rec.endDate <= endDate)
        }
        let declarationEmpMap = declarationEmpRecords.reduce((result, record) => {
            result[record.emp] = result[record.emp] || []
            result[record.emp].push(record)
            return result
        }, {})
        let declarationRecs = []
        let declarationFilters = ['is null', '=1', '=2']

        for(let i = 0; i < declarationFilters.length; i ++) {
            declarationRecs = [...declarationRecs, ...(await store.getRecs({
                query: "declaration.pedmod",
                id: 'is not null',
                archivePlace: declarationFilters[i],
                offset: 0,
                limit: 1000000
            }))]
        }

        let declarationMap = declarationRecs.reduce((result, rec) => {
            result[rec.id] = rec
            return result
        }, {})
        let rows = [
            [
                {text: "Отчет о количестве проверенных заявлений", style: "center_bold", colSpan: 6},
            ],
            ...(expert ? [
                [{text: `Эксперт: ${expertName}`, colSpan: 6}],
            ] : []),
            ...(startDate ? [
                [{text: `Начало периода: ${startDate.toLocaleDateString()}`, colSpan: 6}],
            ] : []),
            ...(endDate ? [
                [{text: `Конец периода: ${endDate.toLocaleDateString()}`, colSpan: 6}],
            ] : []),
            [],
            [
                {text: "№ пп", style: "border_bold", rowSpan: 2},
                {text: expert ? "Должность" : "Ф.И.О.", style: "border_bold", rowSpan: 2},
                {text: expert ? "Ф.И.О." : "Должность", style: "border_bold", rowSpan: 2},
                {text: "Кол-во заявлений", style: "border_bold", colSpan: 3}
            ],
            [
                {text: "Проверено", style: "border_bold"},
                {text: "Отклонено", style: "border_bold"},
                {text: "На рассмотрении", style: "border_bold"}
            ]
        ]
        let itogo = { accepted: 0, rejected: 0, processing: 0 }

        expertRecs.forEach((expertRec, i) => {
            let declarationEmpRecords = declarationEmpMap[expertRec.emp] || []
            let postMap = {}

            declarationEmpRecords.forEach(declarationEmpRecord => {
                let declarationRec = declarationMap[declarationEmpRecord.declaration]

                if (!declarationRec) {
                    return
                }
                postMap[declarationRec.post] = postMap[declarationRec.post] || {
                    accepted: 0, rejected: 0, processing: 0,
                    acceptedNames: [], rejectedNames: [], processingNames: []
                }
                if (declarationEmpRecord.state == store.dict["d.declaration.expertState"]["accepted"].id) {
                    postMap[declarationRec.post].accepted ++
                    postMap[declarationRec.post].acceptedNames.push([declarationRec.fio, 'accepted'])
                } else
                if (declarationEmpRecord.state == store.dict["d.declaration.expertState"]["rejected"].id) {
                    postMap[declarationRec.post].rejected ++
                    postMap[declarationRec.post].rejectedNames.push([declarationRec.fio, 'rejected'])
                } else {
                    postMap[declarationRec.post].processing ++
                    postMap[declarationRec.post].processingNames.push([declarationRec.fio, 'processing'])
                }
            })
            if (Object.keys(postMap).length) {
                if (expert) {
                    for (let post in postMap) {
                        let o = postMap[post]
                        let emps = [...o.acceptedNames, ...o.rejectedNames, ...o.processingNames]

                        emps.sort ((a, b) => a[0] < b[0] ? -1 : 1);

                        for (let j = 0; j < emps.length; j ++) {
                            let [fio, code] = emps[j]

                            rows.push ([
                                {text: !j ? (++ i) : "", style: "border"},
                                {text: !j ? (store.dict ["d.emp.post"][post]?.name || '-') : "", style: "border"},
                                {text: fio, style: "border"},
                                {text: code == 'accepted' ? '1' : "", style: "border"},
                                {text: code == 'rejected' ? '1' : "", style: "border"},
                                {text: code == 'processing' ? '1' : "", style: "border"}
                            ])
                            itogo[code] ++
                        }
                    }
                } else {
                    let first = true

                    for (let post in postMap) {
                        let o = postMap[post]

                        rows.push ([
                            {text: first ? (i + 1) : "", style: "border"},
                            {text: first ? expertRec.fio : "", style: "border"},
                            {text: store.dict ["d.emp.post"][post].name, style: "border"},
                            {text: o.accepted || "", style: "border"},
                            {text: o.rejected || "", style: "border"},
                            {text: o.processing || "", style: "border"}
                        ])
                        itogo.accepted += o.accepted
                        itogo.rejected += o.rejected
                        itogo.processing += o.processing
                        first = false
                    }
                }
            } else {
                rows.push([
                    {text: i + 1, style: "border"},
                    {text: expertRec.fio, style: "border"},
                    {text: "", style: "border"},
                    {text: "", style: "border"},
                    {text: "", style: "border"},
                    {text: "", style: "border"}
                ])
            }
        })
        rows.push ([
            {text: "", style: "border"},
            {text: "", style: "border"},
            {text: "Итого:", style: "border"},
            {text: itogo.accepted, style: "border"},
            {text: itogo.rejected, style: "border"},
            {text: itogo.processing, style: "border"}
        ])

        createReport ({
            rows,
            columns: [10, expert ? 30 : 40, expert ? 40 : 30, 18, 18, 18],
            font: {
                name: "Arial",
                size: 10
            },
            worksheetOpts: {
                pageSetup: {
                    margins: {
                        left: 0.4, right: 0.4,
                        top: 0.4, bottom: 0.4,
                        header: 0.1, footer: 0.1
                    },
                    orientation: "portrait"
                }
            }
        });
    }
    if (loading) {
        return <Loading container />
    }
    return <div>
        <PageTitle>Отчеты</PageTitle>
        <Tabs>
            <Tab label="Экзамены">
                <Grid {...props} id="exams" store={store} query="exam.list" params={{type}}>
                    <Action icon="fas fa-print" label="Участники экзамена" onClick={async ({id}) => {
                        let record = await store.getRecord (id);
                        await record.report ();
                    }} selected />
                </Grid>
            </Tab>
            <Tab label="Эксперты">
                <div className="m-2">
                    <DateField label="Начало периода" value={startDate} onChange={({value}) => setStartDate (value)}  />
                </div>
                <div className="m-2">
                    <DateField label="Конец периода" value={endDate} onChange={({value}) => setEndDate (value)}  />
                </div>
                <div className="m-2">
                    <DictField recs={empRecs} placeholder="Выберите эксперта" onChange={({value}) => setExpert(value)}/>
                </div>
                <div className="m-2">
                    <Action icon="fas fa-print" label="Создать" onClick={ExpertsReport} />
                </div>
            </Tab>
        </Tabs>
    </div>;
};
