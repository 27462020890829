/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {useState, useEffect, useRef} from "react";
import {Action, StringField} from "objectum-react";
import {store} from "../modules/mediator";

export default function ChatMessages ({user, onNewRecord, refresh, showEmpLink}) {
	let [text, setText] = useState (null);
	let [messageRecords, setMessageRecords] = useState ([]);
	let [currentId, setCurrentId] = useState(null)
	let [username, setUsername] = useState('')
	let editorRef = useRef(null)
	let divEl = useRef (null);

	useEffect (() => {
		async function load () {
			let userRecord = await store.getRecord (user)

			if (userRecord.emp) {
				let empRecord = await store.getRecord (userRecord.emp)
				setUsername(`${empRecord.surname} ${empRecord.forename[0].toUpperCase()}. ${empRecord.patronymic ? `${empRecord.patronymic[0].toUpperCase()}.` : ''}`)
			}
			let messageRecords = await store.getRecords ({
				model: "message",
				filters: [
					["user", "=", user]
				]
			});
			messageRecords.sort ((a, b) => a.date > b.date ? 1 : -1);
			setMessageRecords (messageRecords);
			setTimeout (() => divEl.current?.scrollIntoView ({block: "end", behavior: "smooth"}), 500);
		}
		load ();
	}, [user, refresh]);

	async function onSend () {
		await store.transaction (async () => {
			let userRecord = await store.getRecord (user);
			userRecord.messageDate = new Date ();
			await userRecord.sync ();
			let record = await store.createRecord ({
				_model: "message",
				user,
				author: store.userId,
				text,
				date: new Date ()
			});
			setMessageRecords ([...messageRecords, record]);

			if (onNewRecord) {
				onNewRecord (record);
			}
			setText('')
			editorRef.current.quill.clipboard.dangerouslyPasteHTML ('')
			setTimeout (() => divEl.current?.scrollIntoView (), 500);
		});
	}
	async function onSave () {
		await store.transaction (async () => {
			let record = await store.getRecord (currentId)

			record.text = text
			await record.sync()

			setCurrentId (null)
			setText('')
			editorRef.current.quill.clipboard.dangerouslyPasteHTML ('')

			setMessageRecords ([...messageRecords])
		})
	}
	let body = document.body, html = document.documentElement;
	let height = Math.max (body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );

	return <div>
		<div className="mt-3 overflow-auto" style={{height: height - 400}}>
			{messageRecords.filter (record => record.user == user).map ((messageRecord, i) => {
				let canEdit = !showEmpLink && messageRecord.author == messageRecord.user

				if (showEmpLink && messageRecord.author !== messageRecord.user) {
					canEdit = true
				}
				return <div key={i} className={`d-flex ${messageRecord.author == messageRecord.user ? "justify-content-end" : "justify-content-start"}`}>
					<div className={`${messageRecord.author == messageRecord.user ? "bg-retraining" : ""} border-primary border rounded w-75 m-2`}>
						<div className="d-flex align-items-center">
							<div className="p-2 font-weight-bold">
								{(showEmpLink && messageRecord.author == messageRecord.user)
									? <a href={`/sapr_user/${messageRecord.user}`} className="mr-2">{username}</a>
									: null}
								{messageRecord.date.toLocaleString ()}
							</div>
							{canEdit ? <div>
								<Action title="Изменить" icon="fas fa-edit" btnClassName="btn btn-primary btn-sm" onClick={() => {
									setCurrentId (messageRecord.id)
									setText(messageRecord.text)
									editorRef.current.quill.clipboard.dangerouslyPasteHTML (messageRecord.text)
								}} />
							</div> : null}
						</div>
						<div className="p-2" dangerouslySetInnerHTML={{__html: messageRecord.text}} />
					</div>
				</div>;
			})}
			<div ref={divEl} />
		</div>
		<div className="mt-2">
			<StringField ref={editorRef} value={text} onChange={({value}) => setText (value)} wysiwyg placeholder="Введите текст сообщения" />
			<div className="mt-1">
				{currentId ? <div className="d-flex">
						<Action label="Сохранить" icon="fas fa-paper-plane" onClick={onSave} disabled={!text} btnClassName="btn btn-primary btn-labeled" />
						<Action label="Отмена" icon="fas fa-minus-circle" onClick={() => {
							setCurrentId (null)
							setText('')
							editorRef.current.quill.clipboard.dangerouslyPasteHTML ('')
						}} btnClassName="btn btn-primary btn-labeled ml-1" />
					</div> :
					<Action label="Отправить" icon="fas fa-paper-plane" onClick={onSend} disabled={!text} btnClassName="btn btn-primary btn-labeled" />
				}
			</div>
		</div>
	</div>;
};
