/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {useState} from "react";
import {Field, Form, goRidLocation} from "objectum-react";
import {store} from "../modules/mediator";
import PageTitle from "./PageTitle";

export default function Org (props) {
	let [rid, setRid] = useState ((rid => rid == "new" ? null : rid) (props.match.params.rid.split ("#")[0]));

	async function onCreate (rid) {
		setRid (rid);
		goRidLocation (props, rid);
	};

	return <div>
		<PageTitle>Организация</PageTitle>
		<div className="bg-white border shadow-sm">
			<Form store={store} rsc="record" rid={rid} mid="org" onCreate={onCreate}>
				<Field property="name" />
				<Field property="type" />
				<Field property="terr" />
				<Field property="founder" />
				<Field property="accessible" />
				{store.roleCode == "chiefmod" ? null : <Field property="k" />}
			</Form>
		</div>
	</div>;
};
