import {useState} from "react";
import {Action, Grid} from "objectum-react";
import PageTitle from "./PageTitle";
import {store} from "../modules/mediator";

export default function Orgs (props) {
	let [refresh, setRefresh] = useState (false);

	function onCreate () {
		props.history.push ({
			pathname: "/org/new"
		});
	};
	function onEdit ({id}) {
		props.history.push ({
			pathname: "/org/" + id
		});
	};
	async function onRemove ({id}) {
		try {
			await store.startTransaction ("Удаление организации: " + id);
			await store.removeRecord (id);
			await store.commitTransaction ();
		} catch (err) {
			await store.rollbackTransaction ();
			throw err;
		}
		setRefresh (!refresh);
	};
	let filters = [
		["terr", "=", ""],
		["founder", "=", ""]
	];
	let editable = ["name", "type", "terr", "founder", "accessible"];

	if (["admin", "pedmod"].indexOf (store.roleCode) > -1) {
		editable.push ("k");
	}
	return <div>
		<PageTitle>Организации</PageTitle>
		<Grid
			{...props}
			id="orgs"
			store={store}
			query="org.list"
			refresh={refresh}
			editable={editable}
			filters={filters}
			importCSV={{ model: "org", properties: ["name", "terr", "founder", "accessible", "k"] }}
		>
			<div className="d-flex pb-1">
				<Action icon="fas fa-plus" label="Добавить" onClick={onCreate} />
				<Action icon="fas fa-edit" label="Открыть" onClick={onEdit} selected />
				<Action icon="fas fa-minus" label="Удалить" onClick={onRemove} confirm selected />
			</div>
		</Grid>
	</div>;
};
