/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {useReducer, useEffect} from "react";
import {NumberField, Field, Action, Form, Loading, Tabs, Tab, JsonField, StringField, Grid, DictField} from "objectum-react";
import Unit from "./Unit";
import ExamField from "./ExamField";
import mediator, {store} from "../modules/mediator";
import loadPedagogDeclarationData from '../common/loadPedagogDeclarationData'

let tables = [
	{code: "edu", name: "Образование"},
	{code: "training", name: "Повышение квалификации"},
	{code: "retraining", name: "Переподготовка"},
	{code: "reward", name: "Отраслевая награда"},
	{code: "specialReward", name: "Особая отраслевая награда"},
	{code: "presentation", name: "Представление ОО"},
	{code: "inventory", name: "Опись документов"},
	{code: "studentContest", name: "Результаты участия обучающихся в олимпиадах, конкурсах, конференциях (по преподаваемому предмету)"},
	{code: "eduActResult", name: "Результаты образовательной деятельности"},
	{code: "personalContribution", name: "Личный вклад и достижения"}
];
function init (props) {
	return {
		loading: "Загрузка",
		rid: (rid => rid == "new" ? null : rid) (props.id || props.match.params.rid.split ("#")[0]),
		orgRecords: [],
		unitRecords: [],
		eduRecords: [],
		trainingRecords: [],
		retrainingRecords: [],
		rewardRecords: [],
		specialRewardRecords: [],
		presentationRecords: [],
		inventoryRecords: [],
		studentContestRecords: [],
		eduActResultRecords: [],
		personalContributionRecords: [],
		empRecs: [],
		examRecords: [],
		isExpert: false,
		stateMessage: ''
	};
}
function reducer (state, action) {
	return Object.assign ({}, state, action);
}
function Colorer ({id, table, state, children}) {
	let cls = "";
	let unitState = state [`unitState-${id}`];

	if (state.isExpert) {
		if (unitState == store.dict ["d.declaration.blockState"]["accepted"].id) {
			cls = "bg-success";
		}
		if (unitState == store.dict ["d.declaration.blockState"]["rejected"].id) {
			cls = "bg-danger";
		}
	}
	if (state.isAdmin) {
		let n = 0;
		let records = state.unitRecords.filter (record => record [table] == id);

		records.forEach (record => {
			if (record.state == store.dict ["d.declaration.blockState"]["accepted"].id) {
				n ++;
			}
			if (record.state == store.dict ["d.declaration.blockState"]["rejected"].id) {
				n --;
			}
		});
		if (n && n == records.length) {
			cls = "bg-success";
		}
		if (n && n < records.length) {
			cls = "bg-danger";
		}
	}
	return <div>
		<div className={cls} style={{height: "0.5em"}} />
		{children}
	</div>;
}
function UrlField (props) {
	return <div className={props.label ? "form-group stringfield" : "stringfield"}>
		{props.label && <label>{props.label}</label>}
		<div>{props.value ? <a href={props.value} className="sapr-url"><u>{props.value}</u></a> : ""}</div>
	</div>
}
export default function PedagogDeclaration (props) {
	let [state, dispatch] = useReducer (reducer, props, init);

	useEffect (() => {
		dispatch({loading: 'Загрузка'})
		loadPedagogDeclarationData (state.rid, dispatch)
	}, [state.rid]);

	if (props.id && props.id != state.rid) {
		dispatch ({rid: props.id});
	}
	function renderEdu (id) {
		return <div key={id} className="unit-content">
			<Colorer id={id} table="edu" state={state}><div className="unit-main">
				<div className="border p-1 mb-2">
					<Form store={store} rsc="record" rid={id} hideButtons disabled>
						<div className="row">
							<div className="col-6">
								<Field property="orgStr" />
							</div>
							<div className="col-3">
								<Field property="startYear" />
							</div>
							<div className="col-3">
								<Field property="endYear" />
							</div>
						</div>
						<div className="row">
							<div className="col-6">
								<Field property="qualificationStr" />
							</div>
							<div className="col-6">
								<Field property="file" />
							</div>
						</div>
					</Form>
				</div>
			</div></Colorer>
			<div className="">
				<div className="">
					<Unit table="edu" id={id} declaration={state.rid} state={state} dispatch={dispatch} />
				</div>
			</div>
		</div>;
	}
	function renderRetraining (id) {
		return <div key={id} className="unit-content">
			<Colorer id={id} table="retraining" state={state}><div className="unit-main">
				<div className="border p-1 mb-2">
					<Form store={store} rsc="record" rid={id} hideButtons disabled>
						<div className="row">
							<div className="col-8">
								<Field property="name" />
							</div>
							<div className="col-4">
								<Field property="hours" />
							</div>
						</div>
						<div className="row">
							<div className="col-4">
								<Field property="startDate" />
							</div>
							<div className="col-4">
								<Field property="endDate" />
							</div>
							<div className="col-4">
								<Field property="place" />
							</div>
						</div>
						<div className="row">
							<div className="col-4">
								<Field property="num" />
							</div>
							<div className="col-4">
								<Field property="regNum" />
							</div>
							<div className="col-4">
								<Field property="file" />
							</div>
						</div>
					</Form>
				</div>
			</div></Colorer>
			<div className="">
				<div className="">
					<Unit table="retraining" id={id} declaration={state.rid} state={state} dispatch={dispatch} />
				</div>
			</div>
		</div>;
	}
	function renderRewardScore () {
		let score = 0;

		state.rewardRecords.forEach (record => {
			score += store.dict ["d.emp.reward"][record.reward]?.score || 0;
		});
		if (score) {
			return ` (баллы: ${score})`;
		} else {
			return null;
		}
	}
	function renderReward (id) {
		let record = state.rewardRecords.find (record => record.id == id);
		let score = store.dict ["d.emp.reward"][record.reward]?.score;

		return <div key={id} className="unit-content">
			<Colorer id={id} table="reward" state={state}><div className="unit-main">
				<div className="border p-1 mb-2">
					<Form store={store} rsc="record" rid={id} hideButtons disabled>
						<div className="row">
							<div className="col-9">
								<Field property="reward" dict label="Награда за успехи в профессиональной деятельности, наличие ученой степени, звания" />
							</div>
							<div className="col-3">
								<Field property="level" dict />
							</div>
						</div>
						<div className="row">
							<div className="col-4">
								<UrlField property="url" />
							</div>
							<div className="col-5">
								<Field property="file" />
							</div>
							<div className="col-3">
								<NumberField label="Балл" value={score} disabled />
							</div>
						</div>
					</Form>
				</div>
			</div></Colorer>
			<div className="">
				<div className="">
					<Unit table="reward" id={id} declaration={state.rid} state={state} dispatch={dispatch} />
				</div>
			</div>
		</div>;
	}
	function renderSpecialReward (id) {
		let record = state.specialRewardRecords.find (record => record.id == id);
		let score = store.dict ["d.emp.reward"][record.reward]?.score;

		return <div key={id} className="unit-content">
			<Colorer id={id} table="specialReward" state={state}><div className="unit-main">
				<div className="border p-1 mb-2">
					<Form store={store} rsc="record" rid={id} hideButtons disabled>
						<div className="row">
							<div className="col-9">
								<Field property="reward" dict label="Награда за успехи в профессиональной деятельности, наличие ученой степени, звания" />
							</div>
							<div className="col-3">
								<Field property="level" dict />
							</div>
						</div>
						<div className="row">
							<div className="col-4">
								<UrlField property="url" />
							</div>
							<div className="col-5">
								<Field property="file" />
							</div>
							<div className="col-3">
								<NumberField label="Балл" value={score} disabled />
							</div>
						</div>
					</Form>
				</div>
			</div></Colorer>
			<div className="">
				<div className="">
					<Unit table="specialReward" id={id} declaration={state.rid} state={state} dispatch={dispatch} />
				</div>
			</div>
		</div>;
	}
	function renderStudentContest (id) {
		return <div key={id} className="unit-content">
			<Colorer id={id} table="studentContest" state={state}><div className="unit-main">
				<div className="border p-1 mb-2">
					<Form store={store} rsc="record" rid={id} hideButtons disabled>
						<div className="row">
							<div className="col-3">
								<Field property="level" dict />
							</div>
							<div className="col-3">
								<Field property="result" dict />
							</div>
							<div className="col-6">
								<Field property="file" />
							</div>
						</div>
					</Form>
				</div>
			</div></Colorer>
			<div className="">
				<div className="">
					<Unit table="studentContest" id={id} declaration={state.rid} state={state} dispatch={dispatch} />
				</div>
			</div>
		</div>;
	}
	function renderTraining (id) {
		return <div key={id} className="unit-content">
			<Colorer id={id} table="training" state={state}><div className="unit-main">
				<div className="border p-1 mb-2">
					<Form store={store} rsc="record" rid={id} hideButtons disabled>
						<div className="row">
							<div className="col-8">
								<Field property="name" />
							</div>
							<div className="col-2">
								<Field property="startDate" />
							</div>
							<div className="col-2">
								<Field property="endDate" />
							</div>
						</div>
						<div className="row">
							<div className="col-4">
								<Field property="docNum" />
							</div>
							<div className="col-4">
								<Field property="regNum" />
							</div>
							<div className="col-4">
								<Field property="hours" />
							</div>
						</div>
						<div className="row">
							<div className="col-6">
								<Field property="place" />
							</div>
							<div className="col-6">
								<Field property="file" />
							</div>
						</div>
					</Form>
				</div>
			</div></Colorer>
			<div className="">
				<div className="">
					<Unit table="training" id={id} declaration={state.rid} state={state} dispatch={dispatch} />
				</div>
			</div>
		</div>;
	}
	function renderEduActResult (id) {
		return <div key={id} className="unit-content">
			<Colorer id={id} table="eduActResult" state={state}><div className="unit-main">
				<div className="border p-1 mb-2">
					<Form store={store} rsc="record" rid={id} hideButtons disabled>
						<Field property="eduActResult" dict />
						<div className="row">
							<div className="col-6">
								<Field property="type" dict />
							</div>
							<div className="col-6">
								<Field property="level" dict />
							</div>
						</div>
						<div className="row">
							<div className="col-6">
								<JsonField property="url" label={state [`urlLabel-${id}`] || "Ссылка"} multi props={[
									{prop: "url", component: UrlField}
								]} />
							</div>
							<div className="col-6">
								<Field property="file" />
							</div>
						</div>
					</Form>
				</div>
			</div></Colorer>
			<div className="">
				<div className="">
					<Unit table="eduActResult" id={id} declaration={state.rid} state={state} dispatch={dispatch} showScore />
				</div>
			</div>
		</div>;
	}
	function renderPersonalContribution (id) {
		return <div key={id} className="unit-content">
			<Colorer id={id} table="personalContribution" state={state}><div className="unit-main">
				<div className="border p-1 mb-2">
					<Form store={store} rsc="record" rid={id} hideButtons disabled>
						<Field property="personalContribution" dict />
						<div className="row">
							<div className="col-6">
								<Field property="level" dict />
							</div>
						</div>
						<div className="row">
							<div className="col-6">
								<UrlField property="url" />
							</div>
							<div className="col-6">
								<Field property="file" />
							</div>
						</div>
					</Form>
				</div>
			</div></Colorer>
			<div className="">
				<div className="">
					<Unit table="personalContribution" id={id} declaration={state.rid} state={state} dispatch={dispatch} showScore />
				</div>
			</div>
		</div>;
	}
	function renderPresentation (id) {
		return <div key={id} className="unit-content">
			<Colorer id={id} table="presentation" state={state}><div className="unit-main">
				<div className="border p-1 mb-2">
					<Form store={store} rsc="record" rid={id} hideButtons disabled>
						<div className="row">
							<div className="col-6">
								<Field property="name" />
							</div>
							<div className="col-6">
								<Field property="file" />
							</div>
						</div>
					</Form>
				</div>
			</div></Colorer>
			<div className="">
				<div className="">
					<Unit table="presentation" id={id} declaration={state.rid} state={state} dispatch={dispatch} />
				</div>
			</div>
		</div>;
	}
	function renderInventory (id) {
		return <div key={id} className="unit-content">
			<Colorer id={id} table="inventory" state={state}><div className="unit-main">
				<div className="border p-1 mb-2">
					<Form store={store} rsc="record" rid={id} hideButtons disabled>
						<div className="row">
							<div className="col-6">
								<Field property="name" />
							</div>
							<div className="col-6">
								<Field property="file" />
							</div>
						</div>
					</Form>
				</div>
			</div></Colorer>
			<div className="">
				<div className="">
					<Unit table="inventory" id={id} declaration={state.rid} state={state} dispatch={dispatch} />
				</div>
			</div>
		</div>;
	}
	async function onCreateEmp () {
		let records = await store.getRecords ({
			model: "t.declaration.emp",
			filters: [
				["declaration", "=", state.rid],
				["emp", "=", state.expert]
			]
		});
		if (records.length) {
			throw new Error ("Эксперт в списке");
		}
		await store.startTransaction ();

		try {
			await store.createRecord ({
				_model: "t.declaration.emp",
				declaration: state.rid,
				emp: state.expert,
				startDate: new Date ()
			});
			state.declarationRecord.state = store.dict ["d.declaration.state"]["processing"].id
			await state.declarationRecord.sync ()

			await store.commitTransaction ();
			dispatch ({refresh: !state.refresh});
		} catch (err) {
			await store.rollbackTransaction ();
			throw err;
		}
	}
	async function onRemoveEmp ({id}) {
		try {
			await store.startTransaction ();
			await store.removeRecord (id);
			await store.commitTransaction ();
		} catch (err) {
			await store.rollbackTransaction ();
			throw err;
		}
		dispatch ({refresh: !state.refresh});
	}
	async function onCancelExpert ({id}) {
		let record = await store.getRecord (id);
		record.state = null;
		record.endDate = null;
		await record.sync ();
		dispatch ({refresh: !state.refresh});
	}
	async function checkUnits () {
		let unitRecords = await store.getRecords ({
			model: `t.declaration.unit`,
			filters: [
				["declaration", "=", state.rid],
				["emp", "=", mediator.record.user.emp]
			]
		});
		for (let i = 0; i < tables.length; i ++) {
			let table = tables [i];
			let records = state [`${table.code}Records`];

			for (let j = 0; j < records.length; j ++) {
				let unitRecord = unitRecords.find (unitRecord => unitRecord [table.code] == records [j].id);

				if (!unitRecord || !unitRecord.state) {
					throw new Error (`Остались нерассмотренные блоки: ${table.name}`);
				}
			}
		}
	}
	async function onAccept ({confirm}) {
		await checkUnits ();

		if (await confirm ()) {
			try {
				await store.startTransaction ();
				state.declarationEmpRecord.comment = state.comment;
				state.declarationEmpRecord.state = store.dict ["d.declaration.expertState"]["accepted"].id;
				state.declarationEmpRecord.endDate = new Date ();
				await state.declarationEmpRecord.sync ();

				let declarationEmpRecords = (await store.getRecords ({
					model: "t.declaration.emp",
					filters: [
						["declaration", "=", state.rid]
					]
				}));
				if (declarationEmpRecords.filter (record => !!record.state).length == declarationEmpRecords.length) {
					state.declarationRecord.state = store.dict ["d.declaration.state"]["inProcessing"].id;
					await state.declarationRecord.sync ();
				}
				await store.commitTransaction ();
				dispatch ({refresh: !state.refresh});
			} catch (err) {
				await store.rollbackTransaction ();
				throw err;
			}
		}
	}
	async function onReject ({confirm}) {
		await checkUnits ();

		if (await confirm ()) {
			try {
				await store.startTransaction ();
				state.declarationEmpRecord.comment = state.comment;
				state.declarationEmpRecord.state = store.dict ["d.declaration.expertState"]["rejected"].id;
				state.declarationEmpRecord.endDate = new Date ();
				await state.declarationEmpRecord.sync ();

				let declarationEmpRecords = (await store.getRecords ({
					model: "t.declaration.emp",
					filters: [
						["declaration", "=", state.rid]
					]
				}));
				if (declarationEmpRecords.filter (record => !!record.state).length == declarationEmpRecords.length) {
					state.declarationRecord.state = store.dict ["d.declaration.state"]["inProcessing"].id;
					await state.declarationRecord.sync ();
				}
				await store.commitTransaction ();
				dispatch ({refresh: !state.refresh});
			} catch (err) {
				await store.rollbackTransaction ();
				throw err;
			}
		}
	}
	if (state.loading) {
		return <div className="p-3">
			<Loading label={state.loading} />
		</div>
	}
	let stateMap = {
		"await": 1,
		"processing": 2,
		"inProcessing": 4,
		"firstCategory": 5,
		"highestCategory": 6,
		"rejected": 7
	}
	if (store.roleCode == "pedmod") {
		stateMap.editing = 3
	}
	let stateRecords = store.map.dict ["d.declaration.state"].filter (record => Object.keys(stateMap).includes (record.code));
	stateRecords.sort((a, b) => stateMap[a.code] > stateMap[b.code] ? 1 : -1)

	if (store.roleCode == "terrmod") {
		stateRecords = stateRecords.filter (record => !["processing", "rejected"].includes (record.code));
	}
	return <div className="p-1">
		{state.empName && <table className="table table-sm bg-empinfo">
			<thead>
			<tr>
				<th>Ф.И.О.</th><th>Текущее место работы</th><th>Территория</th>
			</tr>
			</thead>
			<tbody>
			<tr>
				<td>{state.empName}</td>
				<td>{state.orgName}</td>
				<td>{state.terrName}</td>
			</tr>
			</tbody>
		</table>}
		<Tabs>
			<Tab label="Общие сведения"><div className="p-2">
				<Form store={store} rsc="record" rid={state.emp} hideButtons disabled>
					<div className="row">
						<div className="col-4">
							<Field property="surname" />
						</div>
						<div className="col-4">
							<Field property="forename" />
						</div>
						<div className="col-4">
							<Field property="patronymic" />
						</div>
					</div>
					<div className="row">
						<div className="col-4">
							<Field property="sex" />
						</div>
						<div className="col-4">
							<Field property="citizenship" dict />
						</div>
						<div className="col-4">
							{state.otherCitizenshipVisible ? <Field property="otherCitizenship" /> : null}
						</div>
					</div>
					<div className="row">
						<div className="col-4">
							<Field property="birthdate" />
						</div>
						<div className="col-4">
						</div>
						<div className="col-4">
						</div>
					</div>
					<Field property="address" />
					<Field property="nameChangeDoc" />
				</Form>
			</div></Tab>
			<Tab label="Профессиональная деятельность"><div className="p-1">
				{state.eduRecords.length ? <div className="mb-1">
					<div className="bg-empinfo p-2 mb-1">
						<h6 className="m-0">Образование</h6>
					</div>
					{state.eduRecords.map (record => renderEdu (record.id))}
				</div> : <div />}
				<Form store={store} rsc="record" rid={state.emp} hideButtons disabled>
					<h6>Научная деятельность</h6>
					<div className="border my-2 p-2">
						<div className="row">
							<div className="col-4">
								<Field property="scienceDegree" dict />
							</div>
							<div className="col-4">
								<Field property="degreeDate" />
							</div>
							<div className="col-4">
								<Field property="degreeCity" />
							</div>
						</div>
						<div className="row">
							<div className="col-4">
								<Field property="degreeRegNum" />
							</div>
							<div className="col-4">
								<Field property="degreeTheme" />
							</div>
							<div className="col-4">
								<Field property="degreeFile" />
							</div>
						</div>
					</div>
					<div className="border my-2 p-2">
						<div className="row">
							<div className="col-4">
								<Field property="scienceRank" dict />
							</div>
							<div className="col-4">
								<Field property="rankRegNum" />
							</div>
							<div className="col-4">
								<Field property="rankDate" />
							</div>
						</div>
						<div className="row">
							<div className="col-4">
								<Field property="rankTheme" />
							</div>
							<div className="col-4" />
							<div className="col-4">
								<Field property="rankFile" />
							</div>
						</div>
					</div>
					<div className="border my-2 p-2">
						<JsonField property="articleUrl" label="Статьи или исследования" multi col props={[
							{prop: "article", label: "Название", col: 6, component: StringField},
							{prop: "url", label: "Ссылка", col: 6, component: StringField}
						]} />
					</div>
				</Form>
				{state.retrainingRecords.length ? <div className="mb-2">
					<div className="bg-empinfo p-2 mb-1">
						<h6 className="m-0">Переподготовка</h6>
					</div>
					{state.retrainingRecords.map (record => renderRetraining (record.id))}
				</div> : <div />}
				<Form store={store} rsc="record" rid={state.emp} mid="emp" hideButtons disabled>
					<div className="row">
						<div className="col-6">
							<Field property="org" dict />
						</div>
						<div className="col-6">
							<Field property="terr" dict />
						</div>
					</div>
					<div className="row">
						<div className="col-6">
							<Field property="post" dict />
						</div>
						<div className="col-6">
							<Field property="pedExp" />
						</div>
					</div>
					<div className="row">
						<div className="col-6">
							<Field property="category" dict />
						</div>
						<div className="col-6">
							<Field property="categoryDoc" />
						</div>
					</div>
					<div className="row">
						<div className="col-6">
						</div>
						<div className="col-6">
							<Field property="analyticalReport" />
						</div>
					</div>
				</Form>
				{state.rewardRecords.length ? <div className="">
					<div className="bg-empinfo p-2 mb-1">
						<h6 className="m-0">Отраслевые награды{renderRewardScore ()}</h6>
					</div>
					{state.rewardRecords.map (record => renderReward (record.id))}
				</div> : <div />}
			</div></Tab>
			<Tab label="Аттестация на присвоение категории"><div className="p-1">
				{state.studentContestRecords.length ? <div className="">
					<div className="bg-empinfo p-2 mb-1">
						<h6 className="m-0">Результаты участия обучающихся в олимпиадах, конкурсах, конференциях (по преподаваемому предмету)</h6>
					</div>
					{state.studentContestRecords.map (record => renderStudentContest (record.id))}
				</div> : <div />}
				{state.trainingRecords.length ? <div className="mb-1">
					<div className="bg-empinfo p-2 mb-1">
						<h6 className="m-0">Повышение квалификации</h6>
					</div>
					{state.trainingRecords.map (record => renderTraining (record.id))}
				</div> : <div />}
				{state.eduActResultRecords.length ? <div className="mb-1">
					<div className="bg-empinfo p-2 mb-1">
						<h6 className="m-0">Результаты образовательной деятельности</h6>
					</div>
					{state.eduActResultRecords.map (record => renderEduActResult (record.id))}
				</div> : <div />}
				{state.personalContributionRecords.length ? <div className="">
					<div className="bg-empinfo p-2 mb-1">
						<h6 className="m-0">Личный вклад и достижения</h6>
					</div>
					{state.personalContributionRecords.map (record => renderPersonalContribution (record.id))}
				</div> : <div />}
				{state.specialRewardRecords.length ? <div className="">
					<div className="bg-empinfo p-2 mb-1">
						<h6 className="m-0">Особые отраслевые награды</h6>
					</div>
					{state.specialRewardRecords.map (record => renderSpecialReward (record.id))}
				</div> : <div />}
				{state.presentationRecords.length ? <div className="">
					<div className="bg-empinfo p-2 mb-1">
						<h6 className="m-0">Представление ОО</h6>
					</div>
					{state.presentationRecords.map (record => renderPresentation (record.id))}
				</div> : <div />}
				{state.inventoryRecords.length ? <div className="">
					<div className="bg-empinfo p-2 mb-1">
						<h6 className="m-0">Опись документов</h6>
					</div>
					{state.inventoryRecords.map (record => renderInventory (record.id))}
				</div> : <div />}
			</div></Tab>
			<Tab label={state.isAdmin ? "Экспертное заключение" : "Заявление"}><div className="p-2">
				<Form store={store} rsc="record" rid={state.rid} hideLogButton={!state.isAdmin}
					hideButtons={store.roleCode == "pedObserver"} onSave={async () => {
						if ([
							store.dict ["d.declaration.state"]["firstCategory"].id,
							store.dict ["d.declaration.state"]["highestCategory"].id,
							store.dict ["d.declaration.state"]["rejected"].id
						].includes(state.declarationRecord.state)) {
							if (state.declarationRecord.archivePlace == null) {
								await store.startTransaction ();
								state.declarationRecord.archivePlace = 1
								await state.declarationRecord.sync ()
								await store.commitTransaction ();
								dispatch({ stateMessage: 'Перемещено в "Архив"' })
							}
						} else {
							if (state.declarationRecord.archivePlace != null) {
								await store.startTransaction ();
								state.declarationRecord.archivePlace = null
								await state.declarationRecord.sync ()
								await store.commitTransaction ();
								dispatch({ stateMessage: 'Возвращено из архива' })
							}
						}
					}
				}>
					<div className="row">
						<div className="col-4">
							<Field property="state" disabled={!state.isAdmin} records={stateRecords} />
							{state.stateMessage && <div className="alert alert-info my-1">{state.stateMessage}</div>}
						</div>
						<div className="col-4">
							<Field property="category" disabled />
						</div>
						<div className="col-4">
							<Field property="date" showTime disabled />
						</div>
					</div>
					{store.roleCode == "expert" ? null : <div className="row">
						<div className="col-8">
							{state.isAdmin ? <ExamField property="exam" records={state.examRecords.filter (record => record.type == store.dict ["d.exam.type"]["ped"].id)} /> :
								<Field property="exam" disabled dict records={state.examRecords}/>}
						</div>
						<div className="col-4">
							<Field property="examScore" disabled={!state.isAdmin} max={50} />
						</div>
					</div>}
					<Field property="comment" disabled={!state.isAdmin} />
				</Form>
				{state.isAdmin && <div>
					<h5>Эксперты</h5>
					<div className="d-flex align-items-center mb-1">
						<DictField recs={state.empRecs} placeholder="Выберите эксперта" onChange={({value}) => dispatch ({expert: value})}/>
						<Action label="Добавить" btnClassName="btn btn-primary ml-1" onClick={onCreateEmp} disabled={!state.expert} />
					</div>
					<Grid id="emps" store={store} query="declaration.emp" params={{declaration: state.rid}} refresh={state.refresh}>
						<Action icon="fas fa-minus" label="Удалить" onClick={onRemoveEmp} confirm selected />
						<Action icon="fas fa-eye-slash" label="Отменить рассмотрение эксперта" onClick={onCancelExpert} selected transaction confirm />
					</Grid>
				</div>}
				{["manager", "expert"].indexOf (mediator.record.role?.code) > -1 ? <div>
					<h5>Экспертное заключение</h5>
					<div className="row">
						<div className="col-4">
							<StringField value={store.dict ["d.declaration.expertState"][state.declarationEmpRecord.state]?.name} disabled />
						</div>
						<div className="col-4">
							<StringField value={state.declarationEmpRecord.startDate.toLocaleString ()} disabled />
						</div>
						<div className="col-4">
							<StringField value={state.declarationEmpRecord.endDate?.toLocaleString ()} disabled />
						</div>
					</div>
					<div className="mt-1">
						<StringField label="Развернутый комментарий эксперта" textarea onChange={({value}) => dispatch ({comment: value})} disabled={!!state.declarationEmpRecord.state} />
					</div>
					{!state.declarationEmpRecord.state && <div>
						<h5>Завершить рассмотрение</h5>
						<div className="d-flex">
							<Action label="Принять" icon="fas fa-check" onClick={onAccept} />
							<Action label="Отклонить" icon="fas fa-times" className="ml-1" onClick={onReject} />
						</div>
					</div>}
				</div> : <div />}
			</div></Tab>
		</Tabs>
	</div>;
};
