/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import React, {useState} from "react";
import {Field, Form, goRidLocation} from "objectum-react";
import PageTitle from "./PageTitle";
import {store} from "../modules/mediator";

export default function Group (props) {
	let [rid, setRid] = useState ((rid => rid == "new" ? null : rid) (props.match.params.rid.split ("#")[0]));

	async function onCreate (rid) {
		setRid (rid);
		goRidLocation (props, rid);
	};
	return <div>
		<PageTitle>Группа</PageTitle>
		<div className="bg-white border shadow-sm">
			<Form store={store} rsc="record" rid={rid} mid="group" onCreate={onCreate}>
				<div className="row">
					<div className="col-6">
						<Field property="name" />
					</div>
					<div className="col-6">
						<Field property="year" />
					</div>
				</div>
				<div className="row">
					<div className="col-6">
						<Field property="startDate" />
					</div>
					<div className="col-6">
						<Field property="endDate" />
					</div>
				</div>
			</Form>
		</div>
	</div>;
};
