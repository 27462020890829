/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import objectumClient from "objectum-client";
const {execute} = objectumClient;

let store;
let data = {};
let value = {};
let record = {};
let records = {};
let listeners = {};

function init (opts) {
	store = opts.store;
}

async function set (a, v, opts) {
	value [a] = v;
	await callListeners ("change", {option: a, value: v, opts: opts || {}});
}

function get (a) {
	return value [a];
}

function addListener (e, fn) {
	listeners [e] = listeners [e] || [];
	listeners [e].push (fn);
}

function removeListener (e, fn) {
	listeners [e] = listeners [e] || [];

	for (let i = 0; i < listeners [e].length; i ++) {
		if (listeners [e][i] == fn) {
			listeners [e].splice (i, 1);
			break;
		}
	}
}

async function callListeners (e, opts) {
	if (listeners [e]) {
		for (let i = 0; i < listeners [e].length; i ++) {
			await execute (listeners [e][i], opts);
		}
	}
}

async function hasDeclaration (userId = store.userId) {
	if (!userId) {
		return false;
	}
	await store.getDict ("d.declaration.state");
	let record = await store.getRecord (userId);

	if (!record.emp) {
		return false;
	}
	let records = await store.getRecords ({
		model: "declaration",
		filters: [
			["emp", "=", record.emp],
			["archivePlace", "is null"]
		]
	});
	let currentYear = new Date ();
	currentYear = currentYear.getMonth () < 8 ? currentYear.getFullYear () - 1 : currentYear.getFullYear ();

	for (let i = 0; i < records.length; i ++) {
		let year = records [i].date;
		year = year.getMonth () < 8 ? year.getFullYear () - 1 : year.getFullYear ();

		//if (year == currentYear && records [i].state != store.dict ["d.declaration.state"]["editing"].id) {
		if (year == currentYear) {
			return records [i].id;
		}
	}
	return false;
}

async function canUserEdit (userId = store.userId) {
	if (!userId || store.roleCode == "chiefmod" || store.roleCode == "pedmod") {
		return true;
	}
	await store.getDict ("d.declaration.state");
	let record = await store.getRecord (userId);

	if (!record.emp) {
		return true;
	}
	let records = await store.getRecords ({
		model: "declaration",
		filters: [
			["emp", "=", record.emp]
		]
	});
	let cd = new Date (await store.remote ({model: "admin", method: "currentUTCTimestamp"}));

	for (let i = 0; i < records.length; i ++) {
		let d = records [i].date;

		if (records [i].state == store.dict ["d.declaration.state"]["editing"].id) {
			continue;
		}
		if (d.getFullYear() == cd.getFullYear() ||
			(d.getFullYear() == cd.getFullYear() - 1 && (
				d.getMonth() > cd.getMonth() ||
				(d.getMonth() ==  cd.getMonth() && d.getDate() > cd.getDate())
			))
		) {
			return false;
		}
	}
	return true;
}

async function canPedagogEdit (userId = store.userId) {
	if (!userId || store.roleCode == "chiefmod" || store.roleCode == "pedmod") {
		return [true, false];
	}
	await store.getDict ("d.declaration.state");
	let record = await store.getRecord (userId);

	if (!record.emp) {
		return [true, false];
	}
	let records = await store.getRecords ({
		model: "declaration",
		filters: [
			["emp", "=", record.emp]
		]
	});
	let cd = new Date (await store.remote ({model: "admin", method: "currentUTCTimestamp"}));
	let hasEditingDeclaration = false

	for (let i = 0; i < records.length; i ++) {
		let d = records [i].date;

		if (records [i].state == store.dict ["d.declaration.state"]["editing"].id) {
			hasEditingDeclaration = true
			continue;
		}
		if (d.getFullYear() == cd.getFullYear() ||
			(d.getFullYear() == cd.getFullYear() - 1 && (
				d.getMonth() > cd.getMonth() ||
				(d.getMonth() ==  cd.getMonth() && d.getDate() > cd.getDate())
			))
		) {
			return [false, false];
		}
	}
	return [true, hasEditingDeclaration];
}

function getJulianDay (d = new Date ()) {
	let dd = d.getDate ();
	let mm = d.getMonth () + 1;
	let yy = d.getFullYear ();

	return  (Math.floor ( 1461 * ( yy + 4800 + ( mm - 14 ) / 12)) / 4 + Math.floor (Math.floor ( 367 * ( mm - 2 - 12 * (( mm - 14 ) / 12))) / 12) - 3 * Math.floor (Math.floor ( yy + 4900 + ( mm - 14 ) / 12) / 100) / 4 + dd - 32075) | 0;
};

async function getOptions () {
	let records = await store.getRecords ({
		model: "objectum.option",
		filters: [
			["code", "=", "admin"]
		]
	});
	return JSON.parse (records [0].value || "{}");
}

const opts = {
	init,
	data,
	records,
	record,
	set,
	get,
	value,
	store,
	addListener,
	removeListener,
	hasDeclaration,
	getJulianDay,
	getOptions,
	canUserEdit,
	canPedagogEdit
};
export default opts;
export {
	init,
	data,
	records,
	record,
	set,
	get,
	value,
	store,
	addListener,
	removeListener,
	hasDeclaration,
	getJulianDay,
	getOptions,
	canUserEdit,
	canPedagogEdit
};
