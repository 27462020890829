import {useState} from "react";
import {Action, Grid} from "objectum-react";
import PageTitle from "./PageTitle";
import mediator, {store} from "../modules/mediator";

export default function Groups (props) {
	let [refresh, setRefresh] = useState (false);
	let [group, setGroup] = useState (document.location.href.split ("?")[1] ? mediator.get ("group") : null);

	function onCreate () {
		props.history.push ({
			pathname: "/group/new"
		});
	}
	function onEdit ({id}) {
		props.history.push ({
			pathname: "/group/" + id
		});
	}
	async function onRemove ({id}) {
		try {
			await store.startTransaction ("Удаление класса: " + id);
			await store.removeRecord (id);
			await store.commitTransaction ();
		} catch (err) {
			await store.rollbackTransaction ();
			throw err;
		}
		setRefresh (!refresh);
	}
	return <div>
		<div className="row">
			<div className="col-6">
				<PageTitle>Группы</PageTitle>
				<Grid
					{...props} id="groups" store={store} query="group.list" refresh={refresh}
					onSelect={id => {
						setGroup (id);
						mediator.set ("group", id);
					}}
				>
					<div className="d-flex pb-1">
						<Action icon="fas fa-plus" label="Добавить" onClick={onCreate} />
						<Action icon="fas fa-edit" label="Открыть" onClick={onEdit} selected />
						<Action icon="fas fa-minus" label="Удалить" onClick={onRemove} confirm selected />
					</div>
				</Grid>
			</div>
			<div className="col-6">
				<PageTitle>Педагоги</PageTitle>
				{group ? <Grid
					{...props} id="emps" store={store} query="group.emp" refresh={refresh}
					params={{group}}
				>
				</Grid> : <div className="alert alert-info">Выберите группу</div>}
			</div>
		</div>
	</div>;
};
