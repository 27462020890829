/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {useState, useEffect} from "react";
import PageTitle from "./PageTitle";
import {store} from "../modules/mediator";

export default function AllNews ({allNews}) {
	let [newRecords, setNewRecords] = useState ([]);

	useEffect (() => {
		async function load () {
			let newRecords = await store.getRecords ({model: "new"});
			newRecords.sort ((a, b) => a.date > b.date ? -1 : 1);
			setNewRecords (newRecords);
		}
		load ();
	}, []);

	let chief = document.location.search.indexOf ("=1") > -1;

	return <div>
		<PageTitle>Новости</PageTitle>
		{newRecords.filter (record => record.chief == chief).map ((record, i) => {
			return <div key={i} className="border rounded">
				<div className="p-2 my-1 border-bottom d-flex align-items-center">
					<div className="text-muted">{record.date.toLocaleDateString ()}</div>
					<div className="ml-2 font-weight-bold">{record.theme}</div>
				</div>
				<div className="p-2" dangerouslySetInnerHTML={{__html: record.text}} />
			</div>;
		})}
	</div>;
};
