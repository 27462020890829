import { useContext } from 'react'
import { DateField, DictField } from 'objectum-react'
import DashboardContext from './DashboardContext'

export default function DashboardFilter() {
    const { startDate, setStartDate, endDate, setEndDate, founderRecs, founder, setFounder } =
        useContext(DashboardContext)

    return (
        <div className="d-flex align-items-center p-1 border mb-1 mt-2 bg-white rounded">
            <h5 className="mr-2">Фильтр</h5>
            <DateField value={startDate} onChange={({ value }) => setStartDate(value)} />
            <div className="mx-1">-</div>
            <DateField value={endDate} onChange={({ value }) => setEndDate(value)} />
            <div className='ml-2' style={{width: '100%'}}>
                <DictField records={founderRecs} value={founder} onChange={({ value }) => setFounder(value)} />
            </div>
        </div>
    )
}
