/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {useState, useEffect} from "react";
import {Action, Grid, Loading} from "objectum-react";
import PageTitle from "./PageTitle";
import ExamCreator from "./ExamCreator";
import {store} from "../modules/mediator";

export default function Exams (props) {
	let [refresh, setRefresh] = useState (false);
	let [type, setType] = useState ("= 0");

	useEffect (() => {
		async function load () {
			await store.getDict ("d.exam.type");

			if (store.roleCode == "admin") {
				setType ("is not null");
			}
			if (store.roleCode == "pedmod") {
				setType ("=" + store.dict ["d.exam.type"]["ped"].id);
			}
			if (store.roleCode == "chiefmod") {
				setType ("=" + store.dict ["d.exam.type"]["chief"].id);
			}
			if (store.roleCode == "observer") {
				setType ("=" + store.dict ["d.exam.type"]["chief"].id);
			}
		}
		load ();
		// eslint-disable-next-line
	}, []);

	if (!type) {
		return <Loading />;
	}
	return <div>
		<PageTitle>Экзамены</PageTitle>
		{store.roleCode == "observer" ? null : <ExamCreator onCreateExams={() => setRefresh (!refresh)} />}
		<Grid {...props} id="exams" store={store} query="exam.list" params={{type}} refresh={refresh}>
			<div className="d-flex pb-1">
				{store.roleCode == "observer" ? null : <Action icon="fas fa-plus" label="Добавить" onClick={() => {
					props.history.push ({
						pathname: "/exam/new"
					});
				}} />}
				{store.roleCode == "observer" ? null : <Action icon="fas fa-edit" label="Открыть" onClick={({id}) => {
					props.history.push ({
						pathname: "/exam/" + id
					});
				}} selected />}
				{store.roleCode == "observer" ? null : <Action icon="fas fa-minus" label="Удалить" onClick={async ({id}) =>{
					let record = await store.getRecord (id);
					await record.remove ();
					setRefresh (!refresh);
				}} confirm selected transaction />}
				<Action icon="fas fa-user-friends" label="Участники" onClick={({id}) => {
					props.history.push ({
						pathname: "/exam_emp/" + id
					});
				}} selected />
			</div>
		</Grid>
	</div>;
};
