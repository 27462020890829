/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {i18n} from "objectum-react";
import {store} from "../modules/mediator.js";

export default function Month ({value, date, records, bg, onChange}) {
	let d = new Date (date);
	let rows = [], row = [], prevMonthWas = false;
	let weekDay = 1, num = 0, cd = new Date ();

	while (d.getDay () != 1) {
		d.setDate (d.getDate () - 1);
	}
	let days = [
		i18n ("Mon"),
		i18n ("Tue"),
		i18n ("Wed"),
		i18n ("Thu"),
		i18n ("Fri"),
		i18n ("Sat"),
		i18n ("Sun")
	];
	let months = [
		i18n ("January"),
		i18n ("February"),
		i18n ("March"),
		i18n ("April"),
		i18n ("May"),
		i18n ("June"),
		i18n ("July"),
		i18n ("August"),
		i18n ("September"),
		i18n ("October"),
		i18n ("November"),
		i18n ("December")
	];
	while (1) {
		let color = (d.getDay () == 6 || d.getDay () == 0) ? "text-danger" : "", title = "";
		let disabled = false;

		if (d.getMonth () == date.getMonth ()) {
			prevMonthWas = true;
		} else {
			color = "text-secondary";
		}
		let dateCls = "";
		let examRecord = records.find (({date}) => d.getFullYear () == date.getFullYear () && d.getMonth () == date.getMonth () && d.getDate () == date.getDate ());

		if (examRecord) {
			if (examRecord.type == store.dict ["d.exam.type"]["chief"].id) {
				dateCls = "text-white bg-primary";
			}
			if (examRecord.type == store.dict ["d.exam.type"]["ped"].id) {
				dateCls = "text-white bg-success";
			}
			if (examRecord.id == value) {
				dateCls = "text-white bg-danger";
			}
		}
		if (d.getFullYear () == cd.getFullYear () && d.getMonth () == cd.getMonth () && d.getDate () == cd.getDate ()) {
			dateCls += " border border-info";
		}
		let opts = {
			key: num,
			className: `${color} text-center`,
			title
		};
		if (disabled) {
			opts.className = "text-secondary text-center font-weight-light";
		} else {
			if (examRecord) {
				if (!(window.OBJECTUM_APP.hidePrevNextMonthDaysFromCalendar && color == "text-secondary")) {
					opts.onClick = () => {
						if (examRecord) {
							onChange (examRecord.id);
						}
					};
					opts.style = {cursor: "pointer"};
				}
			}
		}
		row.push (<td {...opts}>
			<div className={dateCls}>{(window.OBJECTUM_APP.hidePrevNextMonthDaysFromCalendar && color == "text-secondary") ? "" : d.getDate ()}</div>
		</td>);
		num ++;
		weekDay ++;
		d.setDate (d.getDate () + 1);

		if (weekDay == 8) {
			rows.push (<tr key={num}>{row}</tr>);
			weekDay = 1;
			row = [];
		}
		if (prevMonthWas && weekDay == 1 && (d.getMonth () != date.getMonth () || d.getDate () == 1)) {
			break;
		}
	}
	for (let i = rows.length; i < 6; i ++) {
		rows.push (<tr key={i}><td colSpan={7}><img width={1} height={1} alt="" /></td></tr>);
	}
	return <table className="table table-sm border mr-1">
		<thead>
		<tr>
			<th colSpan={7} className="text-center">{`${months [date.getMonth ()]}, ${date.getFullYear ()}`}</th>
		</tr>
		<tr>
			{days.map ((day, i) => <th key={i} className={`text-center ${i > 4 ? "text-danger" : ""}`}>{day}</th>)}
		</tr>
		</thead>
		<tbody>{rows}</tbody>
	</table>;
}
