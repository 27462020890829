/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import React, {useState, useEffect, useCallback} from "react";
import {Grid, Action, StringField, Loading} from "objectum-react";
import PageTitle from "./PageTitle";
import ChatMessages from "./ChatMessages";
import mediator, {store} from "../modules/mediator";

function ColorField ({value, onChange}) {
	let colors = ["#A93226", "#7D3C98", "#2E86C1", "#229954", "#D4AC0D", "#CA6F1E", "#A6ACAF"];
	return <div>
		<div className="form-group">
			<label>Цвет</label>
			<div className="d-flex">
				{colors.map ((color, i) => {
					return <div
						key={i} className={`border p-3 ${value == color ? "border-primary" : ""}`}
						style={{backgroundColor: color}}
						onClick={() => onChange ({value: color})}
					/>;
				})}
			</div>
		</div>
	</div>;
}

function ChatStyle ({recordId, onClose, grid}) {
	let [record, setRecord] = useState (null);
	let [label, setLabel] = useState (null);
	let [color, setColor] = useState (null);

	useEffect (() => {
		async function load () {
			let record = await store.getRecord (recordId);
			setRecord (record);
			setLabel (record.chatLabel);
			let style = JSON.parse (record.chatStyle || "{}");
			setColor (style.color);
		}
		load ();
		// eslint-disable-next-line
	}, []);

	if (!record) {
		return <Loading />;
	}
	return <div>
		<StringField label="Метка чата" value={label} onChange={({value}) => setLabel (value)} />
		<ColorField value={color} onChange={({value}) => setColor (value)} />
		<Action store={store} label="Ok" icon="fas fa-check" transaction onClick={async () => {
			record.chatLabel = label;
			let style = JSON.parse (record.chatStyle || "{}");
			style.color = color;
			record.chatStyle = JSON.stringify (style);
			await record.sync ();
			onClose ();
			grid.setState ({refresh: !grid.state.refresh});
		}} />
	</div>;
}
export default function Messages (props) {
	let [query, setQuery] = useState ("message.list");
	let [params, setParams] = useState ({role: "is not null"});
	let [loading, setLoading] = useState (true);
	let [user, setUser] = useState (null);
	let [messageRecords, setMessageRecords] = useState ([]);
	let [refresh] = useState (false);
	const onRecord = useCallback (async (data) => {
/*
	Добавляется повторно т.к. запись добавляется в onNewRecord
		let l = data.created.filter (({model}) => model == store.getModel ("message").id).map (({id}) => id);

		if (l.length) {
			let records = await store.getRecords ({
				model: "message",
				filters: [
					["id", "in", l]
				]
			});
			setMessageRecords ([...messageRecords, ...records]);
			console.log(records.map(record => record.id), records)

			if (records.find (record => record.user == user)) {
				setRefresh (!refresh);
			}
		}
*/
		// eslint-disable-next-line
	}, [user, messageRecords.length]);

	useEffect (() => {
		store.addListener ("record", onRecord);
		return () => store.removeListener ("record", onRecord);
		// eslint-disable-next-line
	}, [user, messageRecords.length]);

	useEffect (() => {
		async function load () {
			let messageRecords = await store.getRecords ({model: "message"});
			messageRecords.sort ((a, b) => a.date > b.date ? 1 : -1);
			setMessageRecords (messageRecords);

			if (store.roleCode == "pedmod") {
				setParams ({role: `=${store.dict ["objectum.role"].pedagog.id}`});
			}
			if (store.roleCode == "chiefmod") {
				setParams ({role: `=${store.dict ["objectum.role"].chief.id}`});
			}
			if (store.roleCode == "terrmod") {
				let terr = (await store.getRecord (mediator.record.user.emp)).terr;
				setQuery ("message.terrmod");
				setParams ({role: store.dict ["objectum.role"]["chief"].id, terr});
			}
			setLoading (false);
		}
		load ();
	}, []);

	function onRenderCell ({cell, col, rec}) {
		if (col.code == "info") {
			let num = 0, total = 0;
			messageRecords.forEach (record => {
				if (record.user == rec.id) {
					if (record.user != record.author) {
						num = 0;
					} else {
						num ++;
					}
					total ++;
				}
			});
			return <div>{num ? <span className="badge badge-primary mr-1">{num}</span> : null}{total ? <span className="badge badge-secondary">{total}</span> : null}</div>;
		} else {
			return cell;
		}
	}
	function onRenderRow ({row, rec}) {
		if (rec.chatStyle) {
			let style = JSON.parse (rec.chatStyle);
			return React.cloneElement (row, {style: {color: style.color}});
		} else {
			return row;
		}
	}
	if (loading) {
		return <Loading />;
	}
	return <div>
		<PageTitle>Сообщения</PageTitle>
		<div className="row">
			<div className="col-6">
				<Grid {...props} id="messages" store={store} query={query} params={params} onSelect={async (id) => {
					setUser (id);
				}} onRenderCell={onRenderCell} onRenderRow={onRenderRow}>
					<Action label="Метка" icon="fas fa-tags" selected popupComponent={ChatStyle}/>
				</Grid>
			</div>
			<div className="col">
				{user && <ChatMessages user={user} refresh={refresh} showEmpLink onNewRecord={record => {
					setMessageRecords ([...messageRecords, record]);
				}}/>}
			</div>
		</div>
	</div>;
};
