/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {useState, useReducer, useEffect, createRef} from "react";
import {Field, Action, Form, goRidLocation, Loading, Tabs, Tab, JsonField, StringField, Grid} from "objectum-react";
import mediator, {store} from "../modules/mediator";
import data from "../modules/data";

function init ({rid}) {
	return {
		loading: true,
		rid,
		emp: null,
		empRecord: null,
		orgRecords: [],
		eduRecords: [],
		trainingRecords: [],
		retrainingRecords: [],
		rewardRecords: [],
		specialRewardRecords: [],
		presentationRecords: [],
		inventoryRecords: [],
		studentContestRecords: [],
		eduActResultRecords: [],
		personalContributionRecords: [],
		otherCitizenshipVisible: false,
		eduCreationVisible: false,
		rewardCreationVisible: false,
		specialRewardCreationVisible: false,
		presentationCreationVisible: false,
		inventoryCreationVisible: false,
		studentContestCreationVisible: false,
		retrainingCreationVisible: false,
		eduActResultCreationVisible: false,
		personalContributionCreationVisible: false,
		scienceVisible: false,
		savingHasError: false,
		readOnly: true
	};
}

function reducer (state, action) {
	return Object.assign ({}, state, action);
}

export default function Pedagog (props) {
	let [state, dispatch] = useReducer (reducer, props, init);
	let [formRefs, setFormRefs] = useState ({});
	let allRecords = [
		...state.eduRecords,
		...state.trainingRecords,
		...state.retrainingRecords,
		...state.rewardRecords,
		...state.specialRewardRecords,
		...state.presentationRecords,
		...state.inventoryRecords,
		...state.studentContestRecords,
		...state.eduActResultRecords,
		...state.personalContributionRecords
	];
	useEffect (() => {
		let refs = {
			"emp-1": formRefs ["emp-1"] || createRef (),
			"emp-2": formRefs ["emp-2"] || createRef (),
			"emp-4": formRefs ["emp-2"] || createRef (),
			"user": formRefs ["user"] || createRef ()
		};
		allRecords.forEach (record => refs [record.id] = formRefs [record.id] || createRef ())
		setFormRefs (refs);
		// eslint-disable-next-line
	}, [allRecords.length]);

	useEffect (() => {
		async function load () {
			let action = {loading: false};
			let [orgRecords, dictEduActResultRecords, dictPersonalContributionRecords, stateRecords] = await Promise.all ([
				store.getRecords ({model: "org"}),
				store.getDict ("d.emp.eduActResult"),
				store.getDict ("d.emp.personalContribution"),
				store.getDict ("d.declaration.state"),
				store.getDict ("d.level"),
				store.getDict ("d.contest.type"),
				store.getDict ("d.emp.reward")
			]);
			let [hasDeclaration, hasEditingDeclaration] = await mediator.canPedagogEdit ()
			hasDeclaration = !hasDeclaration
			Object.assign (action, {orgRecords, dictEduActResultRecords, dictPersonalContributionRecords, stateRecords, hasDeclaration, hasEditingDeclaration});

			if (state.rid) {
				let userRecord = await store.getRecord (state.rid);

				if (userRecord.emp) {
					action.emp = userRecord.emp;
					action.loading = true;
				}
				let roleRecord = await store.getRecord (userRecord.role);

				if (["manager", "expert"].indexOf (roleRecord.code) > -1) {
					action.isExpert = true;
				}
			}
			action.isAdmin = store.username == "admin" || ["pedmod", "chiefmod", "admin"].indexOf (store.roleCode) > -1;

			if (store.roleCode == "pedObserver") {
				action.hasDeclaration = true
			} else {
				action.readOnly = false
			}
			dispatch (action);
		};
		load ();
	}, [state.rid]);

	useEffect (() => {
		async function load () {
			let action = {};

			if (state.emp) {
				action.loading = false;
				let [
					empRecord,
					declarationEmpRecords
				] = await Promise.all ([
					await store.getRecord (state.emp),
					await store.getRecords ({
						model: "t.declaration.emp",
						filters: [
							["emp", "=", state.emp]
						]
					})
				]);
				let {
					eduRecords,
					trainingRecords,
					retrainingRecords,
					rewardRecords,
					specialRewardRecords,
					presentationRecords,
					inventoryRecords,
					studentContestRecords,
					eduActResultRecords,
					personalContributionRecords
				} = await empRecord.loadPedagogData ();

				if (!eduRecords.length) {
					action.eduCreationVisible = true;
				}
				Object.assign (action, {
					empRecord,
					eduRecords,
					trainingRecords,
					retrainingRecords,
					rewardRecords,
					specialRewardRecords,
					presentationRecords,
					inventoryRecords,
					studentContestRecords,
					eduActResultRecords,
					personalContributionRecords,
				});
				action.empName = `${empRecord.surname} ${empRecord.forename} ${empRecord.patronymic || ""}`;
				action.category = empRecord.category;

				if (empRecord.scienceDegree || empRecord.scienceRank) {
					action.scienceVisible = true;
				}
				if (empRecord.citizenship) {
					let record = await store.getRecord (empRecord.citizenship);
					if (record.code == "other") {
						action.otherCitizenshipVisible = true;
					}
				}
				if (empRecord.org) {
					let orgRecord = await store.getRecord (empRecord.org);
					action.orgName = orgRecord.name;

					if (orgRecord.type) {
						const orgTypeRecord = await store.getRecord (orgRecord.type)
						action.orgTypeCode = orgTypeRecord.code == 'odpo' ? 'dpo' : orgTypeRecord.code
					}
				}
				if (empRecord.terr) {
					let terrRecord = await store.getRecord (empRecord.terr);
					action.terr = empRecord.terr;
					action.terrName = terrRecord.name;
				}
				eduActResultRecords.forEach (record => {
					if (record.eduActResult) {
						action [`urlLabel-${record.id}`] = getUrlName (record.eduActResult);
					}
				});
				action.declarations = declarationEmpRecords.map (record => record.declaration);
				let declarationRecords = await store.getRecords ({
					model: "declaration",
					filters: [
						["id", "in", action.declarations]
					]
				});
				action.activeDeclarations = declarationRecords.filter (record => [
					store.dict ["d.declaration.state"]["await"].id,
					store.dict ["d.declaration.state"]["processing"].id,
					store.dict ["d.declaration.state"]["expired"].id
				].indexOf (record.state) > -1).map (record => record.id);
				action.closedDeclarations = declarationRecords.filter (record => [
					store.dict ["d.declaration.state"]["assigned"].id,
					store.dict ["d.declaration.state"]["rejected"].id
				].indexOf (record.state) > -1).map (record => record.id);

				personalContributionRecords.forEach (record => action [`personalContribution-${record.id}`] = record.personalContribution);
				eduActResultRecords.forEach (record => {
					action [`eduActResult-${record.id}`] = record.eduActResult;
					action [`type-${record.id}`] = record.type;
				});
				rewardRecords.forEach (record => action [`rewardLevel-${record.id}`] = record.level);
				specialRewardRecords.forEach (record => action [`specialRewardLevel-${record.id}`] = record.level);
			}
			dispatch (action);
		};
		load ();
	}, [state.emp]);

	async function onCreate (rid) {
		dispatch ({rid});
		props.onCreate (rid);
		goRidLocation (props, rid);
	}

	async function onCreateEmp (emp) {
		try {
			await store.startTransaction ();
			let record = await store.getRecord (state.rid);
			record.emp = emp;
			await record.sync ();
			await store.commitTransaction ();
		} catch (err) {
			await store.rollbackTransaction ();
			throw err;
		}
		dispatch ({emp});
	}

	async function load (model) {
		let records = await store.getRecords ({model, filters: [["emp", "=", state.emp]]});
		let action = {};

		if (model == "t.emp.edu") {
			action.eduRecords  = records;
			action.eduCreationVisible = false;
		}
		if (model == "t.emp.training") {
			action.trainingRecords = records;
			action.trainingCreationVisible = false;
		}
		if (model == "t.emp.retraining") {
			action.retrainingRecords = records;
			action.retrainingCreationVisible = false;
		}
		if (model == "t.emp.reward") {
			action.rewardRecords = records;
			action.rewardCreationVisible = false;
		}
		if (model == "t.emp.specialReward") {
			action.specialRewardRecords = records;
			action.specialRewardCreationVisible = false;
		}
		if (model == "t.emp.presentation") {
			action.presentationRecords = records;
			action.presentationCreationVisible = false;
		}
		if (model == "t.emp.inventory") {
			action.inventoryRecords = records;
			action.inventoryCreationVisible = false;
		}
		if (model == "t.emp.studentContest") {
			action.studentContestRecords = records;
			action.studentContestCreationVisible = false;
		}
		if (model == "t.emp.eduActResult") {
			action.eduActResultRecords = records;
			action.eduActResultCreationVisible = false;
		}
		if (model == "t.emp.personalContribution") {
			action.personalContributionRecords = records;
			action.personalContributionCreationVisible = false;
		}
		dispatch (action);
	}
	async function removeRecord (id, model) {
		await store.startTransaction ();

		try {
			await store.removeRecord (id);
			await store.commitTransaction ();
			await load (model);
		} catch (err) {
			await store.rollbackTransaction ();
		}
	}
	function filterDict (d, codes) {
		return store.map.dict [d].filter (record => codes.indexOf (record.code) > -1);
	}

	function renderEdu (id) {
		return <div key={id} className={`border border-secondary p-1 mb-2 ${id ? "" : "bg-new"}`}>
			<Form ref={formRefs [id]} store={store} rsc="record" rid={id || null} mid="t.emp.edu" className="border mb-1" hideSaveButton
				  hideLogButton={hideLogButton} defaults={{emp: state.emp}} onCreate={async () => await load ("t.emp.edu")} disabled={!!state.hasDeclaration}
			>
				<div className="row">
					<div className="col-6">
						<Field property="orgStr" values={data.org} />
					</div>
					<div className="col-3">
						<Field property="startYear" />
					</div>
					<div className="col-3">
						<Field property="endYear" />
					</div>
				</div>
				<div className="row">
					<div className="col-6">
						<Field property="qualificationStr" />
					</div>
					<div className="col-6">
						<Field property="file" notNull description="Прикрепите цветной скан, в формате pdf, не более 8 мб" maxSize={8 * 1024 * 1024} accept=".pdf" />
					</div>
				</div>
			</Form>
			{id && !state.hasDeclaration && <div className="text-right">
				<Action label="Удалить" icon="fas fa-minus-circle" confirm onClick={() => removeRecord (id, "t.emp.edu")} />
			</div>}
		</div>;
	}
	function renderRetraining (id) {
		return <div key={id} className={`border border-secondary p-1 mb-2 ${id ? "" : "bg-new"}`}>
			<Form ref={formRefs [id]} store={store} rsc="record" rid={id || null} mid="t.emp.retraining" className="border mb-1" hideSaveButton
				  hideLogButton={hideLogButton} defaults={{emp: state.emp}} onCreate={async () => await load ("t.emp.retraining")} disabled={!!state.hasDeclaration}>
				<div className="row">
					<div className="col-8">
						<Field property="name" notNull />
					</div>
					<div className="col-4">
						<Field property="hours" notNull />
					</div>
				</div>
				<div className="row">
					<div className="col-4">
						<Field property="startDate" notNull />
					</div>
					<div className="col-4">
						<Field property="endDate" notNull />
					</div>
					<div className="col-4">
						<Field property="place" notNull />
					</div>
				</div>
				<div className="row">
					<div className="col-4">
						<Field property="num" notNull />
					</div>
					<div className="col-4">
						<Field property="regNum" notNull />
					</div>
					<div className="col-4">
						<Field property="file" notNull />
					</div>
				</div>
			</Form>
			{id && !state.hasDeclaration && <div className="text-right">
				<Action label="Удалить" icon="fas fa-minus-circle" confirm onClick={() => removeRecord (id, "t.emp.retraining")} />
			</div>}
		</div>;
	}
	function renderReward (id) {
		return <div key={id} className={`border border-secondary p-1 mb-2 ${id ? "" : "bg-new"}`}>
			<Form ref={formRefs [id]} store={store} rsc="record" rid={id || null} mid="t.emp.reward" className="border mb-1" hideSaveButton
				  hideLogButton={hideLogButton} defaults={{emp: state.emp}} onCreate={async () => await load ("t.emp.reward")} disabled={!!state.hasDeclaration}>
				<div className="row">
					<div className="col-3">
						<Field property="level"
							   dict records={filterDict ("d.level", ["region", "fed", "mun", "international"])}
							   onChange={({value}) => dispatch ({[`rewardLevel-${id}`]: value})}
						/>
					</div>
					<div className="col-9">
						<Field property="reward" dict
							   label="Награда за успехи в профессиональной деятельности, наличие ученой степени, звания"
							   records={store.map.dict ["d.emp.reward"].filter (record => record.level == state [`rewardLevel-${id}`] && record.industry)}
							   disabled={!state [`rewardLevel-${id}`]}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-6">
						<Field property="url" />
					</div>
					<div className="col-6">
						<Field property="file" notNull description="Прикрепите цветной скан, в формате pdf, не более 8 мб" maxSize={8 * 1024 * 1024} accept=".pdf" />
					</div>
				</div>
			</Form>
			{id && !state.hasDeclaration && <div className="text-right">
				<Action label="Удалить" icon="fas fa-minus-circle" confirm onClick={() => removeRecord (id, "t.emp.reward")} />
			</div>}
		</div>;
	}
	function renderSpecialReward (id) {
		return <div key={id} className={`border border-secondary p-1 mb-2 ${id ? "" : "bg-new"}`}>
			<Form ref={formRefs [id]} store={store} rsc="record" rid={id || null} mid="t.emp.specialReward" className="border mb-1" hideSaveButton
				  hideLogButton={hideLogButton} defaults={{emp: state.emp}} onCreate={async () => await load ("t.emp.specialReward")} disabled={!!state.hasDeclaration}>
				<div className="row">
					<div className="col-3">
						<Field property="level"
							   dict records={filterDict ("d.level", ["region", "fed", "mun", "international"])}
							   onChange={({value}) => dispatch ({[`specialRewardLevel-${id}`]: value})}
						/>
					</div>
					<div className="col-9">
						<Field property="reward" dict
							   label="Награда за успехи в профессиональной деятельности, наличие ученой степени, звания"
							   records={store.map.dict ["d.emp.reward"].filter (record => record.level == state [`specialRewardLevel-${id}`] && record.special)}
							   disabled={!state [`specialRewardLevel-${id}`]}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-6">
						<Field property="url" />
					</div>
					<div className="col-6">
						<Field property="file" notNull description="Прикрепите цветной скан, в формате pdf, не более 8 мб" maxSize={8 * 1024 * 1024} accept=".pdf" />
					</div>
				</div>
			</Form>
			{id && !state.hasDeclaration && <div className="text-right">
				<Action label="Удалить" icon="fas fa-minus-circle" confirm onClick={() => removeRecord (id, "t.emp.specialReward")} />
			</div>}
		</div>;
	}
	function renderStudentContest (id) {
		return <div key={id} className={`border border-secondary p-1 mb-2 ${id ? "" : "bg-new"}`}>
			<Form ref={formRefs [id]} store={store} rsc="record" rid={id || null} mid="t.emp.studentContest"
				  className="border mb-1" hideLogButton={hideLogButton} defaults={{emp: state.emp}} hideSaveButton
				  onCreate={async () => await load ("t.emp.studentContest")} disabled={!!state.hasDeclaration}
			>
				<div className="row">
					<div className="col-3">
						<Field property="level" dict records={filterDict ("d.level", ["region", "fed", "mun", "international"])} />
					</div>
					<div className="col-3">
						<Field property="result" dict />
					</div>
					<div className="col-6">
						<Field property="file" notNull description="Прикрепите цветной скан, в формате pdf, не более 8 мб" maxSize={8 * 1024 * 1024} accept=".pdf" />
					</div>
				</div>
			</Form>
			{id && !state.hasDeclaration && <div className="text-right">
				<Action label="Удалить" icon="fas fa-minus-circle" confirm onClick={() => removeRecord (id, "t.emp.studentContest")} />
			</div>}
		</div>;
	}
	function renderTraining (id) {
		return <div key={id} className={`border border-secondary p-1 mb-2 ${id ? "" : "bg-new"}`}>
			<Form ref={formRefs [id]} store={store} rsc="record" rid={id || null} mid="t.emp.training" className="border mb-1" hideSaveButton
				  hideLogButton={hideLogButton} defaults={{emp: state.emp}} onCreate={async () => await load ("t.emp.training")} disabled={!!state.hasDeclaration}>
				<div className="row">
					<div className="col-8">
						<Field property="name" />
					</div>
					<div className="col-2">
						<Field property="startDate" min={new Date ().getFullYear () - 3} />
					</div>
					<div className="col-2">
						<Field property="endDate" min={new Date ().getFullYear () - 3} />
					</div>
				</div>
				<div className="row">
					<div className="col-4">
						<Field property="docNum" />
					</div>
					<div className="col-4">
						<Field property="regNum" />
					</div>
					<div className="col-4">
						<Field property="hours" />
					</div>
				</div>
				<div className="row">
					<div className="col-6">
						<Field property="place" />
					</div>
					<div className="col-6">
						<Field property="file" notNull description="Прикрепите цветной скан, в формате pdf, не более 8 мб" maxSize={8 * 1024 * 1024} accept=".pdf" />
					</div>
				</div>
			</Form>
			{id && !state.hasDeclaration && <div className="text-right">
				<Action label="Удалить" icon="fas fa-minus-circle" confirm onClick={() => removeRecord (id, "t.emp.training")} />
			</div>}
		</div>;
	}
	function renderPresentation (id) {
		return <div key={id} className={`border border-secondary p-1 mb-2 ${id ? "" : "bg-new"}`}>
			<Form ref={formRefs [id]} store={store} rsc="record" rid={id || null} mid="t.emp.presentation" className="border mb-1" hideSaveButton
				  hideLogButton={hideLogButton} defaults={{emp: state.emp}} onCreate={async () => await load ("t.emp.presentation")} disabled={!!state.hasDeclaration}>
				<div className="row">
					<div className="col-6">
						<Field property="name" />
					</div>
					<div className="col-6">
						<Field property="file" notNull description="Прикрепите цветной скан, в формате pdf, не более 8 мб" maxSize={8 * 1024 * 1024} accept=".pdf" />
					</div>
				</div>
			</Form>
			{id && !state.hasDeclaration && <div className="text-right">
				<Action label="Удалить" icon="fas fa-minus-circle" confirm onClick={() => removeRecord (id, "t.emp.presentation")} />
			</div>}
		</div>;
	}
	function renderInventory (id) {
		return <div key={id} className={`border border-secondary p-1 mb-2 ${id ? "" : "bg-new"}`}>
			<Form ref={formRefs [id]} store={store} rsc="record" rid={id || null} mid="t.emp.inventory" className="border mb-1" hideSaveButton
				  hideLogButton={hideLogButton} defaults={{emp: state.emp}} onCreate={async () => await load ("t.emp.inventory")} disabled={!!state.hasDeclaration}>
				<div className="row">
					<div className="col-6">
						<Field property="name" />
					</div>
					<div className="col-6">
						<Field property="file" notNull description="Прикрепите цветной скан, в формате pdf, не более 8 мб" maxSize={8 * 1024 * 1024} accept=".pdf" />
					</div>
				</div>
			</Form>
			{id && !state.hasDeclaration && <div className="text-right">
				<Action label="Удалить" icon="fas fa-minus-circle" confirm onClick={() => removeRecord (id, "t.emp.inventory")} />
			</div>}
		</div>;
	}
	function getUrlName (id) {
		let record = store.map ["record"][id];

		if (record && record.code == "sysMon") {
			return "Ссылка на показатели";
		}
		if (record && record.code == "act") {
			return "Ссылка на приказ о награждении";
		}
		return "Ссылка";
	}

	function renderEduActResult (id) {
		return <div key={id} className={`border border-secondary p-1 mb-2 ${id ? "" : "bg-new"}`}>
			<Form
				ref={formRefs [id]} store={store} rsc="record" hideSaveButton
				rid={id || null} mid="t.emp.eduActResult" className="border mb-1" hideLogButton={hideLogButton} defaults={{emp: state.emp}}
				onCreate={async () => await load ("t.emp.eduActResult")}
				onChange={({property, value}) => {
					if (property == "eduActResult") {
						dispatch ({
							[`urlLabel-${id}`]: getUrlName (value),
							[`eduActResult-${id}`]: value
						});
					}
					if (property == "type") {
						dispatch ({[`type-${id}`]: value});
					}
				}}
				disabled={!!state.hasDeclaration}
			>
				<Field property="eduActResult" dict />
				<div className="row">
					<div className="col-6">
						{store.dict ["d.emp.eduActResult"]["act"].id == state [`eduActResult-${id}`] && <div>
							<Field property="type" dict records={filterDict ("d.contest.type", ["conference", "olymp", "contest"])}/>
						</div>}
					</div>
					<div className="col-6">
						{store.dict ["d.contest.type"]["contest"].id == state [`type-${id}`] && <div>
							<Field property="level" dict records={filterDict ("d.level", ["official", "creative"])}/>
						</div>}
						{state [`type-${id}`] && store.dict ["d.contest.type"]["contest"].id != state [`type-${id}`] && <div>
							<Field property="level" dict records={filterDict ("d.level", ["fed", "mun", "republic"])}/>
						</div>}
					</div>
				</div>
				<div className="row">
					<div className="col-6">
						<JsonField property="url" label={state [`urlLabel-${id}`] || "Ссылка"} multi props={[
							{prop: "url", component: StringField}
						]} />
					</div>
					<div className="col-6">
						<Field property="file" notNull description="Прикрепите цветной скан, в формате pdf, не более 8 мб" maxSize={8 * 1024 * 1024} accept=".pdf" />
					</div>
				</div>
			</Form>
			{id && !state.hasDeclaration && <div className="text-right">
				<Action label="Удалить" icon="fas fa-minus-circle" confirm onClick={() => removeRecord (id, "t.emp.eduActResult")} />
			</div>}
		</div>;
	}
	function renderPersonalContribution (id) {
		let levelEl = null;

		if (store.dict ["d.emp.personalContribution"]["expert"].id == state [`personalContribution-${id}`]) {
			levelEl = <div className="row">
				<div className="col-6">
					<Field property="level" dict records={filterDict ("d.level", ["mun", "region"])}/>
				</div>
			</div>;
		}
		if (store.dict ["d.emp.personalContribution"]["3.2"].id == state [`personalContribution-${id}`]) {
			levelEl = <div className="row">
				<div className="col-6">
					<Field property="level" dict records={filterDict ("d.level", ["mun", "region", "fed", "internet"])}/>
				</div>
			</div>;
		}
		return <div key={id} className={`border border-secondary p-1 mb-2 ${id ? "" : "bg-new"}`}>
			<Form
				ref={formRefs [id]} store={store} rsc="record" rid={id || null} mid="t.emp.personalContribution" hideSaveButton
				className="border mb-1" hideLogButton={hideLogButton} defaults={{emp: state.emp}} onCreate={async () => await load ("t.emp.personalContribution")}
				onChange={({property, value}) => {
					if (property == "personalContribution") {
						dispatch ({[`personalContribution-${id}`]: value});
					}
				}}
				disabled={!!state.hasDeclaration}
			>
				<Field property="personalContribution" dict />
				{levelEl}
				<div className="row">
					<div className="col-6">
						<Field property="url" notNull />
					</div>
					<div className="col-6">
						<Field property="file" notNull description="Прикрепите цветной скан, в формате pdf, не более 8 мб" maxSize={8 * 1024 * 1024} accept=".pdf" />
					</div>
				</div>
			</Form>
			{id && !state.hasDeclaration && <div className="text-right">
				<Action label="Удалить" icon="fas fa-minus-circle" confirm onClick={() => removeRecord (id, "t.emp.personalContribution")} />
			</div>}
		</div>;
	}
	async function onSave ({progress}) {
		let forms = [], savingHasError = false;
		dispatch ({saving: true});

		for (let el of Object.values (formRefs)) {
			if (el.current && el.current.isChanged ()) {
				forms.push(el.current);
			}
		}
		for (let i = 0; i < forms.length; i ++) {
			progress ({value: i + 1, max: forms.length});
			let saveResult = await forms[i].onSave ();

			if (!saveResult) {
				savingHasError = true;
			}
		}
		dispatch ({saving: false, savingHasError});
	}
	if (state.loading) {
		return <Loading />;
	}
	let hideLogButton = ["admin", "pedmod", "chiefmod"].indexOf (store.roleCode) == -1;
	const showPresentationInventory = [
		store.dict ["d.emp.category"]["metodist"]?.id,
		store.dict ["d.emp.category"]["nastavnik"]?.id
	].includes(state.empRecord?.submitCategory)

	return <div>
		{state.empName && <table className="table table-sm bg-empinfo">
			<thead>
			<tr>
				<th>Ф.И.О.</th><th>Текущее место работы</th><th>Территория</th>
			</tr>
			</thead>
			<tbody>
			<tr>
				<td>{state.empName}</td>
				<td>{state.orgName}</td>
				<td>{state.terrName}</td>
			</tr>
			</tbody>
		</table>}
		{!!state.hasDeclaration && !state.readOnly && <div className="alert alert-info my-2">Редактирование недоступно т.к. вы подали заявление в текущем учебном году</div>}
		<Tabs>
			<Tab label="Общие сведения"><div className="p-1">
				<div className="border border-secondary mb-3 bg-empinfo p-1">
					<h5>Учетная запись пользователя</h5>
					<Form
						ref={formRefs ["user"]} store={store} rsc="record" rid={state.rid} mid="objectum.user"
						onCreate={onCreate} className="border mb-1" hideLogButton={hideLogButton} hideSaveButton
						disabled={state.readOnly || !!state.hasDeclaration || state.hasEditingDeclaration}
					>
						<div className="row">
							<div className="col-4">
								<Field property="login" onChange={opts => opts.value = opts.value.trim ().toLowerCase ()} />
							</div>
							<div className="col-4">
								<Field property="password" />
							</div>
							<div className="col-4">
								<Field property="role" dict disabled={["pedagog", "chief", "expert", "pedmod", "chiefmod", "terrmod"].indexOf (mediator.record.role?.code) > -1}
									value={store.roleCode == "pedmod" ? store.dict ["objectum.role"].pedagog.id : (store.roleCode == "chiefmod" ? store.dict ["objectum.role"].chief.id : null)}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-4">
								<Field property="email" notNull />
							</div>
							<div className="col-4">
								<Field property="snils" regexp="/^[0-9]{3}-[0-9]{3}-[0-9]{3}-[0-9]{2}$/"
									   exampleValue="111-111-111-11" disabled={!!state.hasDeclaration}
									   onChange={opts => {
										   let v = opts.value || "";

										   if (/^[0-9]{3}$/.test (v) || /^[0-9]{3}-[0-9]{3}$/.test (v) || /^[0-9]{3}-[0-9]{3}-[0-9]{3}$/.test (v)) {
											   opts.value = v + "-";
										   }
									   }}
								/>
							</div>
							<div className="col-4">
								<Field property="regDate" disabled={!state.isAdmin} />
							</div>
						</div>
					</Form>
				</div>
				{state.rid && <div>
					<div className="border p-2 mb-3">
						<Form ref={formRefs ["emp-1"]} store={store} rsc="record" rid={state.emp} mid="emp" onCreate={onCreateEmp} hideSaveButton
							  className="border mb-1" hideLogButton={hideLogButton} disabled={!!state.hasDeclaration || state.hasEditingDeclaration}>
							<div className="row">
								<div className="col-4">
									<Field property="surname" onChange={opts => opts.value = opts.value.trim()} />
								</div>
								<div className="col-4">
									<Field property="forename" onChange={opts => opts.value = opts.value.trim()} />
								</div>
								<div className="col-4">
									<Field property="patronymic" onChange={opts => opts.value = opts.value.trim()} />
								</div>
							</div>
							<div className="row">
								<div className="col-4">
									<Field property="sex" notNull />
								</div>
								<div className="col-4">
									<Field property="citizenship" notNull dict onChange={({value}) => {
										let otherCitizenshipVisible = false;

										if (value) {
											let record = store.map ["record"][value];
											if (record.code == "other") {
												otherCitizenshipVisible = true;
											}
										}
										dispatch ({otherCitizenshipVisible});
									}} />
								</div>
								<div className="col-4">
									{state.otherCitizenshipVisible ? <Field property="otherCitizenship" /> : null}
								</div>
							</div>
							<div className="row">
								<div className="col-4">
									{store.roleCode != "pedObserver" ? <Field property="phone" notNull /> : null}
								</div>
								<div className="col-4">
									<Field property="birthdate" />
								</div>
								<div className="col-4">
								</div>
							</div>
							<Field property="address" notNull values={data.terr} />
							<Field property="nameChangeDoc"
								   description={<div>
									   <div>Прикрепите цветной скан, в формате pdf, не более 8 мб</div>
									   <div className="text-danger">При отсутствии документа заявление будет отклонено!</div>
								   </div>}
								   maxSize={8 * 1024 * 1024} accept=".pdf" />
						</Form>
					</div>
					{state.emp && !state.hasDeclaration && !state.hasEditingDeclaration && <Action label={state.saving ? "Сохранение" : "Сохранить изменения"} disabled={state.saving} icon="fas fa-check" onClick={onSave} />}
					{state.savingHasError ? <div className="alert alert-danger my-1">Форма содержит ошибки</div> : null}
				</div>}
			</div></Tab>
			{state.emp && <Tab label="Профессиональная деятельность"><div className="p-1">
				<div className="mb-2 bg-empinfo p-1">
					<h6>Образование</h6>
					{state.eduRecords.map (record => renderEdu (record.id))}
					{!state.hasDeclaration && <div className="text-right mb-1">
						<Action label={state.eduCreationVisible ? "Закрыть" : "Открыть"} icon={`fas fa-angle-double-${state.eduCreationVisible ? "up": "down"}`} onClick={() => dispatch ({eduCreationVisible: !state.eduCreationVisible})} />
					</div>}
					{state.eduCreationVisible && renderEdu ()}
				</div>
				<div className="mb-2 bg-empinfo p-1">
					<h6>Научная деятельность</h6>
					{state.scienceVisible ? <Form ref={formRefs ["emp-4"]} store={store} rsc="record" rid={state.emp} mid="emp" className="border mb-1" hideSaveButton hideLogButton={hideLogButton} disabled={!!state.hasDeclaration} onValidate={({values, errors}) => {
						if (values.scienceDegree && !values.degreeFile) {
							errors.degreeFile = "Пожалуйста прикрепите скан документа";
						}
						if (values.scienceRank && !values.rankFile) {
							errors.rankFile = "Пожалуйста прикрепите скан документа";
						}
					}}>
						<div className="border my-2 p-2">
							<div className="row">
								<div className="col-4">
									<Field property="scienceDegree" dict />
								</div>
								<div className="col-4">
									<Field property="degreeDate" />
								</div>
								<div className="col-4">
									<Field property="degreeCity" />
								</div>
							</div>
							<div className="row">
								<div className="col-4">
									<Field property="degreeRegNum" />
								</div>
								<div className="col-4">
									<Field property="degreeTheme" />
								</div>
								<div className="col-4">
									<Field property="degreeFile" />
								</div>
							</div>
						</div>
						<div className="border my-2 p-2">
							<div className="row">
								<div className="col-4">
									<Field property="scienceRank" dict />
								</div>
								<div className="col-4">
									<Field property="rankRegNum" />
								</div>
								<div className="col-4">
									<Field property="rankDate" />
								</div>
							</div>
							<div className="row">
								<div className="col-4">
									<Field property="rankTheme" />
								</div>
								<div className="col-4" />
								<div className="col-4">
									<Field property="rankFile" />
								</div>
							</div>
						</div>
						<div className="border my-2 p-2">
							<JsonField property="articleUrl" label="Статьи или исследования" multi col props={[
								{prop: "article", label: "Название", col: 6, component: StringField},
								{prop: "url", label: "Ссылка", col: 6, component: StringField}
							]} />
						</div>
					</Form> : null}
					<div className="text-right mb-1">
						<Action label={state.scienceVisible ? "Закрыть" : "Открыть"} icon={`fas fa-angle-double-${state.scienceVisible ? "up": "down"}`} onClick={() => dispatch ({scienceVisible: !state.scienceVisible})} />
					</div>
				</div>
				<div className="mb-2 bg-empinfo p-1">
					<h6>Переподготовка</h6>
					{state.retrainingRecords.map (record => renderRetraining (record.id))}
					{!state.hasDeclaration && <div className="text-right mb-1">
						<Action label={state.retrainingCreationVisible ? "Закрыть" : "Открыть"} icon={`fas fa-angle-double-${state.retrainingCreationVisible ? "up" : "down"}`} onClick={() => dispatch ({retrainingCreationVisible: !state.retrainingCreationVisible})} />
					</div>}
					{state.retrainingCreationVisible && renderRetraining ()}
				</div>
				<div className="mb-3 bg-empinfo p-1">
					<h6>Отраслевые награды</h6>
					{state.rewardRecords.map (record => renderReward (record.id))}
					{!state.hasDeclaration && <div className="text-right mb-1">
						<Action label={state.rewardCreationVisible ? "Закрыть" : "Открыть"} icon={`fas fa-angle-double-${state.rewardCreationVisible ? "up" : "down"}`} onClick={() => dispatch ({rewardCreationVisible: !state.rewardCreationVisible})} />
					</div>}
					{state.rewardCreationVisible && renderReward ()}
				</div>
				<Form ref={formRefs ["emp-2"]} store={store} rsc="record" rid={state.emp} mid="emp" className="border mb-1" hideSaveButton
					  hideLogButton={hideLogButton} disabled={!!state.hasDeclaration}>
					<div className="row">
						<div className="col-6">
							<Field property="terr" dict onChange={({value}) => {
								if (value != state.empRecord.terr) {
									formRefs['emp-2'].current.setState ({org: null, post: null})
								}
								dispatch ({terr: value})
							}} notNull />
						</div>
					</div>
					<div className="row">
						<div className="col-6">
							<Field property="org" dict records={state.terr ? state.orgRecords.filter (record => record.terr == state.terr) : state.orgRecords}
								   onChange={async ({value}) => {
									   let orgTypeCode = ''

									   if (value) {
										   const orgRecord = await store.getRecord (value)

										   if (orgRecord.type) {
											   const orgTypeRecord = await store.getRecord (orgRecord.type)
											   orgTypeCode = orgTypeRecord.code == 'odpo' ? 'dpo' : orgTypeRecord.code
										   }
									   }
									   if (value != state.empRecord.org) {
										   formRefs['emp-2'].current.setState ({post: null})
									   }
									   dispatch ({orgTypeCode})
								   }} notNull />
						</div>
					</div>
					<div className="row">
						<div className="col-6">
							<Field property="post" dict notNull records={store.map.dict ["d.emp.post"].filter (record => {
								let result = record.pedagog

								if (result) {
									result = record[state.orgTypeCode]
								}
								return result
							})} />
						</div>
					</div>
					<div className="row">
						<div className="col-6">
							<Field property="pedExp" notNull />
						</div>
					</div>
					<div className="row">
						<div className="col-6">
							<Field property="category" dict onChange={({value}) => {
								if (value == store.dict ["d.emp.category"]["none"].id) {
									formRefs['emp-2'].current.setState ({submitCategory: null})
								}
								dispatch ({category: value})
							}} notNull />
						</div>
						<div className="col-6">
							<Field property="categoryDoc" notNull={state.category && state.category != store.dict ["d.emp.category"]["none"].id} />
						</div>
					</div>
					<div className="row">
						<div className="col-6">
							<Field property="submitCategory" dict records={
								store.map.dict ["d.emp.category"].filter (record => {
									if (state.category == store.dict ["d.emp.category"]["none"].id) {
										return record.code == 'first'
									} else {
										return record.code != 'none'
									}
								})
							} />
						</div>
						<div className="col-6">
							<Field property="analyticalReport" notNull />
						</div>
					</div>
				</Form>
				{!state.hasDeclaration && <Action label={state.saving ? "Сохранение" : "Сохранить изменения"} disabled={state.saving} icon="fas fa-check" onClick={onSave} />}
				{state.savingHasError ? <div className="alert alert-danger my-1">Форма содержит ошибки</div> : null}
			</div></Tab>}
			{state.emp && !state.isExpert && <Tab label="Аттестация на присвоение категории"><div className="p-1">
				{!showPresentationInventory ? <div className="pb-2 mb-2 border-bottom bg-empinfo p-1">
					<h6>Результаты участия обучающихся в олимпиадах, конкурсах, конференциях (по преподаваемому предмету)</h6>
					{state.studentContestRecords.map (record => renderStudentContest (record.id))}
					{!state.hasDeclaration && <div className="text-right mb-1">
						<Action label={state.studentContestCreationVisible ? "Закрыть" : "Открыть"} icon={`fas fa-angle-double-${state.studentContestCreationVisible ? "up" : "down"}`} onClick={() => dispatch ({studentContestCreationVisible: !state.studentContestCreationVisible})} />
					</div>}
					{state.studentContestCreationVisible && renderStudentContest ()}
				</div> : null}
				{!showPresentationInventory ? <div className="pb-2 mb-2 border-bottom bg-empinfo p-1">
					<h6>Повышение квалификации</h6>
					{state.trainingRecords.map (record => renderTraining (record.id))}
					{!state.hasDeclaration && <div className="text-right mb-1">
						<Action label={state.trainingCreationVisible ? "Закрыть" : "Открыть"} icon={`fas fa-angle-double-${state.trainingCreationVisible ? "up" : "down"}`} onClick={() => dispatch ({trainingCreationVisible: !state.trainingCreationVisible})} />
					</div>}
					{state.trainingCreationVisible && renderTraining ()}
				</div> : null}
				{!showPresentationInventory ? <div className="pb-2 mb-2 border-bottom bg-empinfo p-1">
					<h6>Результаты образовательной деятельности</h6>
					{state.eduActResultRecords.map (record => renderEduActResult (record.id))}
					{!state.hasDeclaration && <div className="text-right mb-1">
						<Action label={state.eduActResultCreationVisible ? "Закрыть" : "Открыть"} icon={`fas fa-angle-double-${state.eduActResultCreationVisible ? "up" : "down"}`} onClick={() => dispatch ({eduActResultCreationVisible: !state.eduActResultCreationVisible})} />
					</div>}
					{state.eduActResultCreationVisible && renderEduActResult ()}
				</div> : null}
				{!showPresentationInventory ? <div className="pb-2 mb-2 border-bottom bg-empinfo p-1">
					<h6>Личный вклад и достижения</h6>
					{state.personalContributionRecords.map (record => renderPersonalContribution (record.id))}
					{!state.hasDeclaration && <div className="text-right mb-1">
						<Action label={state.personalContributionCreationVisible ? "Закрыть" : "Открыть"} icon={`fas fa-angle-double-${state.personalContributionCreationVisible ? "up" : "down"}`} onClick={() => dispatch ({personalContributionCreationVisible: !state.personalContributionCreationVisible})} />
					</div>}
					{state.personalContributionCreationVisible && renderPersonalContribution ()}
				</div> : null}
				{!showPresentationInventory ? <div className="mb-3 bg-empinfo p-1">
					<h6>Особые отраслевые награды</h6>
					{state.specialRewardRecords.map (record => renderSpecialReward (record.id))}
					{!state.hasDeclaration && <div className="text-right mb-1">
						<Action label={state.specialRewardCreationVisible ? "Закрыть" : "Открыть"} icon={`fas fa-angle-double-${state.specialRewardCreationVisible ? "up" : "down"}`} onClick={() => dispatch ({specialRewardCreationVisible: !state.specialRewardCreationVisible})} />
					</div>}
					{state.specialRewardCreationVisible && renderSpecialReward ()}
				</div> : null}
				{showPresentationInventory ? <div className="mb-3 bg-empinfo p-1">
					<h6>Представление ОО</h6>
					{state.presentationRecords.map (record => renderPresentation (record.id))}
					{!state.hasDeclaration && <div className="text-right mb-1">
						<Action label={state.presentationCreationVisible ? "Закрыть" : "Открыть"} icon={`fas fa-angle-double-${state.presentationCreationVisible ? "up" : "down"}`} onClick={() => dispatch ({presentationCreationVisible: !state.presentationCreationVisible})} />
					</div>}
					{state.presentationCreationVisible && renderPresentation ()}
				</div> : null}
				{showPresentationInventory ? <div className="mb-3 bg-empinfo p-1">
					<h6>Опись документов</h6>
					{state.inventoryRecords.map (record => renderInventory (record.id))}
					{!state.hasDeclaration && <div className="text-right mb-1">
						<Action label={state.inventoryCreationVisible ? "Закрыть" : "Открыть"} icon={`fas fa-angle-double-${state.inventoryCreationVisible ? "up" : "down"}`} onClick={() => dispatch ({inventoryCreationVisible: !state.inventoryCreationVisible})} />
					</div>}
					{state.inventoryCreationVisible && renderInventory ()}
				</div> : null}
				{!state.hasDeclaration && <Action label={state.saving ? "Сохранение" : "Сохранить изменения"} disabled={state.saving} icon="fas fa-check" onClick={onSave} />}
				{state.savingHasError ? <div className="alert alert-danger my-1">Форма содержит ошибки</div> : null}
			</div></Tab>}
			{state.emp && state.isExpert && <Tab label="Заявления"><div className="p-2">
				<Tabs id="declarationTabs">
					<Tab label={<span>Ожидающие рассмотрения <span className="badge badge-primary">{state.activeDeclarations.length}</span></span>}><div className="p-2">
						<Grid {...props} id="activeDeclarations" store={store} query="declaration.expert" params={{
							emp: "is not null", state: "is not null",
							id: `in (${state.activeDeclarations.length ? state.activeDeclarations : 0})`
						}} />
					</div></Tab>
					<Tab label={<span>Рассмотренные <span className="badge badge-primary">{state.closedDeclarations.length}</span></span>}><div className="p-2">
						<Grid {...props} id="closedDeclarations" store={store} query="declaration.expert" params={{
							emp: "is not null", state: "is not null",
							id: `in (${state.closedDeclarations.length ? state.closedDeclarations : 0})`
						}} />
					</div></Tab>
				</Tabs>
			</div></Tab>}
		</Tabs>
	</div>;
};
