/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import { useState, useEffect } from 'react'
import DashboardContext from './DashboardContext'
import { store } from '../modules/mediator.js'
import { Loading } from 'objectum-react'

const initStartDate = new Date()
initStartDate.setMonth(initStartDate.getMonth() - 1)
const initEndDate = new Date()

export default function DashboardProvider({ children }) {
    const [loading, setLoading] = useState(true)
    const [startDate, setStartDate] = useState(initStartDate)
    const [endDate, setEndDate] = useState(initEndDate)
    const [examRecords, setExamRecords] = useState([])
    const [founderRecs, setFounderRecs] = useState([])
    const [userRecs, setUserRecs] = useState([])
    const [founder, setFounder] = useState(null)
    const [declarationRecs, setDeclarationRecs] = useState([])
    const [lastRecs, setLastRecs] = useState([])

    useEffect(() => {
        async function load() {
            await store.getDict('d.exam.type')

            const [examRecords, founderRecs, userRecs, declarationRecs] = await Promise.all([
                store.getRecords({
                    model: 'exam',
                    filters: [['type', '=', store.dict['d.exam.type']['chief'].id]],
                }),
                store.getRecs({ model: 'd.founder' }),
                store.getRecs({
                    model: 'objectum.user',
                    filters: [
                        ['emp', 'is not null'],
                        ['role', '=', store.dict['objectum.role']['chief'].id],
                    ],
                }),
                store.getRecs({ query: 'declaration.empDate' }),
                store.getDict('d.person.sex'),
                store.getDict('d.declaration.state')
            ])
            setExamRecords(examRecords)
            founderRecs.sort((a, b) => (a.name > b.name ? 1 : -1))
            setFounderRecs(founderRecs)
            userRecs.sort((a, b) => (a.regDate > b.regDate ? -1 : 1))
            setDeclarationRecs(declarationRecs)

            const empRecs = await store.getRecs({
                model: 'emp',
                filters: [['id', 'in', userRecs.map(rec => rec.emp)]],
            })
            userRecs.forEach(userRec => {
                const empRec = empRecs.find(rec => rec.id === userRec.emp)
                userRec.fio = `${empRec.surname} ${empRec.forename} ${empRec.patronymic || ''}`
                userRec.birthdate = empRec.birthdate
                const org = store.dict['org'][empRec.allegedOrg]

                if (empRec.sex) {
                    if (empRec.sex === store.dict['d.person.sex']['male'].id) {
                        userRec.sex = 'male'
                    } else {
                        userRec.sex = 'female'
                    }
                }
                if (org) {
                    userRec.allegedOrgName = org.name
                    const founder = founderRecs.find(rec => rec.id === org.founder)

                    if (founder) {
                        userRec.founder = founder.id
                        userRec.founderName = founder.name
                    }
                }
            })
            declarationRecs.sort((a, b) => (a.stateDate > b.stateDate ? -1 : 1))
            const lastRecs = declarationRecs.slice(0, 5)
            const promises = []

            for(let i = 0; i < lastRecs.length; i ++) {
                promises.push(store.getLog (lastRecs[i].id, store.getProperty ("declaration.state").id))
            }
            Promise.all(promises).then(results => {
                results.forEach((updateRecs, i) => {
                    lastRecs[i].login = updateRecs[updateRecs.length - 1].login
                })
                setLastRecs(lastRecs)
            })
            setUserRecs(userRecs)
            setLoading(false)
        }

        load()
    }, [])

    if (loading) {
        return <Loading />
    }
    const empMap = userRecs.reduce((m, rec) => {
        m[rec.emp] = rec
        return m
    }, {})

    return (
        <DashboardContext.Provider
            value={{
                startDate,
                setStartDate,
                endDate,
                setEndDate,
                founderRecs,
                userRecs,
                examRecords,
                founder,
                setFounder,
                declarationRecs,
                empMap,
                lastRecs
            }}
        >
            {children}
        </DashboardContext.Provider>
    )
}
