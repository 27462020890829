/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {useState, useEffect, useCallback} from "react";
import ChatMessages from "./ChatMessages";
import {store} from "../modules/mediator";

export default function Chat () {
	let [refresh, setRefresh] = useState (false);
	const onRecord = useCallback (async (data) => {
		let l = data.created.filter (({model}) => model == store.getModel ("message").id).map (({id}) => id);

		if (l.length) {
			setRefresh (!refresh);
		}
		// eslint-disable-next-line
	}, []);

	useEffect (() => {
		store.addListener ("record", onRecord);
		return () => store.removeListener ("record", onRecord);
		// eslint-disable-next-line
	}, []);

	return <ChatMessages user={store.userId} refresh={refresh} />;
};