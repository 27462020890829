/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {useState, useEffect} from "react";
import {Action, BooleanField, StringField, Loading} from "objectum-react";
import ExamField from "./ExamField";
import {store} from "../modules/mediator";
import PageTitle from "./PageTitle.js";

export default function ExamMove (props) {
    let exam = props.match.params.rid.split ("#")[0]
    let [newExam, setNewExam] = useState (null);
    let [examRecord, setExamRecord] = useState (null);
    let [examRecords, setExamRecords] = useState ([]);
    let [declarationRecords, setDeclarationRecords] = useState ([]);
    let [empRecords, setEmpRecords] = useState ([]);
    let [selected, setSelected] = useState ([]);
    let [sendEmail, setSendEmail] = useState (true);
    let [loading, setLoading] = useState (true);
    let [moveReason, setMoveReason] = useState ('');
    let [selectAll, setSelectAll] = useState (true);

    useEffect (() => {
        async function load () {
            let examRecord = await store.getRecord (exam);
            let examRecords = await store.getRecords ({
                model: "exam",
                filters: [
                    ["type", "=", examRecord.type],
                    ["date", ">=", new Date ().toLocaleDateString ()]
                ]
            });
            let declarationRecords = await examRecord.getDeclarationRecords ();
            let empRecords = await store.getRecords ({
                model: "emp",
                filters: [
                    ["id", "in", declarationRecords.map (record => record.emp)]
                ]
            });
            await store.getDict ("d.exam.type");
            setExamRecord (examRecord);
            setExamRecords (examRecords);
            setDeclarationRecords (declarationRecords);
            setEmpRecords (empRecords);
            setLoading (false);
        }
        load ();
        // eslint-disable-next-line
    }, [])

    if (loading) {
        return <Loading />;
    }
    return <div>
        <PageTitle>Перемещение участников экзамена: {examRecord.getLabel ()}</PageTitle>
        <div className="row">
            <div className="col-6">
                <ExamField label="Выберите дату" onChange={({value}) => setNewExam (value)} records={examRecords} />
                <BooleanField label="Отправить уведомления на почту" value={sendEmail} onClick={({value}) => setSendEmail (value)} />
                <Action className="mt-2" label="Переместить" icon="fas fa-angle-double-right" disabled={!newExam || newExam == exam || !selected.length} onClick={async ({progress}) => {
                    await store.transaction (async () => {
                        let newExamRecord = await store.getRecord (newExam);

                        for (let i = 0; i < selected.length; i ++) {
                            progress ({value: i + 1, max: selected.length});
                            let record = await store.getRecord (selected [i]);
                            record [examRecord.type == store.dict ["d.exam.type"]["ped"].id ? "exam" : "chiefExam"] = newExam;
                            record.moveReason = moveReason
                            await record.sync ();

                            if (sendEmail) {
                                try {
                                    await store.remote ({model: "declaration", id: selected [i], method: "sendEmailNotice", date: newExamRecord.getLabel ()});
                                } catch (err) {
                                }
                            }
                        }
                    });
                    props.history.push ({
                        pathname: "/exam_emp/" + exam
                    });
                }} />
            </div>
            <div className="col-6">
                <h5>Выберите участников</h5>
                <div className="my-3">
                    <Action label="Выбрать всех" onClick={() => {
                        declarationRecords.forEach(record => {
                            if (selectAll) {
                                if (selected.indexOf (record.id) == -1) {
                                    selected.push (record.id)
                                }
                            } else {
                                selected = []
                            }
                            setSelectAll(!selectAll)
                            setSelected ([...selected])
                        })
                    }} />
                </div>
                {declarationRecords.map ((declarationRecord, i) => {
                    let empRecord = empRecords.find (record => record.id == declarationRecord.emp);

                    return <div key={i}>
                        <BooleanField label={`${empRecord.surname} ${empRecord.forename} ${empRecord.patronymic || ""}`} onChange={({value}) => {
                            let idx = selected.indexOf (declarationRecord.id);

                            if (idx > -1) {
                                selected.splice (idx, 1);
                            }
                            if (value) {
                                selected.push (declarationRecord.id);
                            }
                            setSelected ([...selected]);
                        }} value={selected.indexOf (declarationRecord.id) > -1} />
                    </div>
                })}
                <div className="mt-3">
                    <StringField value={moveReason} onChange={({value}) => setMoveReason (value)} textarea placeholder="Укажите причину перемещения" />
                </div>
            </div>
        </div>
    </div>
}
