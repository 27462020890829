/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import { useContext } from 'react'
import DashboardContext from './DashboardContext'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'

export default function DashboardAge() {
    const { userRecs, startDate, endDate, founder, declarationRecs } = useContext(DashboardContext)
    const filteredDeclarationRecs = declarationRecs.filter(rec => rec.date >= startDate && rec.date <= endDate)
    const empHasDeclaration = filteredDeclarationRecs.reduce((m, rec) => {
        m[rec.emp] = true
        return m
    }, {})
    const filteredUserRecs = userRecs.filter(
        rec => (!founder || (founder && rec.founder == founder)) && empHasDeclaration[rec.emp],
    )
    const data = [
        {
            name: '>65',
            m: 0,
            w: 0,
        },
        {
            name: '50-64',
            m: 0,
            w: 0,
        },
        {
            name: '30-49',
            m: 0,
            w: 0,
        },
        {
            name: '18-29',
            m: 0,
            w: 0,
        },
    ]

    filteredUserRecs.forEach(rec => {
        const cd = new Date()

        if (rec.birthdate && rec.sex) {
            let age = cd.getFullYear() - rec.birthdate.getFullYear()

            if (cd.getMonth() > rec.birthdate.getMonth()) {
                age --
            }
            if (cd.getMonth() === rec.birthdate.getMonth() && cd.getDate() > rec.birthdate.getDate()) {
                age --
            }
            if (age < 30) {
                rec.sex === 'male' ? data[3].m ++ : data[3].w ++
            } else
            if (age < 50) {
                rec.sex === 'male' ? data[2].m ++ : data[2].w ++
            } else
            if (age < 65) {
                rec.sex === 'male' ? data[1].m ++ : data[1].w ++
            } else {
                rec.sex === 'male' ? data[0].m ++ : data[0].w ++
            }
        }
    })

    return (
        <div className="p-1 border bg-white rounded">
            <div style={{ height: '16em' }}>
                <h5>Возраст конкурсантов</h5>
                <div style={{ height: '14em' }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            width={500}
                            height={300}
                            data={data}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar name="Женщины" dataKey="w" stackId="a" fill="#FF8272" />
                            <Bar name="Мужчины" dataKey="m" stackId="a" fill="#448AFF" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    )
}
