/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {useReducer, useEffect} from "react";
import {Field, Form, Loading, Tabs, RadioField, Tab, JsonField, StringField, Group} from "objectum-react";
import ExamField from "./ExamField";
import {store} from "../modules/mediator";
import data from "../modules/data";
import { getOrgName, getTerrName, getOtherCitizenshipVisible } from '../common/emp'

function init (props) {
	return {
		loading: true,
		rid: (rid => rid == "new" ? null : rid) (props.id || props.match.params.rid.split ("#")[0]),
		orgRecords: [],
		eduRecords: [],
		trainingRecords: [],
		retrainingRecords: [],
		rewardRecords: [],
		crimeRecords: [],
		languageRecords: [],
		electionRecords: [],
		jobRecords: [],
		expRecords: [],
		examRecords: [],
		stateMessage: ''
	};
}
function reducer (state, action) {
	return Object.assign ({}, state, action);
}
export default function ChiefDeclaration (props) {
	let [state, dispatch] = useReducer (reducer, props, init);

	useEffect (() => {
		async function load () {
			const ts1 = new Date()
			dispatch({loading: true})
			let action = {
				loading: false,
				updateRecs: []
			};
			let [orgRecords, declarationRecord] = await Promise.all([
				store.getRecords ({model: "org"}),
				store.getRecord (state.rid),
				store.getDict ("d.emp.eduActResult"),
				store.getDict ("d.declaration.expertState"),
				store.getDict ("d.declaration.blockState"),
				store.getDict ("objectum.role"),
				store.getDict ("d.declaration.nomination"),
				store.getDict ("d.exam.type")
			])
			action.orgRecords = orgRecords
			action.declarationRecord = declarationRecord

			let emp = action.declarationRecord.emp;
			action.emp = emp;
			action.declarationState = action.declarationRecord.state;
			action.isExpert = [
				store.dict ["objectum.role"]["expert"].id,
				store.dict ["objectum.role"]["manager"].id
			].indexOf (store.roleId) > -1;
			action.isAdmin = store.username == "admin" || ["pedmod", "chiefmod", "admin"].indexOf (store.roleCode) > -1;

			let empRecord = await store.getRecord (emp);
			let [
				chiefData,
				orgName,
				terrName,
				otherCitizenshipVisible,
				empUserRecords,
				userRecords,
				empRecords,
				roleRecords,
				examRecords
			] = await Promise.all([
				empRecord.loadChiefData (),
				getOrgName(empRecord.org),
				getTerrName(empRecord.terr),
				getOtherCitizenshipVisible(empRecord.citizenship),
				store.getRecords ({model: "objectum.user", filters: [["emp", "=", emp]]}),
				store.getRecords ({model: "objectum.user"}),
				store.getRecords ({model: "emp"}),
				store.getRecords ({model: "objectum.role"}),
				store.getRecords ({model: "exam"})
			])
			let {
				eduRecords, trainingRecords, retrainingRecords, rewardRecords, crimeRecords, languageRecords, electionRecords, jobRecords, expRecords
			} = chiefData;

			Object.assign (action, {
				eduRecords, trainingRecords, retrainingRecords, rewardRecords, crimeRecords, languageRecords, electionRecords, jobRecords, expRecords
			});
			action.empRecord = empRecord;
			action.empName = `${empRecord.surname} ${empRecord.forename} ${empRecord.patronymic || ""}`;
			action.noJob = empRecord.noJob;

			if (orgName) {
				action.orgName = orgName
			}
			if (terrName) {
				action.terrName = terrName
			}
			if (otherCitizenshipVisible) {
				action.otherCitizenshipVisible = otherCitizenshipVisible
			}
			if (empUserRecords.length) {
				action.userId = empUserRecords [0].id;
			} else {
				let pedagogRoleRecords = await store.getRecords ({model: "objectum.role", filters: [["code", "=", "pedagog"]]});
				action.roleId = pedagogRoleRecords [0].id;
			}
			let empRecs = [], empMap = {}, roleMap = {};

			roleRecords.forEach (record => roleMap [record.id] = record);
			empRecords.forEach (record => empMap [record.id] = record);

			userRecords.forEach (userRecord => {
				let roleRecord = roleMap [userRecord.role];
				let empRecord = empMap [userRecord.emp];

				if (empRecord && roleRecord && ["expert", "manager"].indexOf (roleRecord.code) > -1) {
					empRecs.push ({
						id: empRecord.id,
						name: `${empRecord.surname} ${empRecord.forename} ${empRecord.patronymic || ""} (${roleRecord.name})`
					});
				}
			});
			action.empRecords = empRecords;
			action.empRecs = empRecs;
			action.examRecords = examRecords;

			store.getLog (state.rid, store.getProperty ("declaration.state").id).then(async (updateRecs) => {
				for (let i = 0; i < updateRecs.length; i ++) {
					let rec = updateRecs [i];

					if (rec.user_id) {
						let record = await store.getRecord (rec.user_id);
						rec.roleName = store.dict ["objectum.role"][record.role].name;
						rec.stateName = store.dict ["d.declaration.state"][rec.value].name;
					} else {
						rec.roleName = "Администратор";
						rec.login = "admin";
						rec.stateName = store.dict ["d.declaration.state"][rec.value].name;
					}
				}
				dispatch ({ updateRecs });
			});
			dispatch (action);
			console.log('chief loading time', (new Date().getTime() - ts1.getTime()) / 1000)
		};
		load ();
	}, [state.rid]);

	if (props.id && props.id != state.rid) {
		dispatch ({rid: props.id});
	}
	function renderEdu (id) {
		return <div key={id} className="border p-1 mb-2">
			<Form store={store} rsc="record" rid={id} hideButtons disabled>
				<div className="row">
					<div className="col-4">
						<Field property="orgStr" label="Учебное заведение" values={data.org} />
					</div>
					<div className="col-4">
						<Field property="qualificationStr" />
					</div>
					<div className="col-4">
						<Field property="endYear" />
					</div>
				</div>
				<div className="row">
					<div className="col-4">
						<Field property="faculty" />
					</div>
					<div className="col-4">
						<Field property="direction" />
					</div>
					<div className="col-4">
						<Field property="file" notNull description="Прикрепите цветной скан, в формате pdf, не более 8 мб" maxSize={8 * 1024 * 1024} accept=".pdf" />
					</div>
				</div>
			</Form>
		</div>;
	}
	function renderRetraining (id) {
		return <div key={id} className="border p-1 mb-2">
			<Form store={store} rsc="record" rid={id} hideButtons disabled>
				<div className="row">
					<div className="col-8">
						<Field property="name" />
					</div>
					<div className="col-4">
						<Field property="hours" />
					</div>
				</div>
				<div className="row">
					<div className="col-4">
						<Field property="startDate" />
					</div>
					<div className="col-4">
						<Field property="endDate" />
					</div>
					<div className="col-4">
						<Field property="place" />
					</div>
				</div>
				<div className="row">
					<div className="col-4">
						<Field property="num" />
					</div>
					<div className="col-4">
						<Field property="regNum" />
					</div>
					<div className="col-4">
						<Field property="file" />
					</div>
				</div>
			</Form>
		</div>;
	}
	function renderReward (id) {
		return <div key={id} className="border p-1 mb-2">
			<Form store={store} rsc="record" rid={id} hideButtons disabled>
				<div className="row">
					<div className="col-3">
						<Field property="level" />
					</div>
					<div className="col-9">
						<Field property="reward" />
					</div>
				</div>
				<div className="row">
					<div className="col-6">
						<Field property="url" notNull />
					</div>
					<div className="col-6">
						<Field property="file" notNull description="Прикрепите цветной скан, в формате pdf, не более 8 мб" maxSize={8 * 1024 * 1024} accept=".pdf" />
					</div>
				</div>
			</Form>
		</div>;
	}
	function renderTraining (id) {
		return <div key={id} className="border p-1 mb-2">
			<Form store={store} rsc="record" rid={id} hideButtons disabled>
				<div className="row">
					<div className="col-8">
						<Field property="name" />
					</div>
					<div className="col-2">
						<Field property="startDate" min={new Date ().getFullYear () - 3} />
					</div>
					<div className="col-2">
						<Field property="endDate" min={new Date ().getFullYear () - 3} />
					</div>
				</div>
				<div className="row">
					<div className="col-4">
						<Field property="docNum" />
					</div>
					<div className="col-4">
						<Field property="regNum" />
					</div>
					<div className="col-4">
						<Field property="hours" />
					</div>
				</div>
				<div className="row">
					<div className="col-6">
						<Field property="place" />
					</div>
					<div className="col-6">
						<Field property="file" notNull description="Прикрепите цветной скан, в формате pdf, не более 8 мб" maxSize={8 * 1024 * 1024} accept=".pdf" />
					</div>
				</div>

			</Form>
		</div>;
	}
	function renderCrime (id) {
		return <div key={id} className="border p-1 mb-2">
			<Form store={store} rsc="record" rid={id} hideButtons disabled>
				<div className="row">
					<div className="col-6">
						<Field property="docType" />
					</div>
					<div className="col-6">
						<Field property="file" />
					</div>
				</div>
				<Field property="comment" />
			</Form>
		</div>;
	}
	function renderLanguage (id) {
		return <div key={id} className="border p-1 mb-2">
			<Form store={store} rsc="record" rid={id} hideButtons disabled>
				<div className="row">
					<div className="col-6">
						<Field property="language" dict />
					</div>
					<div className="col-6">
						<Field property="languageLevel" dict />
					</div>
				</div>
			</Form>
		</div>;
	}
	function renderElection (id) {
		return <div key={id} className="border p-1 mb-2">
			<Form store={store} rsc="record" rid={id} hideButtons disabled>
				<div className="row">
					<div className="col-6">
						<Field property="docType" />
					</div>
					<div className="col-6">
						<Field property="file" />
					</div>
				</div>
			</Form>
		</div>;
	}
	function renderJob (id) {
		return <div key={id} className="border p-1 mb-2">
			<Form store={store} rsc="record" rid={id} hideButtons disabled>
				<div className="row">
					<div className="col-4">
						<Field property="place" />
					</div>
					<div className="col-4">
						<Field property="postStr" />
					</div>
					<div className="col-4">
						<Field property="reason" />
					</div>
				</div>
				<div className="row">
					<div className="col-4">
						<Field property="startDate" />
					</div>
					<div className="col-4">
						<Field property="endDate" />
					</div>
					<div className="col-4">
						<Field property="untilNow" />
					</div>
				</div>
			</Form>
		</div>;
	}
/*
	function renderExp (id) {
		return <div key={id} className="border p-1 mb-2">
			<Form store={store} rsc="record" rid={id} hideButtons disabled>
				<div className="row">
					<div className="col-4">
						<Field property="place" />
					</div>
					<div className="col-4">
						<Field property="post" />
					</div>
					<div className="col-4">
					</div>
				</div>
				<div className="row">
					<div className="col-4">
						<Field property="startDate" />
					</div>
					<div className="col-4">
						<Field property="endDate" />
					</div>
					<div className="col-4">
					</div>
				</div>
			</Form>
		</div>;
	}
*/
	if (state.loading) {
		return <div className="p-3">
			<Loading />
		</div>
	}
	let stateRecords = store.roleCode == "terrmod" ?
		store.map.dict ["d.declaration.state"].filter (record => record.code == "match" || record.code == "noMatch") :
		store.map.dict ["d.declaration.state"]
	let stateMap = {
		"await": 1,
		"editing": 2,
		"match": 3,
		"noMatch": 4,
		"commission": 5,
		"archive": 6,
		"chief": 7,
		"certificationFailed": 8,
		"reserve": 9
	}

	stateRecords = stateRecords.filter (record => Object.keys(stateMap).includes (record.code));
	stateRecords.sort((a, b) => stateMap[a.code] > stateMap[b.code] ? 1 : -1)

	if (store.roleCode == "terrmod" || store.roleCode == "chiefmod") {
		stateRecords = stateRecords.filter (record => !["processing", "rejected"].includes (record.code));
	}
	return <div className="p-1">
{/*
		{state.empName && <table className="table table-sm bg-empinfo">
			<thead>
			<tr>
				<th>Ф.И.О.</th><th>Текущее место работы</th><th>Территория</th>
			</tr>
			</thead>
			<tbody>
			<tr>
				<td>{state.empName}</td>
				<td>{state.orgName}</td>
				<td>{state.terrName}</td>
			</tr>
			</tbody>
		</table>}
*/}
		<Tabs>
			<Tab label="Общие сведения"><div className="p-2">
				<Form store={store} rsc="record" rid={state.emp} hideButtons disabled>
					<div className="row">
						<div className="col-4">
							<Field property="surname" />
						</div>
						<div className="col-4">
							<Field property="forename" />
						</div>
						<div className="col-4">
							<Field property="patronymic" />
						</div>
					</div>
					<div className="row">
						<div className="col-4">
							<Field property="sex" />
						</div>
						<div className="col-4">
							<Field property="citizenship" dict />
						</div>
						<div className="col-4">
							{state.otherCitizenshipVisible ? <Field property="otherCitizenship" /> : null}
						</div>
					</div>
					<div className="row">
						<div className="col-4">
							<Field property="birthdate" />
						</div>
						<div className="col-4">
							<Field property="birthplace" />
						</div>
						<div className="col-4">
							<Field property="phone" />
						</div>
					</div>
					<Field property="address" label="Адрес проживания" />
					<Field property="regAddress" />
					<div className="row">
						<div className="col-6">
							<Field property="allegedOrg" />
						</div>
						<div className="col-6">
							<Field property="allegedPost" />
						</div>
					</div>
				</Form>
				<div className="border rounded">
					<Form store={store} rsc="record" rid={state.emp} mid="emp" hideLogButton disabled={store.roleCode == "observer"} hideButtons={store.roleCode == "observer"}>
						<Field property="docsFile" label="Заявление в аттестационную комиссию" />
					</Form>
				</div>
			</div></Tab>
			<Tab label="Профессиональная деятельность"><div className="p-1">
				{state.eduRecords.length ? <div className="mb-1">
					<div className="bg-empinfo p-2 mb-1">
						<h6 className="m-0">Образование</h6>
					</div>
					{state.eduRecords.map (record => renderEdu (record.id))}
				</div> : <div />}
				<Form store={store} rsc="record" rid={state.emp} hideButtons disabled>
					{(state.empRecord.scienceDegree || state.empRecord.scienceRank) ? <div>
						<h6>Научная деятельность</h6>
							{state.empRecord.scienceDegree ? <div className="border my-2 p-2">
							<div className="row">
								<div className="col-4">
									<Field property="scienceDegree" dict />
								</div>
								<div className="col-4">
									<Field property="degreeDate" />
								</div>
								<div className="col-4">
									<Field property="degreeCity" />
								</div>
							</div>
							<div className="row">
								<div className="col-4">
									<Field property="degreeRegNum" />
								</div>
								<div className="col-4">
									<Field property="degreeTheme" />
								</div>
								<div className="col-4">
									<Field property="degreeFile" />
								</div>
							</div>
						</div> : null}
							{state.empRecord.scienceRank ? <div className="border my-2 p-2">
							<div className="row">
								<div className="col-4">
									<Field property="scienceRank" dict />
								</div>
								<div className="col-4">
									<Field property="rankRegNum" />
								</div>
								<div className="col-4">
									<Field property="rankDate" />
								</div>
							</div>
							<div className="row">
								<div className="col-4">
									<Field property="rankTheme" />
								</div>
								<div className="col-4" />
								<div className="col-4">
									<Field property="rankFile" />
								</div>
							</div>
						</div> : null}
						<div className="border my-2 p-2">
							<JsonField property="articleUrl" label="Статьи или исследования" multi col props={[
								{prop: "article", label: "Название", col: 6, component: StringField},
								{prop: "url", label: "Ссылка", col: 6, component: StringField}
							]} />
						</div>
					</div> : null}
				</Form>
				{state.trainingRecords.length ? <div>
					<div className="bg-empinfo p-2 mb-1">
						<h6 className="m-0">Повышение квалификации</h6>
					</div>
					{state.trainingRecords.map (record => renderTraining (record.id))}
				</div> : <div />}
				{state.retrainingRecords.length ? <div className="mb-2">
					<div className="bg-empinfo p-2 mb-1">
						<h6 className="m-0">Переподготовка</h6>
					</div>
					{state.retrainingRecords.map (record => renderRetraining (record.id))}
				</div> : <div />}
				{state.rewardRecords.length ? <div>
					<div className="bg-empinfo p-2 mb-1">
						<h6 className="m-0">Награды</h6>
					</div>
					{state.rewardRecords.map (record => renderReward (record.id))}
				</div> : <div />}
				{state.crimeRecords.length ? <div>
					<div className="bg-empinfo p-2 mb-1">
						<h6 className="m-0">Сведения о дисциплинарной, материальной, гражданско-правовой, административной и уголовной ответственности</h6>
					</div>
					<Form store={store} rsc="record" rid={state.emp} mid="emp" hideButtons disabled>
						<Field property="noCrime" />
					</Form>
					{state.empRecord.noCrime ? null : state.crimeRecords.map (record => renderCrime (record.id))}
				</div> : <div />}
				{state.languageRecords.length ? <div>
					<div className="bg-empinfo p-2 mb-1">
						<h6 className="m-0">Владение иностранными языками</h6>
					</div>
					{state.languageRecords.map (record => renderLanguage (record.id))}
				</div> : <div />}
				{state.electionRecords.length ? <div>
					<div className="bg-empinfo p-2 mb-1">
						<h6 className="m-0">Сведения об участии в выборных органах государственной власти, муниципального управления</h6>
					</div>
					{state.electionRecords.map (record => renderElection (record.id))}
				</div> : <div />}
				{state.jobRecords.length ? <div>
					<div className="bg-empinfo p-2 mb-1">
						<h6 className="m-0">Сведения о работе</h6>
					</div>
					{state.jobRecords.map (record => renderJob (record.id))}
				</div> : <div />}
{/*
				{state.expRecords.length ? <div>
					<div className="bg-empinfo p-2 mb-1">
						<h6 className="m-0">Сведения о стаже и характере управленческой, научно-педагогической и иной деятельности</h6>
					</div>
					{state.expRecords.map (record => renderExp (record.id))}
				</div> : <div />}
*/}
				<Form store={store} rsc="record" rid={state.emp} mid="emp" hideButtons disabled>
					<div className="border mb-2 p-2 bg-empinfo">
						<h6>Текущее место работы</h6>
{/*
						{state.noJob ? null : <div className="row">
							<div className="col-4">
								<Field property="terr" dict onChange={({value}) => dispatch ({terr: value})} />
							</div>
							<div className="col-4">
								<Field property={state.empRecord.postStr ? "postStr" : "post"} dict />
							</div>
							<div className="col-4">
								<Field property={state.empRecord.orgStr ? "orgStr" : "org"} dict records={state.terr ? state.orgRecords.filter (record => record.terr == state.terr) : state.orgRecords} />
							</div>
						</div>}
*/}
						<div className="row">
							<div className="col-4">
								<Field property="pedExp" />
							</div>
							<div className="col-4">
								<Field property="noJob" />
							</div>
						</div>
					</div>
					<div className="border my-2 p-2 bg-empinfo">
						<Field property="jobFile" accept=".pdf" />
					</div>
					<div className="border my-2 p-2 bg-empinfo">
						<div className="row">
							<div className="col-6">
								<RadioField property="nomination" records={store.map.dict ["d.declaration.nomination"]} disabled={!!state.hasDeclaration} />
							</div>
							<div className="col-6">
								<Field property="nominationFile" label="Файл" />
							</div>
						</div>
					</div>
					<div className="border my-2 p-2 bg-empinfo">
						<Field property="confirmFile" />
						<Field property="drugsFile" />
						<Field property="noCrimeFile" />
						<Field property="programFile" />
					</div>
				</Form>
			</div></Tab>
			<Tab label={state.isAdmin ? "Экспертное заключение" : "Заявление"}><div className="p-2" style={{height: "120em"}}>
				<Form store={store} rsc="record" rid={state.rid} hideLogButton={!state.isAdmin} onSave={async () => {
/*
					if (state.stateDate) {
						await store.remote ({model: "declaration", id: state.declarationRecord.id, method: "sendStateNotice"});
					}
*/
					if (state.stateDate || state.chiefExam) {
						let exam;

						if (state.chiefExam) {
							let record = await store.getRecord (state.chiefExam);
							exam = record.date.toLocaleString ().substr (0, 17);
						}
						await store.remote ({
							model: "declaration",
							id: state.declarationRecord.id,
							method: "sendNotice",
							state: state.stateDate ? state.declarationState : null,
							exam
						});
					}
					if (store.dict ["d.declaration.state"]["noMatch"].id == state.declarationRecord.state) {
						if (state.declarationRecord.archivePlace != 2) {
							await store.startTransaction ();
							state.declarationRecord.archivePlace = 2
							await state.declarationRecord.sync ()
							await store.commitTransaction ();
							dispatch({ stateMessage: 'Перемещено в "Архив КТ"' })
						}
					} else if (store.dict ["d.declaration.state"]["archive"].id == state.declarationRecord.state) {
						if (state.declarationRecord.archivePlace != 1) {
							await store.startTransaction ();
							state.declarationRecord.archivePlace = 1
							await state.declarationRecord.sync ()
							await store.commitTransaction ();
							dispatch({ stateMessage: 'Перемещено в "Архив"' })
						}
					} else {
						if (state.declarationRecord.archivePlace != null) {
							await store.startTransaction ();
							state.declarationRecord.archivePlace = null
							await state.declarationRecord.sync ()
							await store.commitTransaction ();
							dispatch({ stateMessage: 'Возвращено из архива' })
						}
					}
				}} values={{
					state: state.declarationState,
					stateDate: state.stateDate
				}} onChange={({property, value}) => {
					if (property == "chiefExam") {
						dispatch ({chiefExam: value});
					}
				}} disabled={store.roleCode == "observer"} hideButtons={store.roleCode == "observer"}>
					<div className="row">
						<div className="col-4">
							<Field property="date" label={"Дата подачи заявления"} showTime disabled />
						</div>
					</div>
					<Group label={"Статус рассмотрения заявления"}>
						<div className="row">
							<div className="col-4">
								<Field property="state"
									onChange={({value}) => {
										let action = {declarationState: value, stateDate: new Date ()};

	/*
										if (state.declarationRecord.state != value) {
											action.stateDate = new Date ();
										} else {
											action.stateDate = null;
										}
	*/
										dispatch (action);
									}}
									records={stateRecords}
									disabled={store.roleCode === "terrmod" && state.declarationRecord.state != store.dict["d.declaration.state"]["await"].id}
								/>
								{state.stateMessage && <div className="alert alert-info my-1">{state.stateMessage}</div>}
								{state.updateRecs.length ? <table className="table table-sm table-bordered">
									{state.updateRecs.map ((rec, i) => {
										return <tr key={i}>
											<td>{rec.date.toLocaleString ()}</td>
											<td>{rec.stateName}</td>
											<td>{rec.login}</td>
											<td>{rec.roleName}</td>
										</tr>;
									})}
								</table> : null}
							</div>
							<div className="col-3">
								<Field property="stateDate" showTime disabled />
							</div>
							<div className="col">
								<Field property="comment" />
							</div>
						</div>
					</Group>
					<Group label={"Результаты диагностики управленческих компетенций"}>
						<div className="row">
							<div className="col-7">
								{state.isAdmin ?
									<ExamField property="chiefExam" label="Дата сдачи" records={state.examRecords.filter (record => record.type == store.dict ["d.exam.type"]["chief"].id)} /> :
									<Field property="chiefExam" label="Дата сдачи" disabled dict records={state.examRecords} />
								}
							</div>
							<div className="col">
								<Field property="examScore" label={"Количество баллов"} disabled={!state.isAdmin} />
							</div>
						</div>
						<Field property="examFile" disabled={!state.isAdmin} />
					</Group>
					<div className="border rounded mt-1 p-1">
						<Field property="chiefResult" disabled={!state.isAdmin} onChange={({value}) => {
							if (value == store.dict ["d.declaration.chiefResult"]["rejected"].id) {
								dispatch ({declarationState: store.dict ["d.declaration.state"]["archive"].id});
							}
						}} />
						<Field property="chiefResultFile" disabled={!state.isAdmin} />
					</div>
				</Form>
			</div></Tab>
		</Tabs>
	</div>;
};
