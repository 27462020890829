/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import { useContext } from 'react'
import DashboardContext from './DashboardContext'
import { Link } from 'react-router-dom'

export default function DashboardNewUsers() {
    const { userRecs } = useContext(DashboardContext)
    const newRecs = userRecs.slice(0, 5)

    return (
        <div className="border p-1 mt-1 bg-white rounded">
            <h5 className="mr-5">Новые пользователи</h5>
            <table className="table table-bordered table-sm">
                <thead>
                    <tr>
                        <th rowSpan={2}>Ф.И.О.</th>
                        <th colSpan={2}>Предполагаемая организация</th>
                        <th rowSpan={2}>Дата регистрации</th>
                    </tr>
                    <tr>
                        <th>Наименование</th>
                        <th>Учредитель</th>
                    </tr>
                </thead>
                <tbody>
                    {newRecs.map(rec => {
                        return (
                            <tr key={rec.id}>
                                <td>{rec.fio}</td>
                                <td>{rec.allegedOrgName || ''}</td>
                                <td>{rec.founderName || ''}</td>
                                <td>{rec.regDate ? rec.regDate.toLocaleString() : ''}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <div className="text-center">
                <Link to="/sapr_users" style={{color: 'red'}}><u>Все пользователи</u></Link>
            </div>
        </div>
    )
}
