import {Fade} from "objectum-react";

export default function Footer () {
	return <div className="bg-white text-primary py-1 border-top border-primary fixed-bottom"><Fade>
		<div className="container">
			<div className="d-flex justify-content-between">
				<div>2024 © ИАС "Аверс: Система аттестации педагогических работников" (версия {process.env.REACT_APP_VERSION})</div>
				<div>Электронная почта: <a className="text-primary font-weight-bold ml-2" href="helpsapr@cokord.ru">helpsapr@cokord.ru</a></div>
			</div>
		</div>
	</Fade></div>;
};
