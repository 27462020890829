/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

// не используется

import {useReducer, useEffect} from "react";
import {Field, Action, Form, Loading, Tabs, Tab, JsonField, StringField, Grid, DictField} from "objectum-react";
import Unit from "./Unit";
import mediator, {store} from "../modules/mediator";

let tables = [
	{code: "edu", name: "Образование"},
	{code: "training", name: "Повышение квалификации"},
	{code: "retraining", name: "Переподготовка"},
	{code: "reward", name: "Награда"},
	{code: "eduActResult", name: "Результаты образовательной деятельности"},
	{code: "personalContribution", name: "Личный вклад и достижения"}
];
function init (props) {
	return {
		loading: true,
		rid: (rid => rid == "new" ? null : rid) (props.id || props.match.params.rid.split ("#")[0]),
		orgRecords: [],
		unitRecords: [],
		eduRecords: [],
		trainingRecords: [],
		retrainingRecords: [],
		rewardRecords: [],
		eduActResultRecords: [],
		personalContributionRecords: [],
		empRecs: [],
		examRecords: [],
		isExpert: false
	};
}
function reducer (state, action) {
	return Object.assign ({}, state, action);
}
function Colorer ({id, table, state, children}) {
	let cls = "";
	let unitState = state [`unitState-${id}`];

	if (state.isExpert) {
		if (unitState == store.dict ["d.declaration.blockState"]["accepted"].id) {
			cls = "bg-success";
		}
		if (unitState == store.dict ["d.declaration.blockState"]["rejected"].id) {
			cls = "bg-danger";
		}
	}
	if (state.isAdmin) {
		let n = 0;
		let records = state.unitRecords.filter (record => record [table] == id);

		records.forEach (record => {
			if (record.state == store.dict ["d.declaration.blockState"]["accepted"].id) {
				n ++;
			}
			if (record.state == store.dict ["d.declaration.blockState"]["rejected"].id) {
				n --;
			}
		});
		if (n && n == records.length) {
			cls = "bg-success";
		}
		if (n && n < records.length) {
			cls = "bg-danger";
		}
	}
	return <div className={cls}>
		{children}
	</div>;
}
function UrlField (props) {
	return <div className={props.label ? "form-group stringfield" : "stringfield"}>
		{props.label && <label>{props.label}</label>}
		<div>{props.value ? <a href={props.value} className="sapr-url"><u>{props.value}</u></a> : ""}</div>
	</div>
}
export default function Declaration (props) {
	let [state, dispatch] = useReducer (reducer, props, init);

	useEffect (() => {
		async function load () {
			let action = {
				loading: false,
				orgRecords: await store.getRecords ({model: "org"}),
				declarationRecord: await store.getRecord (state.rid),
				unitRecords: await store.getRecords ({model: "t.declaration.unit", filters: [["declaration", "=", state.rid]]})
			};
			let emp = action.declarationRecord.emp;
			action.emp = emp;
			await Promise.all ([
				store.getDict ("d.emp.eduActResult"),
				store.getDict ("d.declaration.expertState"),
				store.getDict ("d.declaration.blockState"),
				store.getDict ("objectum.role")
			]);
			action.isExpert = [
				store.dict ["objectum.role"]["expert"].id,
				store.dict ["objectum.role"]["manager"].id
			].indexOf (store.roleId) > -1;
			action.isAdmin = store.dict ["objectum.role"]["admin"].id == store.roleId || store.username == "admin";

			let [eduRecords, trainingRecords, retrainingRecords, rewardRecords, eduActResultRecords, personalContributionRecords] = await Promise.all ([
				store.getRecords ({model: "t.emp.edu", filters: [["emp", "=", emp]]}),
				store.getRecords ({model: "t.emp.training", filters: [["emp", "=", emp]]}),
				store.getRecords ({model: "t.emp.retraining", filters: [["emp", "=", emp]]}),
				store.getRecords ({model: "t.emp.reward", filters: [["emp", "=", emp]]}),
				store.getRecords ({model: "t.emp.eduActResult", filters: [["emp", "=", emp]]}),
				store.getRecords ({model: "t.emp.personalContribution", filters: [["emp", "=", emp]]})
			]);
			Object.assign (action, {
				eduRecords, trainingRecords, retrainingRecords, rewardRecords, eduActResultRecords, personalContributionRecords,
			});
			let empRecord = await store.getRecord (emp);
			action.empName = `${empRecord.surname} ${empRecord.forename} ${empRecord.patronymic || ""}`;
			if (empRecord.org) {
				let orgRecord = await store.getRecord (empRecord.org);
				action.orgName = orgRecord.name;
			}
			if (empRecord.terr) {
				let terrRecord = await store.getRecord (empRecord.terr);
				action.terrName = terrRecord.name;
			}
			if (empRecord.citizenship) {
				let record = await store.getRecord (empRecord.citizenship);
				if (record.code == "other") {
					action.otherCitizenshipVisible = true;
				}
			}
			let userRecords = await store.getRecords ({model: "objectum.user", filters: [["emp", "=", emp]]});
			if (userRecords.length) {
				action.userId = userRecords [0].id;
			} else {
				let roleRecords = await store.getRecords ({model: "objectum.role", filters: [["code", "=", "pedagog"]]});
				action.roleId = roleRecords [0].id;
			}
			action.eduActResultRecords.forEach (record => {
				if (record.eduActResult) {
					action [`urlLabel-${record.id}`] = getUrlName (record.eduActResult);
				}
			});
			userRecords = await store.getRecords ({model: "objectum.user"});
			let empRecords = await store.getRecords ({model: "emp"});
			let roleRecords = await store.getRecords ({model: "objectum.role"});
			let empRecs = [];

			userRecords.forEach (userRecord => {
				let roleRecord = roleRecords.find (record => record.id == userRecord.role);
				let empRecord = empRecords.find (record => record.id == userRecord.emp);

				if (empRecord && roleRecord && ["expert", "manager"].indexOf (roleRecord.code) > -1) {
					empRecs.push ({
						id: empRecord.id,
						name: `${empRecord.surname} ${empRecord.forename} ${empRecord.patronymic || ""} (${roleRecord.name})`
					});
				}
			});
			action.empRecords = empRecords;
			action.empRecs = empRecs;

			if (action.isExpert) {
				action.declarationEmpRecord = (await store.getRecords ({
					model: "t.declaration.emp",
					filters: [
						["declaration", "=", state.rid],
						["emp", "=", mediator.record.user.emp]
					]
				})) [0];
				action.unitRecords = await store.getRecords ({
					model: "t.declaration.unit",
					filters: [
						["declaration", "=", state.rid],
						["emp", "=", mediator.record.user.emp]
					]
				});
				action.unitRecords.forEach (record => {
					["edu", "retraining", "training", "reward", "eduActResult", "personalContribution"].forEach (table => action [`unitState-${record [table]}`] = record.state);
				});
			}
			if (action.isAdmin) {
				action.unitRecords = await store.getRecords ({
					model: "t.declaration.unit",
					filters: [
						["declaration", "=", state.rid]
					]
				});
			}
			action.examRecords = await store.getRecords ({
				model: "exam",
				filters: [
					["date", ">", new Date ().toLocaleDateString ()]
				]
			});
			dispatch (action);
		};
		load ();
	}, [state.rid]);

	if (props.id && props.id != state.rid) {
		dispatch ({rid: props.id});
	}
	function renderEdu (id) {
		return <div key={id} className="unit-content">
			<div className="d-flex justify-content-end">
				<div className="unit-overlay">
					<Unit table="edu" id={id} declaration={state.rid} state={state} dispatch={dispatch} />
				</div>
			</div>
			<Colorer id={id} table="edu" state={state}><div className="unit-main">
				<div className="border p-1 mb-2">
					<Form store={store} rsc="record" rid={id} hideButtons disabled>
						<div className="row">
							<div className="col-6">
								<Field property="orgStr" />
							</div>
							<div className="col-3">
								<Field property="startYear" />
							</div>
							<div className="col-3">
								<Field property="endYear" />
							</div>
						</div>
						<div className="row">
							<div className="col-6">
								<Field property="qualificationStr" />
							</div>
							<div className="col-6">
								<Field property="file" />
							</div>
						</div>
					</Form>
				</div>
			</div></Colorer>
		</div>;
	}
	function renderRetraining (id) {
		return <div key={id} className="unit-content">
			<div className="d-flex justify-content-end">
				<div className="unit-overlay">
					<Unit table="retraining" id={id} declaration={state.rid} state={state} dispatch={dispatch} />
				</div>
			</div>
			<Colorer id={id} table="retraining" state={state}><div className="unit-main">
				<div className="border p-1 mb-2">
					<Form store={store} rsc="record" rid={id} hideButtons disabled>
						<Field property="name" />
						<div className="row">
							<div className="col-4">
								<Field property="date" />
							</div>
							<div className="col-4">
								<Field property="hours" />
							</div>
						</div>
					</Form>
				</div>
			</div></Colorer>
		</div>;
	}
	function renderReward (id) {
		return <div key={id} className="unit-content">
			<div className="d-flex justify-content-end">
				<div className="unit-overlay">
					<Unit table="reward" id={id} declaration={state.rid} state={state} dispatch={dispatch} />
				</div>
			</div>
			<Colorer id={id} table="reward" state={state}><div className="unit-main">
				<div className="border p-1 mb-2">
					<Form store={store} rsc="record" rid={id} hideButtons disabled>
						<div className="row">
							<div className="col-9">
								<Field property="reward" dict />
							</div>
							<div className="col-3">
								<Field property="level" dict />
							</div>
						</div>
						<div className="row">
							<div className="col-6">
								<UrlField property="url" />
							</div>
							<div className="col-6">
								<Field property="file" />
							</div>
						</div>
					</Form>
				</div>
			</div></Colorer>
		</div>;
	}
	function renderTraining (id) {
		return <div key={id} className="unit-content">
			<div className="d-flex justify-content-end">
				<div className="unit-overlay">
					<Unit table="training" id={id} declaration={state.rid} state={state} dispatch={dispatch} />
				</div>
			</div>
			<Colorer id={id} table="training" state={state}><div className="unit-main">
				<div className="border p-1 mb-2">
					<Form store={store} rsc="record" rid={id} hideButtons disabled>
						<div className="row">
							<div className="col-8">
								<Field property="name" />
							</div>
							<div className="col-2">
								<Field property="startDate" />
							</div>
							<div className="col-2">
								<Field property="endDate" />
							</div>
						</div>
						<div className="row">
							<div className="col-4">
								<Field property="docNum" />
							</div>
							<div className="col-4">
								<Field property="regNum" />
							</div>
							<div className="col-4">
								<Field property="hours" />
							</div>
						</div>
						<div className="row">
							<div className="col-6">
								<Field property="place" />
							</div>
							<div className="col-6">
								<Field property="file" />
							</div>
						</div>
					</Form>
				</div>
			</div></Colorer>
		</div>;
	}
	function getUrlName (id) {
		let record = store.map ["record"][id];

		if (record && record.code == "sysMon") {
			return "Ссылка на показатели";
		}
		if (record && record.code == "act") {
			return "Ссылка на приказ о награждении";
		}
		return "Ссылка";
	}

	function renderEduActResult (id) {
		return <div key={id} className="unit-content">
			<div className="d-flex justify-content-end">
				<div className="unit-overlay">
					<Unit table="eduActResult" id={id} declaration={state.rid} state={state} dispatch={dispatch} />
				</div>
			</div>
			<Colorer id={id} table="eduActResult" state={state}><div className="unit-main">
				<div className="border p-1 mb-2">
					<Form store={store} rsc="record" rid={id} hideButtons disabled>
						<Field property="eduActResult" dict />
						<div className="row">
							<div className="col-6">
								<Field property="type" dict />
							</div>
							<div className="col-6">
								<Field property="level" dict />
							</div>
						</div>
						<div className="row">
							<div className="col-6">
								<JsonField property="url" label={state [`urlLabel-${id}`] || "Ссылка"} multi props={[
									{prop: "url", component: UrlField}
								]} />
							</div>
							<div className="col-6">
								<Field property="file" />
							</div>
						</div>
					</Form>
				</div>
			</div></Colorer>
		</div>;
	}
	function renderPersonalContribution (id) {
		return <div key={id} className="unit-content">
			<div className="d-flex justify-content-end">
				<div className="unit-overlay">
					<Unit table="personalContribution" id={id} declaration={state.rid} state={state} dispatch={dispatch} />
				</div>
			</div>
			<Colorer id={id} table="personalContribution" state={state}><div className="unit-main">
				<div className="border p-1 mb-2">
					<Form store={store} rsc="record" rid={id} hideButtons disabled>
						<Field property="personalContribution" dict />
						<div className="row">
							<div className="col-6">
								<Field property="level" dict />
							</div>
						</div>
						<div className="row">
							<div className="col-6">
								<UrlField property="url" />
							</div>
							<div className="col-6">
								<Field property="file" />
							</div>
						</div>
					</Form>
				</div>
			</div></Colorer>
		</div>;
	}
	async function onCreateEmp () {
		let records = await store.getRecords ({
			model: "t.declaration.emp",
			filters: [
				["declaration", "=", state.rid],
				["emp", "=", state.expert]
			]
		});
		if (records.length) {
			throw new Error ("Эксперт в списке");
		}
		await store.startTransaction ();

		try {
			await store.createRecord ({
				_model: "t.declaration.emp",
				declaration: state.rid,
				emp: state.expert,
				startDate: new Date ()
			});
			await store.commitTransaction ();
			dispatch ({refresh: !state.refresh});
		} catch (err) {
			await store.rollbackTransaction ();
			throw err;
		}
	}
	async function onRemoveEmp ({id}) {
		try {
			await store.startTransaction ();
			await store.removeRecord (id);
			await store.commitTransaction ();
		} catch (err) {
			await store.rollbackTransaction ();
			throw err;
		}
		dispatch ({refresh: !state.refresh});
	}
	async function onCancelExpert ({id}) {
		let record = await store.getRecord (id);
		record.state = null;
		record.endDate = null;
		await record.sync ();
		dispatch ({refresh: !state.refresh});
	}
	async function checkUnits () {
		let unitRecords = await store.getRecords ({
			model: `t.declaration.unit`,
			filters: [
				["declaration", "=", state.rid],
				["emp", "=", mediator.record.user.emp]
			]
		});
		for (let i = 0; i < tables.length; i ++) {
			let table = tables [i];
			let records = state [`${table.code}Records`];

			for (let j = 0; j < records.length; j ++) {
				let unitRecord = unitRecords.find (unitRecord => unitRecord [table.code] == records [j].id);

				if (!unitRecord || !unitRecord.state) {
					throw new Error (`Остались нерассмотренные блоки: ${table.name}`);
				}
			}
		}
	}
	async function onAccept ({confirm}) {
		await checkUnits ();

		if (await confirm ()) {
			try {
				await store.startTransaction ();
				state.declarationEmpRecord.comment = state.comment;
				state.declarationEmpRecord.state = store.dict ["d.declaration.expertState"]["accepted"].id;
				state.declarationEmpRecord.endDate = new Date ();
				await state.declarationEmpRecord.sync ();
				await store.commitTransaction ();
				dispatch ({refresh: !state.refresh});
			} catch (err) {
				await store.rollbackTransaction ();
				throw err;
			}
		}
	}
	async function onReject ({confirm}) {
		await checkUnits ();

		if (await confirm ()) {
			try {
				await store.startTransaction ();
				state.declarationEmpRecord.comment = state.comment;
				state.declarationEmpRecord.state = store.dict ["d.declaration.expertState"]["rejected"].id;
				await state.declarationEmpRecord.sync ();
				state.declarationEmpRecord.endDate = new Date ();
				await store.commitTransaction ();
				dispatch ({refresh: !state.refresh});
			} catch (err) {
				await store.rollbackTransaction ();
				throw err;
			}
		}
	}
	if (state.loading) {
		return <Loading />;
	}
	return <div>
		{state.empName && <table className="table table-sm bg-empinfo">
			<thead>
			<tr>
				<th>Ф.И.О.</th><th>Текущее место работы</th><th>Территория</th>
			</tr>
			</thead>
			<tbody>
			<tr>
				<td>{state.empName}</td>
				<td>{state.orgName}</td>
				<td>{state.terrName}</td>
			</tr>
			</tbody>
		</table>}
		<Tabs>
			<Tab label="Общие сведения"><div className="p-2">
				<Form store={store} rsc="record" rid={state.emp} hideButtons disabled>
					<div className="row">
						<div className="col-4">
							<Field property="surname" />
						</div>
						<div className="col-4">
							<Field property="forename" />
						</div>
						<div className="col-4">
							<Field property="patronymic" />
						</div>
					</div>
					<div className="row">
						<div className="col-4">
							<Field property="sex" />
						</div>
						<div className="col-4">
							<Field property="citizenship" dict />
						</div>
						<div className="col-4">
							{state.otherCitizenshipVisible ? <Field property="otherCitizenship" /> : null}
						</div>
					</div>
					<div className="row">
						<div className="col-4">
							<Field property="birthdate" />
						</div>
						<div className="col-4">
						</div>
						<div className="col-4">
						</div>
					</div>
					<Field property="address" />
				</Form>
			</div></Tab>
			<Tab label="Профессиональная деятельность"><div className="p-1">
				{state.eduRecords.length ? <div className="mb-1">
					<div className="bg-edu p-2 mb-1">
						<h6 className="m-0">Образование</h6>
					</div>
					{state.eduRecords.map (record => renderEdu (record.id))}
				</div> : <div />}
				{state.retrainingRecords.length ? <div className="mb-2">
					<div className="bg-retraining p-2 mb-1">
						<h6 className="m-0">Переподготовка</h6>
					</div>
					{state.retrainingRecords.map (record => renderRetraining (record.id))}
				</div> : <div />}
				<Form store={store} rsc="record" rid={state.emp} mid="emp" hideButtons disabled>
					<div className="row">
						<div className="col-6">
							<Field property="org" dict />
							<Field property="terr" dict />
							<Field property="post" dict />
							<Field property="pedExp" />
							<Field property="category" dict />
						</div>
					</div>
				</Form>
				{state.rewardRecords.length ? <div className="">
					<div className="bg-reward p-2 mb-1">
						<h6 className="m-0">Награды</h6>
					</div>
					{state.rewardRecords.map (record => renderReward (record.id))}
				</div> : <div />}
			</div></Tab>
			<Tab label="Аттестация на присвоение категории"><div className="p-1">
				{state.trainingRecords.length ? <div className="mb-1">
					<div className="bg-training p-2 mb-1">
						<h6 className="m-0">Повышение квалификации</h6>
					</div>
					{state.trainingRecords.map (record => renderTraining (record.id))}
				</div> : <div />}
				{state.eduActResultRecords.length ? <div className="mb-1">
					<div className="bg-eduactresult p-2 mb-1">
						<h6 className="m-0">Результаты образовательной деятельности</h6>
					</div>
					{state.eduActResultRecords.map (record => renderEduActResult (record.id))}
				</div> : <div />}
				{state.personalContributionRecords.length ? <div className="">
					<div className="bg-personalcontribution p-2 mb-1">
						<h6 className="m-0">Личный вклад и достижения</h6>
					</div>
					{state.personalContributionRecords.map (record => renderPersonalContribution (record.id))}
				</div> : <div />}
			</div></Tab>
			<Tab label={store.roleCode == "admin" ? "Экспертное заключение" : "Заявление"}><div className="p-2">
				<Form store={store} rsc="record" rid={state.rid} hideLogButton>
					<div className="row">
						<div className="col-4">
							<Field property="state" disabled={!(store.username == "admin" || mediator.record.role?.code == "admin")} />
						</div>
						<div className="col-4">
							<Field property="category" disabled />
						</div>
						<div className="col-4">
							<Field property="date" showTime disabled />
						</div>
					</div>
					<div className="row">
						<div className="col-4">
							<Field
								property="exam"
								disabled={!(store.username == "admin" || mediator.record.role?.code == "admin")}
								dict
								records={state.examRecords}
							/>
						</div>
						<div className="col-4">
							<Field property="examScore" disabled={!(store.username == "admin" || mediator.record.role?.code == "admin")} />
						</div>
					</div>
				</Form>
				{(store.username == "admin" || mediator.record.role?.code == "admin") && <div>
					<h5>Эксперты</h5>
					<div className="d-flex align-items-center mb-1">
						<DictField recs={state.empRecs} placeholder="Выберите эксперта" onChange={({value}) => dispatch ({expert: value})}/>
						<Action label="Добавить" btnClassName="btn btn-primary ml-1" onClick={onCreateEmp} disabled={!state.expert} />
					</div>
					<Grid id="emps" store={store} query="declaration.emp" params={{declaration: state.rid}} refresh={state.refresh}>
						<Action icon="fas fa-minus" label="Удалить" onClick={onRemoveEmp} confirm selected />
						<Action icon="fas fa-eye-slash" label="Отменить рассмотрение эксперта" onClick={onCancelExpert} selected transaction confirm />
					</Grid>
				</div>}
				{["manager", "expert"].indexOf (mediator.record.role?.code) > -1 ? <div>
					<h5>Экспертное заключение</h5>
					<div className="row">
						<div className="col-4">
							<StringField value={store.dict ["d.declaration.expertState"][state.declarationEmpRecord.state]?.name} disabled />
						</div>
						<div className="col-4">
							<StringField value={state.declarationEmpRecord.startDate.toLocaleString ()} disabled />
						</div>
						<div className="col-4">
							<StringField value={state.declarationEmpRecord.endDate?.toLocaleString ()} disabled />
						</div>
					</div>
					<div className="mt-1">
						<StringField label="Развернутый комментарий эксперта" textarea onChange={({value}) => dispatch ({comment: value})} disabled={!!state.declarationEmpRecord.state} />
					</div>
					{!state.declarationEmpRecord.state && <div>
						<h5>Завершить рассмотрение</h5>
						<div className="d-flex">
							<Action label="Принять" icon="fas fa-check" onClick={onAccept} />
							<Action label="Отклонить" icon="fas fa-times" className="ml-1" onClick={onReject} />
						</div>
					</div>}
				</div> : <div />}
			</div></Tab>
		</Tabs>
	</div>;
};
