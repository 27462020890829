/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {useState, useEffect} from "react";
import {Action, Grid, Loading} from "objectum-react";
import PageTitle from "./PageTitle";
import mediator, {store} from "../modules/mediator";

export default function Exams (props) {
	let exam = props.match.params.rid.split ("#")[0]
	let [examRecord, setExamRecord] = useState ();
	let [loading, setLoading] = useState (true);
	let [declarations, setDeclarations] = useState (null);

	useEffect (() => {
		async function load () {
			let record = await store.getRecord (exam);
			setExamRecord (record);

			let records = await store.getRecords ({
				model: "declaration",
				filters: [
					["exam", "=", exam]
				]
			});
			let chiefRecords = await store.getRecords ({
				model: "declaration",
				filters: [
					["chiefExam", "=", exam]
				]
			});
			setDeclarations ([0, ...records.map (record => record.id), ...chiefRecords.map (record => record.id)]);
			setLoading (false);
			await mediator.set ("exam", exam);
		}
		load ();
	}, [exam]);

	if (loading) {
		return <Loading />;
	}
	let query = "declaration.list";

	if (store.roleCode == "chiefmod") {
		query = "declaration.chiefmod";
	}
	if (store.roleCode == "pedmod") {
		query = "declaration.pedmod";
	}
	return <div>
		<PageTitle>Участники экзамена: {examRecord?.getLabel ()}</PageTitle>
		<Grid {...props} id="declarations" store={store} query={query} params={{emp: `is not null`, archivePlace: "is null", state: "is not null", id: ` in (${declarations})`}}>
			<div className="d-flex pb-1">
{/*
				<Action icon="fas fa-edit" label="Открыть" onClick={({id}) => {
					props.history.push ({
						pathname: "/exam_declaration/" + id
					});
				}} selected />
*/}
				{store.roleCode == "observer" ? null : <Action icon="fas fa-angle-double-right" label="Перемещение участников" onClick={() => {
					props.history.push ({
						pathname: "/exam_move/" + exam
					});
				}} />}
			</div>
		</Grid>
	</div>;
};
