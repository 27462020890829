/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import { useContext } from 'react'
import DashboardContext from './DashboardContext'
import { store } from '../modules/mediator.js'

export default function DashboardState() {
    const { lastRecs, empMap } = useContext(DashboardContext)

    return (
        <div className="border p-1 mt-2 bg-white rounded">
            <h5 className="mr-5">Последнее изменение статуса заявления</h5>
            <table className="table table-bordered table-sm">
                <thead>
                    <tr>
                        <th rowSpan={2}>Ф.И.О.</th>
                        <th colSpan={2}>Предполагаемая организация</th>
                        <th rowSpan={2}>Статус</th>
                        <th rowSpan={2}>Дата</th>
                        <th rowSpan={2}>Модератор</th>
                    </tr>
                    <tr>
                        <th>Наименование</th>
                        <th>Учредитель</th>
                    </tr>
                </thead>
                <tbody>
                    {lastRecs.map(rec => {
                        const empRec = empMap[rec.emp]

                        return (
                            <tr key={rec.id}>
                                <td>{empRec.fio}</td>
                                <td>{empRec.allegedOrgName || ''}</td>
                                <td>{empRec.founderName || ''}</td>
                                <td>{store.dict['d.declaration.state'][rec.state].name}</td>
                                <td>{rec.stateDate.toLocaleDateString()}</td>
                                <td>{rec.login || 'admin'}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}
