/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {useState, useEffect} from "react";
import {Action, Loading, Form, JsonField, NumberField, BooleanField, StringField} from "objectum-react";
import {store, getJulianDay} from "../modules/mediator";
import PageTitle from "./PageTitle";

export default function Options () {
	let [loading, setLoading] = useState (true);
	let [rid, setRid] = useState (null);

	useEffect (() => {
		async function load () {
			await store.getDict ("d.declaration.state");

			let records = await store.getRecords ({
				model: "objectum.option",
				filters: [
					["code", "=", "admin"]
				]
			});
			setRid (records [0].id);
			setLoading (false);
		}
		load ();
	}, []);

	if (loading) {
		return <Loading />
	}
	let props = [
/*
		{prop: "chiefSubmitDays", label: "Количество календарных дней для подачи заявления руководителем (указывается кол-во дней от момента получения ЛК до нажатия на кнопку \"Подать заявку\")", component: NumberField},
*/
		{prop: "chiefExamDays", label: "Количество календарных дней для записи на экзамен у руководителя (кандидата) (указывается кол-во дней от даты подачи заявления)", component: NumberField},
		{prop: "pedExamDays", label: "Количество календарных дней для записи на экзамен у педагогов", component: NumberField},
		{prop: "tkArchiveDays", label: 'Количество календарных дней для установки статуса "Архив КТ" для заявлений со статусом "Не соответствует квалификационной категории"', component: NumberField},
		{prop: "pedagogRegDisabled", label: "Отключить регистрацию педагогов", component: BooleanField},
		{prop: "pedagogConfirm", label: "Согласие на предоставление персональных данных (педагог)", component: StringField},
		{prop: "", label: "Согласие на предоставление персональных данных (педагог)", component: StringField}
	];
	if (store.roleCode == "chiefmod") {
		props = [
/*
			{prop: "chiefSubmitDays", label: "Количество календарных дней для подачи заявления руководителем (указывается кол-во дней от момента получения ЛК до нажатия на кнопку \"Подать заявку\")", component: NumberField},
*/
			{prop: "chiefExamDays", label: "Количество календарных дней для записи на экзамен у руководителя (кандидата) (указывается кол-во дней от даты подачи заявления)", component: NumberField},
			{prop: "tkArchiveDays", label: 'Количество календарных дней для установки статуса "Архив КТ" для заявлений со статусом "Не соответствует квалификационной категории"', component: NumberField}
		];
	}
	if (store.roleCode == "pedmod") {
		props = [
			{prop: "pedExamDays", label: "Количество календарных дней для записи на экзамен у педагогов", component: NumberField},
			{prop: "pedagogRegDisabled", label: "Отключить регистрацию педагогов", component: BooleanField},
			{prop: "pedagogConfirm", label: "Согласие на предоставление персональных данных (педагог)", component: StringField}
		];
	}
	return <div className="">
		<PageTitle>Параметры</PageTitle>
		<div className="border rounded">
			<Form store={store} rsc="record" rid={rid} mid="objectum.option" hideLogButton>
				<JsonField
					property="value" label={""}
					props={props}
				/>
				{store.roleCode == "pedmod" ? null : <Action label='Установить статус "Архив КТ"' onClick={async ({progress}) => {
					let optionRecord = await store.getRecord (rid);
					let opts = JSON.parse (optionRecord.value);

					if (!opts.tkArchiveDays || opts.tkArchiveDays < 1) {
						throw new Error ("Укажите количество дней");
					}
					let jd = getJulianDay (new Date ());
					let records = await store.getRecords ({
						model: "declaration",
						filters: [
							["state", "=", store.dict ["d.declaration.state"]["noMatch"].id],
							["stateDate", "is not null"]
						]
					});
					let num = 0;

					for (let i = 0; i < records.length; i ++) {
						let record = records [i];

						if (jd - getJulianDay (record.stateDate) >= opts.tkArchiveDays) {
							record.state = store.dict ["d.declaration.state"]["ktArchive"].id;
							record.stateDate = new Date ();
							await record.sync ();
							num ++;
						}
						progress ({value: i + 1, max: records.length});
					}
					return `Найдено заявлений: ${num}`;
				}} />}
			</Form>
		</div>
	</div>;
}
