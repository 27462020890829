/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import mediator, {store} from "../modules/mediator.js";
import { getOrgName, getTerrName, getOtherCitizenshipVisible } from './emp'

function getUrlName (id) {
	let record = store.map ["record"][id];

	if (record && record.code == "sysMon") {
		return "Ссылка на показатели";
	}
	if (record && record.code == "act") {
		return "Ссылка на приказ о награждении";
	}
	return "Ссылка";
}

async function createUnits (rid, action, dispatch) {
	if (!["manager", "expert"].includes (store.roleCode)) {
		return;
	}
	let list = [], newRecords = [];

	await store.transaction (async () => {
		let tables = ["edu", "training", "retraining", "reward", "specialReward", "eduActResult", "personalContribution", "studentContest", "presentation", "inventory"];

		for (let i = 0; i < tables.length; i ++) {
			let table = tables [i];
			let records = action [`${table}Records`];
			let map = {};

			action.unitRecords.forEach (record => map [`${record [table]}-${record.emp}`] = record);

			for (let j = 0; j < records.length; j ++) {
				let record = records [j];
				if (!map [`${record.id}-${mediator.record.user.emp}`]) {
					list.push ({
						_model: "t.declaration.unit",
						declaration: rid,
						emp: mediator.record.user.emp,
						[table]: record.id
					});
				}
			}
		}
		for (let i = 0; i < list.length; i ++) {
			dispatch ({loading: `Создание блока для экспертного заключения: ${i + 1} / ${list.length}`});
			newRecords.push (await store.createRecord (list [i]));
		}
	});
	action.unitRecords = [...action.unitRecords, ...newRecords];
	dispatch ({loading: "Загрузка"});
}

export default async function loadPedagogDeclarationData (rid, dispatch) {
	const ts1 = new Date()
	let action = {
		loading: false
	}
	let [orgRecords, declarationRecord, unitRecords] = await Promise.all([
		store.getRecords ({model: "org"}),
		store.getRecord (rid),
		store.getRecords ({model: "t.declaration.unit", filters: [["declaration", "=", rid]]}),
		store.getDict ("d.emp.eduActResult"),
		store.getDict ("d.declaration.expertState"),
		store.getDict ("d.declaration.blockState"),
		store.getDict ("objectum.role"),
		store.getDict ("d.emp.reward")
	])
	action.orgRecords = orgRecords
	action.declarationRecord = declarationRecord
	action.unitRecords = unitRecords

	let emp = action.declarationRecord.emp;
	action.emp = emp;
	action.isExpert = [
		store.dict["objectum.role"]["expert"].id,
		store.dict["objectum.role"]["manager"].id
	].indexOf (store.roleId) > -1;
	action.isAdmin = store.username == "admin" || ["pedmod", "chiefmod", "admin"].indexOf (store.roleCode) > -1;

	let empRecord = await store.getRecord (emp);

	action.empName = `${empRecord.surname} ${empRecord.forename} ${empRecord.patronymic || ""}`;

	let [
		orgName,
		terrName,
		otherCitizenshipVisible,
		empUserRecords,
		userRecords,
		empRecords,
		roleRecords,
		examRecords,
		pedagogData
	] = await Promise.all([
		getOrgName(empRecord.org),
		getTerrName(empRecord.terr),
		getOtherCitizenshipVisible(empRecord.citizenship),
		store.getRecords ({model: "objectum.user", filters: [["emp", "=", emp]]}),
		store.getRecords ({model: "objectum.user"}),
		store.getRecords ({model: "emp"}),
		store.getRecords ({model: "objectum.role"}),
		store.getRecords ({model: "exam"}),
		empRecord.loadPedagogData ()
	])
	let {
		eduRecords,
		trainingRecords,
		retrainingRecords,
		rewardRecords,
		specialRewardRecords,
		presentationRecords,
		inventoryRecords,
		studentContestRecords,
		eduActResultRecords,
		personalContributionRecords
	} = pedagogData

	Object.assign (action, {
		eduRecords, trainingRecords, retrainingRecords,
		rewardRecords, specialRewardRecords, eduActResultRecords, personalContributionRecords,
		studentContestRecords, presentationRecords, inventoryRecords
	});
	await createUnits (rid, action, dispatch);

	if (orgName) {
		action.orgName = orgName
	}
	if (terrName) {
		action.terrName = terrName
	}
	if (otherCitizenshipVisible) {
		action.otherCitizenshipVisible = otherCitizenshipVisible
	}
	if (empUserRecords.length) {
		action.userId = empUserRecords [0].id;
	} else {
		let pedagogRoleRecords = await store.getRecords ({model: "objectum.role", filters: [["code", "=", "pedagog"]]});
		action.roleId = pedagogRoleRecords [0].id;
	}
	action.eduActResultRecords.forEach (record => {
		if (record.eduActResult) {
			action [`urlLabel-${record.id}`] = getUrlName (record.eduActResult);
		}
	});
	action.examRecords = examRecords

	let empRecs = [], empMap = {}, roleMap = {};

	roleRecords.forEach (record => roleMap [record.id] = record);
	empRecords.forEach (record => empMap [record.id] = record);

	userRecords.forEach (userRecord => {
		let roleRecord = roleMap [userRecord.role];
		let empRecord = empMap [userRecord.emp];

		if (empRecord && roleRecord && ["expert", "manager"].indexOf (roleRecord.code) > -1) {
			empRecs.push ({
				id: empRecord.id,
				name: `${empRecord.surname} ${empRecord.forename} ${empRecord.patronymic || ""} (${roleRecord.name})`
			});
		}
	});
	action.empRecords = empRecords;
	action.empRecs = empRecs;

	if (action.isExpert) {
		action.declarationEmpRecord = (await store.getRecords ({
			model: "t.declaration.emp",
			filters: [
				["declaration", "=", rid],
				["emp", "=", mediator.record.user.emp]
			]
		})) [0];
		action.unitRecords = await store.getRecords ({
			model: "t.declaration.unit",
			filters: [
				["declaration", "=", rid],
				["emp", "=", mediator.record.user.emp]
			]
		});
		action.unitRecords.forEach (record => {
			[
				"edu", "retraining", "training", "reward", "specialReward",
				"eduActResult", "personalContribution", "studentContest", "presentation", "inventory"
			].forEach (table => action [`unitState-${record [table]}`] = record.state);
		});
	}
	if (action.isAdmin) {
		action.unitRecords = await store.getRecords ({
			model: "t.declaration.unit",
			filters: [
				["declaration", "=", rid]
			]
		});
	}
	dispatch (action);
	console.log('pedagog loading time', (new Date().getTime() - ts1.getTime()) / 1000)
}
