/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import React from "react";
import objectumClient from "objectum-client";

const {Record} = objectumClient;

export default class DFounderModel extends Record {
	static _renderGrid ({grid, store}) {
		return React.cloneElement (grid, {
			query: "d.founder",
			onRenderCell: ({cell, col, rec}) => {
				if (col.code == "info") {
					let num = 0, accesibleNum = 0;
					store.map ["dict"]["org"].forEach (record => {
						if (record.founder == rec.id) {
							num ++;

							if (record.accessible) {
								accesibleNum ++;
							}
						}
					});
					return <span>{num ? `${num}/${accesibleNum}` : ""}</span>
				} else {
					return cell;
				}
			}
		});
	}

	static _layout () {
		return [
			"id",
			["name"],
			["terr"],
			["startDate", "endDate"],
			[{table: "t.founder.extension", label: "123"}]
		]
	}
};
