/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {useState} from "react";
import {Action, Grid} from "objectum-react";
import PageTitle from "./PageTitle";
import {store} from "../modules/mediator";

export default function Docs (props) {
	let [refresh, setRefresh] = useState (false);

	function onCreate () {
		props.history.push ({
			pathname: "/doc/new"
		});
	};
	function onEdit ({id}) {
		props.history.push ({
			pathname: "/doc/" + id
		});
	};
	async function onRemove ({id}) {
		await store.transaction (() => store.removeRecord (id));
		setRefresh (!refresh);
	};
	let chief = "is not null";

	if (store.roleCode == "chiefmod") {
		chief = " = 1";
	}
	if (store.roleCode == "pedmod") {
		chief = " = 0";
	}
	return <div>
		<PageTitle>Документы</PageTitle>
		<Grid {...props} id="docs" store={store} query="docs" params={{chief}} refresh={refresh}>
			<div className="d-flex pb-1">
				<Action icon="fas fa-plus" label="Добавить" onClick={onCreate} />
				<Action icon="fas fa-edit" label="Открыть" onClick={onEdit} selected />
				<Action icon="fas fa-minus" label="Удалить" onClick={onRemove} confirm selected />
			</div>
		</Grid>
	</div>;
};
