/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {useReducer, useEffect, useRef} from "react";
import {DictField, StringField, Field, Action, Form, goRidLocation, Loading, Tabs, Tab} from "objectum-react";
import mediator, {store} from "../modules/mediator";
import data from "../modules/data";

function init ({rid}) {
	return {
		loading: true,
		rid,
		emp: null
	};
}

function reducer (state, action) {
	return Object.assign ({}, state, action);
}

export default function SaprUser (props) {
	let [state, dispatch] = useReducer (reducer, props, init);
	let formEl = useRef (null);

	useEffect (() => {
		async function load () {
			let action = {loading: false};

			if (state.rid) {
				let userRecord = await store.getRecord (state.rid);

				if (userRecord.emp) {
					action.emp = userRecord.emp;
					action.loading = true;
				}
				action.role = userRecord.role;
			}
			if (store.roleCode == "terrmod") {
				action.terr = (await store.getRecord (mediator.record.user.emp)).terr;
				action.role = store.dict ["objectum.role"]["chief"].id;
			}
			action.isAdmin = store.username == "admin" || ["pedmod", "chiefmod", "admin"].indexOf (store.roleCode) > -1;
			await store.getDict ("d.terr");
			await store.getDict ("d.emp.post");
			await store.getDict ("d.founder");
			await store.getDict ("objectum.role");
			dispatch (action);
		};
		load ();
	}, [state.rid]);

	useEffect (() => {
		async function load () {
			let action = {};

			if (state.emp) {
				action.loading = false;
			}
			dispatch (action);
		};
		load ();
	}, [state.emp]);

	async function onCreate (rid) {
		await store.transaction (async () => {
			let empRecord = await store.createRecord ({
				_model: "emp",
				surname: state.surname,
				forename: state.forename,
				patronymic: state.patronymic,
				terr: state.terr,
				allegedOrg: state.allegedOrg
			});
			let record = await store.getRecord (rid);
			record.emp = empRecord.id;
			await record.sync ();
		});
		dispatch ({rid});
		props.onCreate (rid);
		goRidLocation (props, rid);
	}

	async function onCreateEmp (emp) {
		try {
			await store.startTransaction ();
			let record = await store.getRecord (state.rid);
			record.emp = emp;
			await record.sync ();
			await store.commitTransaction ();
		} catch (err) {
			await store.rollbackTransaction ();
			throw err;
		}
		dispatch ({emp});
	}

	async function onCheck () {
		let userRecords = await store.getRecords ({
			model: "objectum.user",
			filters: [
				["snils", "=", state.snils]
			]
		});
		userRecords = userRecords.filter (record => record.id != state.rid);

		if (!userRecords.length) {
			return "Указанный СНИЛС не найден";
		}
		let userRecord = userRecords [0];

		if (!userRecord.emp) {
			return `Указанный СНИЛС найден. Пользователь id: ${userRecord.id}, роль: ${store.dict ["objectum.role"][userRecord.role].name}`;
		}
		let declarationRecords = await store.getRecords ({
			model: "declaration",
			filters: [
				["emp", "=", userRecord.emp]
			]
		});
		if (!declarationRecords.length) {
			return `Указанный СНИЛС найден. Пользователь id: ${userRecord.id}, роль: ${store.dict ["objectum.role"][userRecord.role].name}`;
		}
		let examRecords = await store.getRecords ({model: "exam"});

		return `Указанный СНИЛС найден. Пользователь id: ${userRecord.id}, роль: ${store.dict ["objectum.role"][userRecord.role].name}. Заявления: ${declarationRecords.map (record => {
			let examRecord = examRecords.find (examRecord => examRecord.id == (record.exam || record.chiefExam));
			return `id: ${record.id}, дата: ${record.date.toLocaleDateString ()}, экзамен: ${examRecord.date.toLocaleDateString ()}`;
		}).join ("; ")}`;
	}
	if (state.loading) {
		return <Loading />;
	}
	let hideLogButton = ["admin", "pedmod", "chiefmod"].indexOf (store.roleCode) == -1;
	let accessibleOrgs = store.map ["dict"]["org"].filter (record => {
		let founderRecord = store.dict ["d.founder"][record.founder];
		return (
			record.accessible && (
				!state.terr || (
					founderRecord &&
					founderRecord.terr == state.terr &&
					founderRecord.startDate &&
					founderRecord.endDate &&
					new Date () >= founderRecord.startDate &&
					new Date () <= founderRecord.endDate
				)
			)
		)
	})

	return <div>
		<Tabs>
			<Tab label="Общие сведения"><div className="p-1">
				<div className="border border-secondary mb-3 bg-empinfo p-1">
					<h5>Учетная запись пользователя</h5>
					<Form
						store={store} rsc="record" rid={state.rid} mid="objectum.user"
						onCreate={onCreate} className="border mb-1" hideLogButton={hideLogButton}
						onChange={({property, value}) => {
							if (property == "snils") {
								dispatch ({snils: value});
							}
						}}
						onValidate={async ({errors}) => {
							// Новый пользователь
							if (formEl.current) {
								await formEl.current.isValid ();

								if (!state.surname || !state.forename || (state.role == store.dict ["objectum.role"]["chief"].id && !state.allegedOrg)) {
									errors ["hiddenField"] = "error";
								}
								if (!state.terr && state.role != store.dict ["objectum.role"]["observer"].id) {
									errors ["hiddenField"] = "error";
								}
							}
						}}
						disabled={store.roleCode == "observer"} hideButtons={store.roleCode == "observer"}
						defaults={{regDate: new Date ()}}
					>
						<div className="row">
							<div className="col-4">
								<Field property="login" onChange={opts => opts.value = opts.value.trim ().toLowerCase ()} />
							</div>
							<div className="col-4">
								<Field property="password" />
							</div>
							<div className="col-4">
								<Field property="role" dict
									   disabled={["pedagog", "chief", "expert", "manager", "terrmod"].indexOf (mediator.record.role?.code) > -1}
									   value={store.roleCode == "terrmod" ? store.dict ["objectum.role"]["chief"].id : undefined}
									   onChange={({value}) => dispatch ({role: value})}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-4">
								<Field property="email" notNull />
							</div>
							<div className="col-4">
								<Field property="snils" regexp="/^[0-9]{3}-[0-9]{3}-[0-9]{3}-[0-9]{2}$/" exampleValue="111-111-111-11" onChange={opts => {
									let value = opts.value || "";
									let s = "";
									for (let i = 0; i < value.length; i ++) {
										if ("0123456789-".includes (value [i])) {
											if (value [i] == '-' && i !== 3 && i !== 7 && i !== 11) {
												continue;
											}
											s += value [i]
										}
									}
									if (s.length > 14) {
										s = s.substr (0, 14);
									}
									value = s;

									if (/^[0-9]{3}$/.test (value) || /^[0-9]{3}-[0-9]{3}$/.test (value) || /^[0-9]{3}-[0-9]{3}-[0-9]{3}$/.test (value)) {
										value = value + "-";
									}
									opts.value = value;
								}} disabled={!!state.hasDeclaration} />
								{["terrmod", "chiefmod", "admin"].indexOf (store.roleCode) > -1 ? <Action label="Проверка" onClick={onCheck} disabled={!state.snils} /> : null}
							</div>
						</div>
					</Form>
					{state.rid ? null : <Form
						record={{terr: store.roleCode == "terrmod" ? state.terr : null}}
						hideButtons onChange={({property, value}) => dispatch ({[property]: value})} ref={formEl}
						disabled={store.roleCode == "observer"}
					>
						<div className="row">
							<div className="col-4">
								<StringField label="Фамилия" property="surname" notNull onChange={opts => opts.value = opts.value.trim()} />
							</div>
							<div className="col-4">
								<StringField label="Имя" property="forename" notNull onChange={opts => opts.value = opts.value.trim()} />
							</div>
							<div className="col-4">
								<StringField label="Отчество" property="patronymic" onChange={opts => opts.value = opts.value.trim()} />
							</div>
						</div>
						<DictField label="Территория" property="terr" records={store.map ["dict"]["d.terr"]} notNull={state.role != store.dict ["objectum.role"]["observer"]?.id}
							disabled={store.roleCode == "terrmod"}
						/>
						{state.role == store.dict ["objectum.role"]["chief"].id  ? <div>
							<DictField
								label={`Предполагаемая организация ${store.roleCode == "terrmod" ? `(${store.dict ["d.terr"][state.terr].name})` : ""}`}
								property="allegedOrg"
								records={accessibleOrgs}
								notNull onChange={async ({value}) => {
									if (value) {
										let record = await store.getRecord (value);
										dispatch ({allegedOrgTerr: `Предполагаемая территория: ${store.dict ["d.terr"][record.terr].name}`});
									} else {
										dispatch ({allegedOrgTerr: ""});
									}
							}} />
							<div><i>{state.allegedOrgTerr}</i></div>
						</div> : null}
					</Form>}
				</div>
				{state.rid && <div>
					<div className="border p-2 mb-3">
						<Form
							store={store} rsc="record" rid={state.emp} mid="emp" onCreate={onCreateEmp}
							className="border mb-1" hideLogButton={hideLogButton} disabled={!!state.hasDeclaration || store.roleCode == "observer"}
							hideButtons={store.roleCode == "observer"}
						>
							<div className="row">
								<div className="col-4">
									<Field property="surname" onChange={opts => opts.value = opts.value.trim()} />
								</div>
								<div className="col-4">
									<Field property="forename" onChange={opts => opts.value = opts.value.trim()} />
								</div>
								<div className="col-4">
									<Field property="patronymic" onChange={opts => opts.value = opts.value.trim()} />
								</div>
							</div>
							<div className="row">
								<div className="col-4">
									<Field property="sex" />
								</div>
								<div className="col-4">
									<Field property="citizenship" dict />
								</div>
								<div className="col-4">
								</div>
							</div>
							<div className="row">
								<div className="col-4">
									<Field property="birthdate" />
								</div>
								<div className="col-4">
									<Field property="birthplace" />
								</div>
								<div className="col-4">
									<Field property="phone" />
								</div>
							</div>
							<Field property="address" label="Адрес проживания" values={data.terr} />
							<Field property="regAddress" values={data.terr} />
							<Field property="terr" dict notNull={state.role != store.dict ["objectum.role"]["observer"].id} disabled={store.roleCode == "terrmod"} />
							<Field property="post" dict disabled={store.roleCode == "expert"} />
						</Form>
					</div>
				</div>}
			</div></Tab>
		</Tabs>
	</div>;
};
