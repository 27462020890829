/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {useState, useEffect} from "react";
import PageTitle from "./PageTitle";
import {Action, Grid, Loading} from "objectum-react";
import mediator, {store} from "../modules/mediator";
import PedagogDeclaration from "./PedagogDeclaration";

export default function ExpertDeclarations (props) {
	let [loading, setLoading] = useState (true);
	let [declarations, setDeclarations] = useState ([]);
	let [declaration, setDeclaration] = useState (null);
	let [listVisible, setListVisible] = useState (true);
	let [declarationRecords, setDeclarationRecords] = useState ([]);
	let [refresh, setRefresh] = useState (false);

	useEffect (() => {
		async function load () {
			let declarationRecords = await store.getRecords ({
				model: "declaration"
			});
			setDeclarationRecords (declarationRecords);

			let records = await store.getRecords ({
				model: "t.declaration.emp",
				filters: [
					["emp", "=", mediator.record.user.emp],
					["state", "is null"]
				]
			});
			setDeclarations ([0, ...records.map (record => record.declaration)]);
			setLoading (false);
		}
		load ();
	}, []);
	if (loading) {
		return <Loading />;
	}
	async function onGet () {
		let stateRecs = await store.getRecs ({
			model: "d.declaration.state",
			filters: [
				["code", "in", ["'await'", "'processing'"]]
			]
		});
		let declarationRecs = (await store.getRecs ({
			model: "declaration",
			filters: [
				["state", "in", stateRecs.map (rec => rec.id)]
			]
		})).filter (rec => !rec.chief);
		let declarationEmpRecs = await store.getRecs ({
			model: "t.declaration.emp"
		});
		declarationEmpRecs.forEach (rec => {
			let declarationRec = declarationRecs.find (declarationRec => declarationRec.id == rec.declaration);

			if (declarationRec) {
				if (rec.emp == mediator.record.user.emp) {
					if (rec.state) {
						declarationRec.empNum = 10;
					} else {
						throw new Error (`У вас не завершено рассмотрение заявления (id: ${declarationRec.id})`);
					}
				}
				declarationRec.empNum = (declarationRec.empNum || 0) + 1;
			}
		});
		declarationRecs = declarationRecs.filter (rec => (rec.empNum || 0) < 3);
		declarationRecs.sort ((a, b) => a.date < b.date ? -1 : 1);

		// postMap
		let empMap = {}, empRecs = await store.getRecs ({
			model: "emp"
		});
		empRecs.forEach (rec => empMap [rec.id] = rec);
		let expertPost = empMap [mediator.record.user.emp].post;

		if (!expertPost) {
			throw new Error ("В вашей учетной записи не указана должность");
		}
		let postMapRecs = await store.getRecs ({
			model: "postMap",
			filters: [
				["expertPost", "=", expertPost]
			]
		});
		let allowedPosts = postMapRecs.map (rec => rec.pedagogPost);
		declarationRecs = declarationRecs.filter (rec => allowedPosts.indexOf (empMap [rec.emp].post) > -1);

		if (!declarationRecs.length) {
			throw new Error ("Заявления на рассмотрение отсутствуют");
		}
		let declarationId = declarationRecs [0].id;

		return await store.transaction (async () => {
			await store.createRecord ({
				_model: "t.declaration.emp",
				declaration: declarationId,
				emp: mediator.record.user.emp,
				startDate: new Date ()
			});
			setDeclarations ([...declarations, declarationId]);
			setRefresh (!refresh);
			return `Заявление добавлено (id: ${declarationId})`;
		});
	};
	return <div>
		<PageTitle>Заявления</PageTitle>
		<div className="d-flex">
			<div className={listVisible ? "" : "d-none"}>
				<Grid {...props} id="declarations" store={store} query="declaration.expert" refresh={refresh} params={{
					emp: `is not null`, state: "is not null", id: ` in (${declarations})`
				}} onSelect={id => {
					setDeclaration (null);
					setDeclaration (id);
					setListVisible (false);
				}}>
					<div className="d-flex mb-1 align-items-center">
						<Action label="Получить заявление на рассмотрение" icon="fas fa-file-download" onClick={onGet} />
						<div className="mx-2 border p-1 rounded">Всего в банке заявлений <span className="badge badge-info">{declarationRecords.length}</span></div>
						<div className="border p-1 rounded">Проверено мной <span className="badge badge-info">{declarations.length - 1}</span></div>
					</div>
				</Grid>
			</div>
			<div className={listVisible ? "d-none" : "sapr-declarations-min border rounded mr-2 d-flex justify-content-center py-5"}>
				<Action icon="fas fa-chevron-right" title="Показать заявления" onClick={() => setListVisible (true)} />
			</div>
			{declaration && <div>
				<div className={listVisible ? "d-none" : ""}>
					<PedagogDeclaration {...props} store={store} id={declaration} />
				</div>
				<div className={listVisible ? "sapr-declarations-min d-block border rounded ml-2 d-flex justify-content-center py-5 h-100" : "d-none"}>
					<Action icon="fas fa-chevron-left" title="Показать заявление" onClick={() => setListVisible (false)} />
				</div>
			</div>}
		</div>
	</div>;
};
