/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import PageTitle from "./PageTitle";
import {store} from "../modules/mediator";

export default function Home () {
	let [newRecords, setNewRecords] = useState ([]);
	let [docRecords, setDocRecords] = useState ([]);

	useEffect (() => {
		async function load () {
			let newRecords = await store.getRecords ({model: "new"});
			newRecords.sort ((a, b) => a.date > b.date ? -1 : 1);
			setNewRecords (newRecords);

			let docRecords = await store.getRecords ({model: "doc"});
			setDocRecords (docRecords);
		}
		load ();
	}, []);

	let pedDocRecords = docRecords.filter (record => !record.chief && !record.type);
	let chiefDocRecords = docRecords.filter (record => record.chief && !record.type);

	return <div>
		<div className="row no-gutters mb-1">
			<div className="col-6 pr-1">
				<div className="border rounded p-1">
					<PageTitle>Новости для педагогов</PageTitle>
					{newRecords.filter (record => !record.chief).slice (0, 3).map ((record, i) => {
						return <div key={i} className="border rounded my-1">
							<div className="p-2 my-1 border-bottom d-flex align-items-center">
								<div className="text-muted">{record.date.toLocaleDateString ()}</div>
								<div className="ml-2 font-weight-bold">{record.theme}</div>
							</div>
							<div className="p-2" dangerouslySetInnerHTML={{__html: record.text}} />
						</div>;
					})}
					<div className="my-3">
						<Link to="/all_news?chief=0">Все новости</Link>
					</div>
				</div>
			</div>
			<div className="col-6">
				<div className="border rounded p-1">
					<PageTitle>Новости для руководителей</PageTitle>
					{newRecords.filter (record => record.chief).slice (0, 3).map ((record, i) => {
						return <div key={i} className="border rounded my-1">
							<div className="p-2 my-1 border-bottom d-flex align-items-center">
								<div className="text-muted">{record.date.toLocaleDateString ()}</div>
								<div className="ml-2 font-weight-bold">{record.theme}</div>
							</div>
							<div className="p-2" dangerouslySetInnerHTML={{__html: record.text}} />
						</div>;
					})}
					<div className="my-3">
						<Link to="/all_news?chief=1">Все новости</Link>
					</div>
				</div>
			</div>
		</div>
		<div className="row no-gutters">
			<div className="col-6 pr-1">
				<div className="jumbotron">
					<h1 className="display-4">Аттестация<br/>педагогов<br/><br/></h1>
					<p className="lead">Подача заявления на повышение категории<br/><br/><br/></p>
					<hr className="my-4" />
					<Link className="btn btn-primary" to="/login">Вход / Регистрация</Link>
				</div>
			</div>
			<div className="col-6">
				<div className="jumbotron">
					<h1 className="display-4">Аттестация<br/>руководителей<br/>ОО</h1>
					<p className="lead">Аттестация руководителей ОО,<br/>кандидаты на замещение вакантной должности<br/>руководитель ОО</p>
					<hr className="my-4" />
					<Link className="btn btn-primary" to="/login?chief=1">Вход</Link>
				</div>
			</div>
		</div>
		{docRecords.length ? <div className="row no-gutters">
			<div className="col-6 pr-1">
				{pedDocRecords.length ? <div className="border rounded p-1">
					<PageTitle>Документы</PageTitle>
					{pedDocRecords.map ((record, i) => {
						return <div key={i} className="mt-3">
							<a target="_blank" rel="noopener noreferrer" href={record.getRef ("file")}>{record.file}</a>
						</div>;
					})}
				</div> : null}
			</div>
			<div className="col-6">
				{chiefDocRecords.length ? <div className="border rounded p-1">
					<PageTitle>Документы</PageTitle>
					{chiefDocRecords.map ((record, i) => {
						return <div key={i} className="mt-3">
							<a target="_blank" rel="noopener noreferrer" href={record.getRef ("file")}>{record.file}</a>
						</div>;
					})}
				</div> : null}
			</div>
		</div> : null}
	</div>;
};
