/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {useState, useEffect} from "react";
import {DictField, NumberField, Action, i18n} from "objectum-react";
import {store} from "../modules/mediator";

const months = [
	"Январь",
	"Февраль",
	"Март",
	"Апрель",
	"Май",
	"Июнь",
	"Июль",
	"Август",
	"Сентябрь",
	"Октябрь",
	"Ноябрь",
	"Декабрь"
];
let cd = new Date ();
cd.setMonth (cd.getMonth () - 3);
let curYear = cd.getFullYear ();
let curMonth = cd.getMonth ();
let monthRecs = [];

for (let i = 0; i < 12; i ++) {
	let m = curMonth + i, y = curYear;

	if (m > 11) {
		m -= 12;
		y ++;
	}
	monthRecs.push ({
		id: `${m}-${y}`, name: `${months [m]}, ${y}`
	});
}
let times = [
	"09:00",
	"10:00",
	"11:00",
	"12:00",
	"13:00",
	"14:00",
	"15:00",
	"16:00",
	"17:00",
	"18:00",
	"19:00"
];
times = times.map (v => {
	return {id: v, name: v};
});
export default function ExamCreator ({onCreateExams}) {
	let [month, setMonth] = useState (`${new Date ().getMonth ()}-${new Date ().getFullYear ()}`);
	let [m, y] = month.split ("-");
	let [selected, setSelected] = useState ([]);
	let [time1, setTime1] = useState (times [0].id);
	let [time2, setTime2] = useState (times [4].id);
	let [capacity1, setCapacity1] = useState (20);
	let [capacity2, setCapacity2] = useState (20);
	let [type, setType] = useState (null);
	let [examRecords, setExamRecords] = useState ([]);

	useEffect (() => {
		async function load () {
			await store.getDict ("d.exam.type");

			let opts = {model: "exam"};

			if (store.roleCode == "pedmod") {
				opts.filters = [
					["type", "=", store.dict ["d.exam.type"]["ped"].id]
				];
			}
			if (store.roleCode == "chiefmod") {
				opts.filters = [
					["type", "=", store.dict ["d.exam.type"]["chief"].id]
				];
			}
			setExamRecords (await store.getRecords (opts));
		}
		load ();
	}, []);

	function renderDates () {
/*
		let dates = [];

		for (let i = 0; i < dayNum; i ++) {
			let d = new Date (y, m, i + 1);
			let disabled = false;
			let cls = "sapr-cursor";

			if (d.getDay () == 6 || d.getDay () == 0) {
				cls = "text-danger";
				disabled = true;
			}
			if (selected.indexOf (i + 1) > -1) {
				cls += " bg-primary text-white";
			}
			dates.push (<div key={i} className={`p-1 ${cls} border`} onClick={() => {
				if (disabled) {
					return;
				}
				let idx = selected.indexOf (i + 1);

				if (idx == -1) {
					selected.push (i + 1);
				} else {
					selected.splice (idx, 1);
				}
				setSelected ([...selected]);
			}}>
				{i + 1}
			</div>);
		}
		return dates;
*/
		let days = [
			i18n ("Mon"),
			i18n ("Tue"),
			i18n ("Wed"),
			i18n ("Thu"),
			i18n ("Fri"),
			i18n ("Sat"),
			i18n ("Sun")
		];
		let rows = [], row = [];
		let d = new Date (y, m, 1);

		while (1) {
			if (d.getDay () > 1) {
				d.setDate (d.getDate () - 1);
			} else {
				break;
			}
		}
		while (1) {
			let disabled = d.getMonth () != m;
			let month = d.getMonth (), date = d.getDate ();
			let records = examRecords.filter (record => {
				return (d.getDate () == record.date?.getDate () &&
					d.getMonth () == record.date?.getMonth () &&
					d.getFullYear () == record.date?.getFullYear ()
				);
			});
			row.push (<td className="" key={date}>
				<div
					className={`${disabled ? "text-secondary" : "sapr-cursor"} ${(!disabled && selected.indexOf (date) > -1) ? "bg-primary text-white" : ""} text-center`}
					onClick={() => {
						if (month != m) {
							return;
						}
						let idx = selected.indexOf (date);

						if (idx == -1) {
							selected.push (date);
						} else {
							selected.splice (idx, 1);
						}
						setSelected ([...selected]);
					}}
				>
					<div>{date}</div>
					<div>{records.map (record => <div className="text-success">{record.date.toLocaleTimeString ().substr (0, 5)}</div>)}</div>
				</div>
			</td>);
			d.setDate (d.getDate () + 1);

			if (d.getDay () == 1) {
				rows.push (<tr key={d.getDate ()}>{row}</tr>);
				row = [];

				if (d.getMonth () != m) {
					break;
				}
			}
		}
		return <table className="table table-sm border mr-1">
			<thead>
			<tr>
				{days.map ((day, i) => <th key={i} className={`text-center ${i > 4 ? "text-danger" : ""}`}>{day}</th>)}
			</tr>
			</thead>
			<tbody>{rows}</tbody>
		</table>;
	}
	async function createExams () {
		let records = await store.getRecords ({
			model: "exam",
			filters: [
				["type", "=", type]
			]
		});

		for (let i = 0; i < selected.length; i ++) {
			let [hour1, min1] = time1.split (":");
			let date1 = new Date (y, m, selected [i], hour1, min1, 0, 1);

			let exam = records.find (record =>
				record.date.getFullYear () == date1.getFullYear () &&
				record.date.getMonth () == date1.getMonth () &&
				record.date.getDate () == date1.getDate ()
			);
			if (!exam) {
				await store.createRecord ({
					_model: "exam",
					date: date1,
					capacity: capacity1,
					type
				});
				if (time2) {
					let [hour2, min2] = time2.split (":");
					let date2 = new Date (y, m, selected [i], hour2, min2, 0, 1);

					await store.createRecord ({
						_model: "exam",
						date: date2,
						capacity: capacity2,
						type
					});
				}
			}
		}
		setSelected ([]);
		onCreateExams ();
	}
	return <div className="mb-1">
		<div className="bg-new p-1">
			<div className="row">
				<div className="col-4">
					<DictField label="Месяц" recs={monthRecs} value={month} onChange={({value}) => {
						setMonth (value);
						setSelected ([]);
					}} />
				</div>
				<div className="col-4">
					<label>Выберите даты</label>
					<div className="">
						{renderDates ()}
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-2">
					<DictField label="Экзамен 1" recs={times} value={time1} onChange={({value}) => {
						setTime1 (value);
					}} />
				</div>
				<div className="col-2">
					<NumberField label="Вместимость 1" value={capacity1} min={1} onChange={({value}) => {
						setCapacity1 (value);
					}} />
				</div>
				<div className="col-2">
					<DictField label="Экзамен 2" recs={times} value={time2} onChange={({value}) => {
						setTime2 (value);
					}} />
				</div>
				<div className="col-2">
					<NumberField label="Вместимость 2" value={capacity2} min={1} onChange={({value}) => {
						setCapacity2 (value);
					}} />
				</div>
				<div className="col">
					<DictField label="Тип" value={type} records={store.map.dict ["d.exam.type"]} onChange={({value}) => {
						setType (value);
					}} />
				</div>
			</div>
			<Action label="Добавить экзамены" disabled={!selected.length || !time1 || !type} onClick={createExams} transaction store={store} />
		</div>
	</div>;
};
