/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {store} from "../modules/mediator.js";

export async function getOrgName(org) {
	if (org) {
		let orgRecord = await store.getRecord (org);
		return orgRecord.name;
	}
}

export async function getTerrName(terr) {
	if (terr) {
		let terrRecord = await store.getRecord (terr);
		return terrRecord.name;
	}
}

export async function getOtherCitizenshipVisible(citizenship) {
	if (citizenship) {
		let record = await store.getRecord (citizenship);

		if (record.code == "other") {
			return true
		}
	}
}
