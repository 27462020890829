/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {useState, useEffect, useRef} from "react";
import PageTitle from "./PageTitle";
import ExamField from "./ExamField";
import {Action, Grid, Loading, DictField, BooleanField, Field, Form} from "objectum-react";
import {store, getJulianDay, getOptions} from "../modules/mediator";

export default function PedagogDeclarations (props) {
	let [loading, setLoading] = useState (true);
	let [emp, setEmp] = useState (null);
	let [categoryRecords, setCategoryRecords] = useState ([]);
	let [category, setCategory] = useState (null);
	let [exam, setExam] = useState (null);
	let [hideExam, setHideExam] = useState (true);
	let [examRecords, setExamRecords] = useState ([]);
	let [refresh, setRefresh] = useState (false);
	let [declarationRecord, setDeclarationRecord] = useState (null);
	let [options, setOptions] = useState ({});
	let [files, setFiles] = useState([])
	let [showFiles, setShowFiles] = useState(false)
	let [acceptFiles, setAcceptFiles] = useState(false)
	let [error, setError] = useState ("");
	let formRef = useRef(null)
	let [docListFile, setDocListFile] = useState("")

	useEffect (() => {
		async function load () {
			await store.getDict ("d.declaration.state");
			let record = await store.getRecord (store.userId);
			setEmp (record.emp);

			let records = await store.getDict ("d.emp.category");
			records = records.filter (record => record.code != "none");
			setCategoryRecords (records);

			if (!record.emp) {
				return setError ('Заполните "Основные сведения"');
			}
			let empRecord = await store.getRecord (record.emp);

			if (empRecord.submitCategory) {
				setCategory (empRecord.submitCategory);

/*
				if (empRecord.submitCategory == store.dict ["d.emp.category"]["top"].id) {
					setHideExam (false);
				}
*/
			}
			setDocListFile(empRecord.docList)
			await store.getDict ("d.exam.type");
			setOptions (await getOptions ());

			let examRecords = await store.getRecords ({
				model: "exam",
				filters: [
					["type", "=", store.dict ["d.exam.type"]["ped"].id],
					["date", ">", new Date ().toLocaleDateString ()]
				]
			});
			let declarationRecords = await store.getRecords ({
				model: "declaration",
				filters: [
					["exam", "in", examRecords.map (record => record.id)]
				]
			});
			examRecords = examRecords.filter (examRecord => {
				if (examRecord.capacity <= declarationRecords.filter (declarationRecord => declarationRecord.exam == examRecord.id).length) {
					return false;
				}
				return true;
			});
			setExamRecords (examRecords);

			// docs
			let files = []
			let {
				eduRecords,
				trainingRecords,
				retrainingRecords,
				rewardRecords,
				specialRewardRecords,
				studentContestRecords,
				eduActResultRecords,
				personalContributionRecords
			} = await empRecord.loadPedagogData ();

			[
				{ property: "degreeFile", label: "Документ о присуждении ученой степени" },
				{ property: "rankFile", label: "Документ о присвоении ученого звания" },
				{ property: "categoryDoc", label: "Документ о действующей квалификационной категории" },
				{ property: "analyticalReport", label: "Аналитическая справка" },
				{ property: "nameChangeDoc", label: "Документ об изменении фамилии" }
			].forEach(o => {
				if (empRecord[o.property]) {
					files.push({ ref: empRecord.getRef(o.property), name: empRecord[o.property], label: o.label })
				}
			});
			[
				{ records: eduRecords, label: "Документ об образовании" },
				{ records: trainingRecords, label: "Документ о повышении квалификации" },
				{ records: retrainingRecords, label: "Документ о переподготовке" },
				{ records: rewardRecords, label: "Документ о наличии отраслевой награды" },
				{ records: specialRewardRecords, label: "" },
				{ records: studentContestRecords, label: "Документ о результатах участия обучающихся в олимпиадах, конкурсах, конференциях (по преподаваемому предмету)" },
				{ records: eduActResultRecords, label: "Документ о результатах образовательной деятельности" },
				{ records: personalContributionRecords, label: "Документ о личном вкладе и достижениях" }
			].forEach(o => {
				o.records.forEach(record => {
					if (record.file) {
						files.push({ ref: record.getRef("file"), name: record.file, label: o.label })
					}
				})
			})
			setFiles(files)
			setLoading (false);
		}
		load ();
	}, []);
	async function onCreate ({confirm}) {
		if (!category) {
			throw new Error ("Выберите категорию");
		}
		if (!hideExam && !exam) {
			throw new Error ("Выберите дату экзамена");
		}
		let userRecord = await store.getRecord (store.userId);
		let empRecord = await store.getRecord (emp);

		if (!userRecord.email) {
			throw new Error ("Заполните: Электронная почта");
		}
		let errorFields = await store.remote ({
			model: "emp",
			method: "checkFields",
			id: emp,
			fields: ["surname", "forename", "sex", "citizenship", "address", "terr", "post", "org", "pedExp", "analyticalReport"]
		});
		if (errorFields.length) {
			throw new Error ("Заполните: " + errorFields.join(", "));
		}
		let eduRecords = await store.getRecords ({
			model: "t.emp.edu",
			filters: [
				["emp", "=", empRecord.id]
			]
		});
		if (!eduRecords.length) {
			throw new Error ("Заполните: Образование");
		}
		if (!(await confirm ("Пожалуйста, убедитесь что все данные заполнены правильно. В случае отказа повторная подача на аттестацию возможна лишь через год. Подать заявление сейчас?"))) {
			return;
		}
		if (!hideExam) {
			let examRecord = examRecords.find (record => record.id == exam);
			let declarationRecords = await examRecord.getDeclarationRecords ();

			if (declarationRecords.length >= examRecord.capacity) {
				throw new Error ("Отсутствуют места на выбранную дату экзамена");
			}
		}
		/*
		let now = new Date (await store.remote ({model: "admin", method: "currentUTCTimestamp"}));
		await store.startTransaction ("Подача заявления");
		try {
			await store.createRecord ({
				_model: "declaration",
				date: now,
				emp,
				category,
				exam: hideExam ? null : exam,
				state: store.dict ["d.declaration.state"]["await"].id,
				chief: false
			});
			await store.commitTransaction ();
			setRefresh (!refresh);
		} catch (err) {
			await store.rollbackTransaction ();
			throw err;
		}
		*/

		let examDate

		if (exam) {
			const examRecord = await store.getRecord (exam)
			examDate = examRecord.date.toLocaleString ().substring(0, 17)
		}
		await store.remote ({
			model: "declaration",
			method: "submitPedagog",
			emp,
			stateId: store.dict ["d.declaration.state"]["await"].id,
			category,
			exam: hideExam ? null : exam,
			examDate
		})
		setRefresh (!refresh)
	}
	async function onFinish () {
		await store.transaction (async () => {
			declarationRecord.state = store.dict ["d.declaration.state"]["await"].id;
			await declarationRecord.sync ();
		});
		setRefresh (!refresh);
	}
	async function docListReport() {
		let rows = files.map((file, i) => {
			let label = file.label

			if (label == prevLabel) {
				label = ""
			}
			prevLabel = file.label

			return { v0: i + 1, v1: label, v2: file.name }
		})
		let response = await fetch ("/doc_list_report", {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify ({a: rows})
		});
		let blob = await response.blob ();
		let url = window.URL.createObjectURL (blob);
		let a = document.createElement ('a');
		a.href = url;
		a.download = "doc-list.docx";
		document.body.appendChild (a);
		a.click ();
		a.remove ();
	}
	if (error) {
		return <div className="alert alert-danger">{error}</div>
	}
	if (loading) {
		return <Loading />;
	}
	let prevLabel

	return <div>
		<PageTitle>Подача заявления</PageTitle>
		<div className="row">
			<div className="col-4">
				<DictField label="Категория" notNull value={category} disabled records={categoryRecords} onChange={({value}) => {
					setCategory (value);

					if (value == store.dict ["d.emp.category"]["top"].id) {
						setHideExam (false);
					}
				}} />
			</div>
			<div className="col-2">
				<label>Экзамен</label>
				<BooleanField label="Сдавать экзамен" value={!hideExam} onChange={({value}) => setHideExam (!value)} />
			</div>
			<div className="col-6">
				{!hideExam && <ExamField label="Выберите дату" onChange={({value}) => setExam (value)} records={examRecords.filter (record => {
					let valid = record.type == store.dict ["d.exam.type"]["ped"].id;
					let jd = getJulianDay (new Date ());

					if (options.pedExamDays && (getJulianDay (record.date) - jd > options.pedExamDays)) {
						valid = false;
					}
					return valid;
				})} />}
			</div>
		</div>
		<div className="mb-2 d-flex align-items-center">
			<div>Всего загружено документов</div>
			<div className="p-2 border rounded mx-3">{files.length}</div>
			<Action label="Список документов" icon="fas fa-list" onClick={() => setShowFiles(!showFiles)} />
			<Action label="Скачать" icon="fas fa-file-download" onClick={() => docListReport()} />
			<div className="ml-3 mt-2">
				<BooleanField label="Согласие со списком документов" onChange={({value}) => setAcceptFiles(value)} />
			</div>
		</div>
		{showFiles ? <table className="table table-bordered">
			<tr>
				<th style={ { width: "5em" } }>№ п/п</th>
				<th style={ { width: "30em" } }>Тип</th>
				<th>Наименование</th>
			</tr>
			{files.map((file, i) => {
				let label = file.label

				if (label == prevLabel) {
					label = ""
				}
				prevLabel = file.label

				return <tr key={i}>
					<td>{i + 1}</td>
					<td>{label}</td>
					<td>
						<a target="_blank" rel="noreferrer" href={file.ref}>{file.name}</a>
					</td>
				</tr>
			})}
		</table> : null}
		{emp ? <div className="mb-2">
			<Form ref={formRef} store={store} rsc="record" rid={emp} hideButtons>
				<Field
					property="docList"
					description="Прикрепите цветной скан, в формате pdf, не более 8 мб"
					maxSize={8 * 1024 * 1024}
					accept=".pdf"
					onChange={async ({value}) => {
						setDocListFile(value)
						await formRef.current.onSave ()
					}}
				/>
			</Form>
		</div> : null}
		<div className="mb-2">
			<Action
				icon="fas fa-plus"
				label="Подать заявление"
				onClick={onCreate}
				disabled={!category || !acceptFiles || !docListFile}
			/>
		</div>
		<PageTitle>Поданные заявления</PageTitle>
		{declarationRecord?.state == store.dict ["d.declaration.state"]["editing"].id && <div className="mb-2">
			<Action
				icon="fas fa-plus"
				label="Завершить редактирование заявления"
				onClick={onFinish}
				confirm
			/>
		</div>}
		<Grid {...props} id="declarations" store={store} query="declaration.pedagog" params={{emp}} refresh={refresh} onSelect={async (id) => {
			setDeclarationRecord (await store.getRecord (id));
		}} />
	</div>;
};
