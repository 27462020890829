/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {useState, useEffect} from "react";
import {Loading} from "objectum-react";
import Pedagog from "./Pedagog";
import Chief from "./Chief";
import SaprUser from "./SaprUser";
import {store} from "../modules/mediator";

export default function User (props) {
	let [loading, setLoading] = useState (true);
	let [rid, setRid] = useState ((rid => rid == "new" ? null : rid) (props.match.params.rid.split ("#")[0]));
	let [roleCode, setRoleCode] = useState ("");

	useEffect (() => {
		async function load () {
			if (rid) {
				let userRecord = await store.getRecord (rid);
				let roleRecord = await store.getRecord (userRecord.role);
				setRoleCode (roleRecord.code);
			}
			setLoading (false);
		}
		load ();
		// eslint-disable-next-line
	}, [rid]);

	if (loading) {
		return <Loading />;
	}
	if (roleCode == "pedagog") {
		return <Pedagog {...props} rid={rid} onCreate={rid => setRid (rid)}/>;
	} else if (roleCode == "chief") {
		return <Chief {...props} rid={rid} onCreate={rid => setRid (rid)} />;
	} else {
		return <SaprUser {...props} rid={rid} onCreate={rid => setRid (rid)} />;
	}
};
