/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {factory, Record} from "objectum-client";

export default class EmpModel  extends Record {
    async loadChiefData () {
        let empData = await this.store.remote ({model: "emp", id: this.id, method: "loadChiefData"});
        empData.jobRecs.forEach (rec => {
            rec.startDate = rec.startDate ? new Date (rec.startDate) : null;
            rec.endDate = rec.endDate ? new Date (rec.endDate) : null;
        });
        let data = {};

        ["edu", "training", "retraining", "reward", "crime", "language", "election", "job", "exp"].forEach (a => {
            let records = empData [`${a}Recs`].map (data => {
                data._model = `t.emp.${a}`;
                let record = factory ({rsc: "record", data, store: this.store});
                this.store.map ["record"][data.id] = record;
                return record;
            });
            data [`${a}Records`] = records;
        });
        return data;
    }

    async loadPedagogData () {
        let empData = await this.store.remote ({model: "emp", id: this.id, method: "loadPedagogData"});
        let data = {};

        ["edu", "training", "retraining", "reward", "specialReward", "eduActResult", "personalContribution", "studentContest", "presentation", "inventory"].forEach (a => {
            let records = empData [`${a}Recs`].map (data => {
                data._model = `t.emp.${a}`;
                let record = factory ({rsc: "record", data, store: this.store});
                this.store.map ["record"][data.id] = record;
                return record;
            });
            data [`${a}Records`] = records;
        });
        return data;
    }

    static async loadDicts (store) {
        let data = await store.remote ({model: "emp", method: "loadDicts"});
        let models = [
            "d.emp.allegedPost",
            "d.person.citizenship",
            "d.person.sex",
            "d.declaration.nomination",
            "d.declaration.blockState",
            "d.declaration.expertState",
            "d.emp.eduActResult",
            "d.person.language",
            "d.person.languageLevel",
            "d.emp.post",
            "d.declaration.chiefResult",
            "d.emp.reward",
            "d.emp.scienceDegree",
            "d.emp.scienceRank",
            "d.level",
            "d.declaration.state",
            "d.emp.category",
            "d.terr",
            "objectum.role"
        ];

        for (let i = 0; i < models.length; i ++) {
            let path = models [i];
            let m = store.getModel (path);
            let id = m.id;

            if (store.map ["dict"][id]) {
                return store.map ["dict"][id];
            }
            let records = data [path].map (data => {
                data._model = path;
                return factory ({rsc: "record", data, store});
            });
            store.map ["dict"][id] = records;
            store.dict [id] = {};

            records.forEach (record => {
                store.dict [id][record.id] = record;

                if (record.code && !store.dict [id][record.code]) {
                    store.dict [id][record.code] = record;
                }
            });
            store.map ["dict"][path] = store.map ["dict"][id];
            store.dict [path] = store.dict [id];
        }
    }
}
