/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {useState, useEffect} from "react";
import {NumberField, StringField, Loading, Action} from "objectum-react";
import RadioField from "./RadioField";
import mediator, {store} from "../modules/mediator";

export default function Unit ({id, table, declaration, state, dispatch, showScore}) {
	let [loading, setLoading] = useState (true);
	let [unitState, setUnitState] = useState (null);
	let [comment, setComment] = useState ("");
	let [score, setScore] = useState ("");
	let [unitRecord, setUnitRecord] = useState (null);

	useEffect (() => {
		async function load () {
/*
			let records = await store.getRecords ({
				model: `t.declaration.unit`,
				filters: [
					["declaration", "=", declaration],
					["emp", "=", mediator.record.user.emp],
					[table, "=", id]
				]
			});
*/
			let records = state.unitRecords.filter (record => record.emp == mediator.record.user.emp && record [table] == id);
			let record;

			if (!records.length) {
				throw new Error (`Запись экспертного заключения не найдена`);
/*
				await store.startTransaction ();
				try {
					record = await store.createRecord ({
						_model: "t.declaration.unit",
						declaration,
						emp: mediator.record.user.emp,
						[table]: id
					});
					await store.commitTransaction ();
				} catch (err) {
					await store.rollbackTransaction ();
					throw err;
				}
*/
			} else {
				record = records [0];
			}
			setUnitState (record.state);
			setComment (record.comment);
			setScore (record.score);
			setUnitRecord (record);
			setLoading (false);
		}
		if (["manager", "expert"].indexOf (mediator.record.role?.code) > -1) {
			load ();
		} else {
			setLoading (false);
		}
		// eslint-disable-next-line
	}, [declaration, id, table]);

	async function onSave () {
		await store.startTransaction ();
		try {
			unitRecord.state = unitState;
			unitRecord.comment = comment;

			if (showScore) {
				unitRecord.score = score;
			}
			await unitRecord.sync ();
			await store.commitTransaction ();
			dispatch ({[`unitState-${id}`]: unitState});
		} catch (err) {
			await store.rollbackTransaction ();
			throw err;
		}
	}

	function renderExpert () {
		let disabled = !!state.declarationEmpRecord.state;

		return <div className="p-2 bg-white border">
			<h6>Экспертное заключение</h6>
			<div className="d-flex align-items-center">
				<RadioField records={store.map.dict ["d.declaration.blockState"]} value={unitState} onChange={({value}) => setUnitState (value)} disabled={disabled} />
				{unitState == store.dict ["d.declaration.blockState"]["rejected"].id && <div className="ml-1">
					<StringField placeholder="Комментарий" textarea rows={2} maxlength={140} value={comment} onChange={({value}) => setComment (value)} disabled={disabled} />
				</div>}
				{showScore ? <NumberField placeholder="Балл" value={score} onChange={({value}) => setScore (value)} disabled={disabled} /> : null}
				{!disabled && <Action label="Сохранить" className="ml-1" icon="fas fa-check" onClick={onSave} disabled={unitRecord.state == unitState && unitRecord.comment == comment} />}
			</div>
		</div>;
	}
	function renderAdmin () {
		return <div className="p-2 bg-white border">
			<table className="table table-bordered">
				<thead>
					<tr>
						<th>Ф.И.О.</th>
						<th>Статус</th>
						<th>Комментарий</th>
						{showScore ? <th>Балл</th> : null}
					</tr>
				</thead>
				<tbody>
					{state.unitRecords?.filter (record => record [table] == id).map ((record, i) => {
						let empRecord = state.empRecords.find (empRecord => empRecord.id == record.emp);

						return <tr key={i}>
							<td>{`${empRecord.surname} ${empRecord.forename} ${empRecord.patronymic || ""}`}</td>
							<td>{record.state ? store.map.record [record.state]?.name : ""}</td>
							<td>{record.comment}</td>
							{showScore ? <td>{record.score}</td> : null}
						</tr>;
					})}
				</tbody>
			</table>
		</div>;
	}

	if (store.roleCode == "pedObserver") {
		return null
	}
	return <div className="">
		{loading ? <Loading /> : (["manager", "expert"].indexOf (mediator.record.role?.code) > -1 ? renderExpert () : renderAdmin ())}
	</div>;
};
