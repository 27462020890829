const org = [
	'Дагестанская академия образования и культуры',
	'Дагестанский государственный аграрный университет (ДагГАУ)',
	'Дагестанский государственный медицинский университет (ДГМУ)',
	'Дагестанский государственный университет (ДГУ)',
	'Дагестанский государственный университет народного хозяйства (ДГУНХ)',
	'Дагестанский государственный педагогический университет (ДГПУ)',
	'Дагестанский государственный технический университет (ДГТУ)',
	'Дагестанский гуманитарный институт',
	'Федеральное Государственное Бюджетное Образовательное Учреждение Высшего Образования "Дагестанский Государственный Университет"',
	'Федеральное Государственное Бюджетное Образовательное Учреждение Высшего Образования "Дагестанский Государственный Аграрный Университет Имени М.М.Джамбулатова"',
	'Государственное Автономное Образовательное Учреждение Высшего Образования "Дагестанский Государственный Университет Народного Хозяйства"',
	'Государственное Бюджетное Профессиональное Образовательное Учреждение Республики Дагестан "Промышленно-Экономический Колледж"',
	'Государственное Бюджетное Профессиональное Образовательное Учреждение Республики Дагестан "Автомобильно-Дорожный Колледж"',
	'Государственное Бюджетное Профессиональное Образовательное Учреждение Республики Дагестан "Технический Колледж"',
	'Государственное Бюджетное Профессиональное Образовательное Учреждение Республики Дагестан "Колледж Экономики И Предпринимательства" Г. Буйнакск"',
	'Государственное Бюджетное Профессиональное Образовательное Учреждение "Профессионально-Педагогический Колледж Имени Расула Гамзатова"',
	'Государственное Бюджетное Профессиональное Образовательное Учреждение Республики Дагестан "Республиканский Сельскохозяйственный Колледж Им. Ш.И.Шихсаидова"',
	'Государственное Бюджетное Профессиональное Образовательное Учреждение Республики Дагестан "Колледж Экономики И Права" (ГБПОУ РД КЭИП)"',
	'Государственное Бюджетное Профессиональное Образовательное Учреждение Республики Дагестан "Дербентский Профессионально-Педагогический Колледж Имени Г.Б.Казиахмедова"',
	'Государственное Бюджетное Профессиональное Образовательное Учреждение Республики Дагестан "Аграрно-Экономический Колледж"',
	'Государственное Бюджетное Профессиональное Образовательное Учреждение Республики Дагестан "Профессионально-Педагогический Колледж Имени З.Н. Батырмурзаева"',
	'Государственное Бюджетное Профессиональное Образовательное Учреждение Республики Дагестан "Электромеханический Колледж"',
	'Государственное Бюджетное Профессиональное Образовательное Бюджетное Учреждение Республики Дагестан "Профессионально-Педагогический Колледж Имени М.М. Меджидова"',
	'Государственное Бюджетное Профессиональное Образовательное Учреждение Республики Дагестан "Колледж Машиностроения И Сервиса Им. С. Орджоникидзе"',
	'Государственное Бюджетное Профессиональное Образовательное Учреждение Республики Дагестан "Железнодорожный Колледж"',
	'Государственное Бюджетное Профессиональное Образовательное Учреждение Республики Дагестан "Колледж Строительства И Дизайна"',
	'Государственное Бюджетное Профессиональное Образовательное Учреждение Республики Дагестан "Колледж Архитектуры И Строительства" (Г. Каспийск)"',
	'Государственное Бюджетное Профессиональное Образовательное Учреждение Республики Дагестан "Индустриально-Промышленный Колледж"',
	'Государственное Бюджетное Профессиональное Образовательное Учреждение "Аграрный Колледж" Г. Дагестанские Огни"',
	'Государственное Бюджетное Профессиональное Образовательное Учреждение Республики Дагестан "Кизлярский Профессионально-Педагогический Колледж"',
	'Государственное Бюджетное Профессиональное Образовательное Учреждение Республики Дагестан "Колледж Народных Промыслов И Туризма"',
	'Государственное Бюджетное Профессиональное Образовательное Учреждение Республики Дагестан "Дорожно-Строительный Колледж"',
	'Государственное Бюджетное Профессиональное Образовательное Учреждение Республики Дагестан "Колледж Сферы Услуг"',
	'Государственное Бюджетное Профессиональное Образовательное Учреждение Республики Дагестан "Дербентский Медицинский Колледж Имени Г.А.Илизарова"',
	'Частное Профессиональное Образовательное Учреждение "Медицинский Колледж Имени Башларова"',
	'Федеральное Государственное Бюджетное Образовательное Учреждение Высшего Образования "Дагестанский Государственный Медицинский Университет" Министерства Здравоохранения Российской Федерации"',
	'Частное Образовательное Учреждение Высшего Образования "Социально-Педагогический Институт"',
	'Государственное Бюджетное Профессиональное Образовательное Учреждение Республики Дагестан "Дагестанский Колледж Культуры И Искусств Им. Б. Мурадовой"',
	'Государственное Бюджетное Профессиональное Образовательное Учреждение Республики Дагестан "Дагестанское Художественное Училище Им. М. А. Джемала"',
	'Государственное Бюджетное Профессиональное Образовательное Учреждение Республики Дагестан "Дербентское Музыкальное Училище"',
	'Государственное Бюджетное Профессиональное Образовательное Учреждение Республики Дагестан "Махачкалинское Музыкальное Училище Им. Г.А.Гасанова"',
	'Государственное Бюджетное Профессиональное Образовательное Учреждение Республики Дагестан "Буйнакское Медицинское Училище"',
	'Государственное Бюджетное Профессиональное Образовательное Учреждение Республики Дагестан "Дагестанский Базовый Медицинский Колледж Им. Р.П.Аскерханова"',
	'Государственное Бюджетное Профессиональное Образовательное Учреждение Республики Дагестан "Каспийское Медицинское Училище Им.А.Алиева"',
	'Государственное Бюджетное Профессиональное Образовательное Учреждение Республики Дагестан "Кизлярское Медицинское Училище"',
	'Профессиональное Образовательное Учреждение "Гуманитарно-Педагогический Колледж"',
	'Профессиональное Образовательное Учреждение "Техникум Дизайна, Экономики И Права"',
	'Частное Профессиональное Образовательное Учреждение "Дагестанский Колледж Образования"',
	'Государственное Бюджетное Профессиональное Образовательное Учреждение Республики Дагестан "УЧИЛИЩЕ ОЛИМПИЙСКОГО РЕЗЕРВА ПО ФУТБОЛУ "Дагестан"',
	'Частное Профессиональное Образовательное Учреждение "Республиканский Медико-Социальный Колледж"',
	'Профессиональное Образовательное Учреждение "Колледж Гражданской Обороны И Чрезвычайным Ситуациям"',
	'Профессиональная Образовательная Автономная Некоммерческая Организация "Медицинский Колледж" Г. Хасавюрт РД"',
	'Профессиональная Образовательная Автономная Некоммерческая Организация "Республиканский Многопрофильный Медицинский Колледж"',
	'Филиал Федерального Государственного Бюджетного Образовательного Учреждения Высшего Образования "Дагестанский Государственный Университет" В Г. Дербенте',
	'Филиал Федерального Государственного Бюджетного Образовательного Учреждения Высшего Образования "Дагестанский Государственный Университет" В Г. Хасавюрте"',
	'Филиал Федерального Государственного Бюджетного Образовательного Учреждения Высшего Образования "Дагестанский Государственный Университет" В Г. Кизляре"',
	'Филиал Федерального Государственного Бюджетного Образовательного Учреждения Высшего Образования "Дагестанский Государственный Университет" В Г. Избербаше"',
	'Филиал Федерального Государственного Бюджетного Образовательного Учреждения Высшего Образования "Ростовский Государственный Экономический Университет (РИНХ)" В Г.Махачкале Республики Дагестан"',
	'Северо-Кавказский Институт (Филиал) Федерального Государственного Бюджетного Образовательного Учреждения Высшего Образования "Всероссийский Государственный Университет Юстиции (РПА Минюста России)" В Г. Махачкале',
	'Филиал Федерального Государственного Бюджетного Образовательного Учреждения Высшего Образования "Санкт-Петербургский Государственный Экономический Университет" В Г. Кизляре',
	'Филиал Федерального Государственного Бюджетного Образовательного Учреждения Высшего Образования "Дагестанский Государственный Технический Университет" В Г. Дербенте"',
	'Махачкалинский Финансово-Экономический Колледж-Филиал Федерального Государственного Образовательного Бюджетного Учреждения Высшего Образования "Финансовый Университет При Правительстве Российской Федерации"',
	'Избербашский Филиал ГБПОУ "ЧРТ"',
	'Государственное Бюджетное Профессиональное Образовательное Учреждение Республики Дагестан "Филиал Дагестанского Базового Медицинского Колледжа Им. Р.П.Аскерханова В г.Избербаше"',
	'Дагестанский Гуманитарный Институт (Филиал) Образовательного Учреждения Профсоюзов Высшего Образования "Академия Труда И Социальных Отношений"',
	'Кизлярский Филиал Частного Профессионального Образовательного Учреждения "Медицинский Колледж Имени Башларова"',
	'Хасавюртовский Филиал Частного Профессионального Образовательного Учреждения "Медицинский Колледж Имени Башларова"',
	'Избербашский Филиал Частного Профессионального Образовательного Учреждения "Медицинский Колледж Имени Башларова"',
	'Дербентский Филиал Частного Профессионального Образовательного Учреждения "Медицинский Колледж Имени Башларова"',
	'Северо-Кавказский Филиал Автономной Некоммерческой Образовательной Организации Профессионального Образования "Санкт-Петербургская Академия Милиции"',
	'Профессиональная Образовательная Автономная Некоммерческая Организация "Энергетический Колледж"',
	'Профессиональное Образовательное Учреждение "Южный Социальный Колледж Экономики, Педагогики И Права"',
	'Профессиональное Образовательное Учреждение "Колледж Бизнес-Менеджмента, Экономики И Права"',
	'Государственное Бюджетное Профессиональное Образовательное Учреждение Республики Дагестан "Училище Олимпийского Резерва"',
	'Профессиональное Образовательное Учреждение "Юридический Колледж"',
	'Профессиональная Образовательная Автономная Некоммерческая Организация "Национальный Инновационный Колледж"',
	'Частное Профессиональное Образовательное Учреждение "Социально-Многопрофильный Колледж"',
	'НЕГОСУДАРСТВЕННОЕ ЧАСТНОЕ ПРОФЕССИОНАЛЬНОЕ ОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ "МНОГОПРОФИЛЬНЫЙ КОЛЛЕДЖ"',
	'Профессиональная Образовательная Автономная Некоммерческая Организация "Многопрофильный Колледж"',
	'Профессиональное Образовательное Учреждение "Каспийский Медицинский Колледж"',
	'Частное Профессиональное Образовательное Учреждение "Республиканский Полипрофессиональный Колледж"',
	'Профессиональная Образовательная Автономная Некоммерческая Организация "Северо-Кавказский Гуманитарно-Многопрофильный Колледж Имени Имама Шамиля"',
	'Частное Профессиональное Образовательное Учреждение "Колледж Современного Образования Имени Саида Афанди"',
	'Автономная Некоммерческая Профессиональная Образовательная Организация "Многопрофильный Инновационный Колледж"',
	'Профессиональная Образовательная Автономная Некоммерческая Организация "Международный Гуманитарно-Технический Колледж"'
];
const terr = [
	"2-е отделение совхоза",
	"Абдурашид",
	"Авадан",
	"Аверьяновка",
	"Агада",
	"Агачаул",
	"Агачкала",
	"Агвали",
	"Аглоби",
	"Адага",
	"Аданак",
	"Аджидада",
	"Аджимажагатюрт",
	"Адильотар",
	"Адиль-Янгиюрт",
	"Адукмахи",
	"Азадоглы",
	"Азильта",
	"Айбатхановское лесничество",
	"Аймазимахи",
	"Аймаки",
	"Аймалабек",
	"Аймаумахи",
	"Айникаб",
	"Айникабмахи",
	"Айнурбимахи",
	"Айсалакак",
	"Айтхан",
	"Акайтала",
	"Акаро",
	"Акбулатюрт",
	"Акды",
	"Акка",
	"Акнада",
	"Аксай",
	"Акуша",
	"Акушали",
	"Ала",
	"Аладаш",
	"Алак",
	"Албания",
	"Александрийская",
	"Александро-Невское",
	"Алимпашаюрт",
	"Алиханмахи",
	"Алкадар",
	"Аллате",
	"Алмак",
	"Алмало",
	"Алхаджакент",
	"Алчуниб",
	"Альбурикент",
	"Амалте",
	"Аметеркмахи",
	"Амишта",
	"Амсар",
	"Амуариб",
	"Амузги",
	"Амух",
	"Амущи Большое",
	"Амущи Малое",
	"Анада",
	"Ангида",
	"Анди",
	"Андреевка",
	"Андуз",
	"Андых",
	"Анклух",
	"Ансалта",
	"Анхвала",
	"Анхо",
	"Анцух",
	"Анчик",
	"Апши",
	"Араблинское",
	"Арабляр",
	"Арада",
	"Арак",
	"Аракани",
	"Аракул",
	"Аран",
	"Арани",
	"Арассамахи",
	"Арачанамахи",
	"Аргвани",
	"Аркас",
	"Аркаскент",
	"Аркида",
	"Аркит",
	"Арсланбек",
	"Арсуг",
	"Артлух",
	"Арусси",
	"Архида",
	"Арцалу",
	"Арчиб",
	"Арчо",
	"Арчуг",
	"Арыхкент",
	"Асакент",
	"Асаликент",
	"Асах",
	"Ассаб",
	"Асхабиль-Кули",
	"Атланаул",
	"Атрик",
	"Афна",
	"Ахалчи",
	"Ахар",
	"Ахкент",
	"Ахмедкент",
	"Ахниг",
	"Ахсакадамахи",
	"Ахсу",
	"Ахтачикан",
	"Ахтини",
	"Ахты",
	"Ачи-Су",
	"Ашага-Архит",
	"Ашага-Захит",
	"Ашагакартас",
	"Ашага-Стал",
	"Ашага-Стал-Казмаляр",
	"Ашага-Цинит",
	"Ашага-Ярак",
	"Ашакент",
	"Ашали",
	"Ашар",
	"Ашильта",
	"Ашино",
	"Ашты",
	"Аща",
	"Аялакаб",
	"Аялизимахи",
	"Аямахи",
	"Аяцимахи",
	"Аяцури",
	"Бабаюрт",
	"Бавтугай",
	"Багикла",
	"Багинуб",
	"Бажлук",
	"Баитль",
	"Байкатмахи",
	"Байрам",
	"Байрамаул",
	"Бакдаб",
	"Бакни",
	"Балакури",
	"Балануб",
	"Баласма",
	"Балахани",
	"Балдутль",
	"Балтамахи",
	"Балхар",
	"Бамматюрт",
	"Банайюрт",
	"Барнаб",
	"Барчхойотар",
	"Баршамай",
	"Басар",
	"Баташюрт",
	"Батаюрт",
	"Батлаич",
	"Батлахатли",
	"Батыр-Мурза",
	"Бахмахи",
	"Бахцуг",
	"Бацада",
	"Бацикварих",
	"Башлыкент",
	"Бедюк",
	"Бежта",
	"Бежтинский участок",
	"Бежуда",
	"Беледи",
	"Белиджи",
	"Бергеинзи",
	"Берикей",
	"Берих",
	"Бетельда",
	"Бетли",
	"Бехвалутчи",
	"Бикаламахи",
	"Бильбиль-Казмаляр",
	"Бильгади",
	"Богатырёвка",
	"Большая Арешевка",
	"Большебредихинское",
	"Большезадоевское",
	"Большекозыревское",
	"Большой Урала",
	"Бондареновское",
	"Борагангечув",
	"Боранчи",
	"Борч",
	"Ботлих",
	"Бочох",
	"Брянск",
	"Брянский Рыбзавод",
	"Буанзимахи",
	"Бугдатепе",
	"Буглен",
	"Буйнакск",
	"Буккамахи",
	"Бурганкент",
	"Бургимакмахи",
	"Бургимахи",
	"Бурдеки",
	"Буркихан",
	"Буртанимахи",
	"Буртунай",
	"Бурумбай",
	"Бурхимахи",
	"Буршаг",
	"Бурши",
	"Бускри",
	"Бусутли",
	"Бут-Казмаляр",
	"Буткент",
	"Бутри",
	"Бутулта",
	"Бухнаг",
	"Бухты",
	"Буцра",
	"Вавилово",
	"Ванашимахи",
	"Варсит",
	"Вачи",
	"Великент",
	"Вертиль",
	"Верхнее Гаквари",
	"Верхнее Инхело",
	"Верхнее Инхо",
	"Верхнее Инхоквари",
	"Верхнее Ишкарты",
	"Верхнее Казанище",
	"Верхнее Лабкомахи",
	"Верхнее Махаргимахи",
	"Верхнее Хваршини",
	"Верхние Мулебки",
	"Верхний Арадирих",
	"Верхний Арши",
	"Верхний Батлух",
	"Верхний Дженгутай",
	"Верхний Кавкамахи",
	"Верхний Камкамахи",
	"Верхний Каранай",
	"Верхний Каршли",
	"Верхний Катрух",
	"Верхний Колоб",
	"Верхний Тогох",
	"Верхний Убекимахи",
	"Верхний Чиамахи",
	"Вечрик",
	"Викри",
	"Виноградное",
	"Вираты",
	"Вихли",
	"Вициятль",
	"Вперёд",
	"Вруш",
	"Выше-Таловка",
	"Вышеталовский",
	"Гагар",
	"Гагатли",
	"Гадайчи",
	"Гадари",
	"Гадири",
	"Газардкам-Казмаляр",
	"Газилала",
	"Газия",
	"Гакко",
	"Галатли",
	"Гамиях",
	"Гамсутль",
	"Гандарамахи",
	"Гандурар",
	"Гапцах",
	"Гапшима",
	"Гараколоб",
	"Гарах",
	"Гарбилазда",
	"Гарбутль",
	"Гариг",
	"Гасик",
	"Гацалух",
	"Гачитли",
	"Гаша",
	"Гведыш",
	"Гвиначи",
	"Гдым",
	"Гдынк",
	"Геба",
	"Гебгуда",
	"Геджух",
	"Гекнаумахи",
	"Гели",
	"Гелинбатан",
	"Гельбах",
	"Гельмец",
	"Гельхен",
	"Геметюбе",
	"Геназох",
	"Гендух",
	"Генеколоб",
	"Генжеаул",
	"Геницуриб",
	"Геничутль",
	"Гениятль",
	"Гента",
	"Генух",
	"Герга",
	"Гергебиль",
	"Гергентала",
	"Герейхановское",
	"Герель",
	"Герменчик",
	"Гертма",
	"Герхмахи",
	"Гигатли-Урух",
	"Гигатль",
	"Гигих",
	"Гидатли",
	"Гидатлинский Мост",
	"Гидиб",
	"Гилиб",
	"Гильяр",
	"Гимерсо",
	"Гимры",
	"Гиндиб",
	"Гинта",
	"Гисик",
	"Гиягарамахи",
	"Гоа",
	"Гоаб",
	"Говкра",
	"Гогаз",
	"Гоготль",
	"Годобери",
	"Гозолоколо",
	"Гоксув",
	"Голотль",
	"Гондокори",
	"Гонода",
	"Гонох",
	"Гонтлоб",
	"Гоор",
	"Гоор-Хиндах",
	"Гортколо",
	"Гортноб",
	"Гостала",
	"Гоцатль Большой",
	"Гоцатль Малый",
	"Гоцоб",
	"Гочада",
	"Гочоб",
	"Гребенской Мост",
	"Грузинское",
	"Губден",
	"Гувлиг",
	"Гузбая",
	"Гуйми",
	"Гуладты",
	"Гуладтымахи",
	"Гулебки",
	"Гулли",
	"Гульды",
	"Гуми",
	"Гумрамахи",
	"Гунакари",
	"Гундучи",
	"Гунзиб",
	"Гуни",
	"Гуниб",
	"Гуннамахи",
	"Гунух",
	"Гунха",
	"Гурбуки",
	"Гургумахи",
	"Гурик",
	"Гурхунь",
	"Гутатли",
	"Гущи",
	"Гюгряг",
	"Гюхряг",
	"Дагбаш",
	"Дагестанские Огни",
	"Дагестанское",
	"Дагни",
	"Дадулазда",
	"Дакниса",
	"Дальнее",
	"Дамкулакада",
	"Данух",
	"Дарада",
	"Дарваг",
	"Дардаркент",
	"Даркуш-Казмаляр",
	"Датуна",
	"Дахадаевка[7]",
	"Дегва",
	"Дейбук",
	"Деличобан",
	"Демирар",
	"Дербент",
	"Джаба",
	"Джаванкент",
	"Джавгат",
	"Джалатури",
	"Джалган",
	"Джанга",
	"Джангамахи",
	"Джемикент",
	"Джепель",
	"Джибахни",
	"Джигия",
	"Джилихур",
	"Джинаби",
	"Джиных",
	"Джирабачи",
	"Джугдиль",
	"Джугут",
	"Джули",
	"Джульджаг",
	"Джульджиниф",
	"Джурмачи",
	"Дзержинское",
	"Дзилебки",
	"Дибгалик",
	"Дибгаши",
	"Дилчу[7]",
	"Динго",
	"Дирбакмахи",
	"Дитуншимахи",
	"Доргели",
	"Доронуб",
	"Дружба",
	"Дуакар",
	"Дубки",
	"Дубримахи",
	"Дулдуг",
	"Дуранги",
	"Дуреги",
	"Дусрах",
	"Дучи",
	"Дылым",
	"Дюбек",
	"Дюзлер",
	"Екраг",
	"Ергюняг",
	"Ерси",
	"Ефимовка",
	"Жажада",
	"Жалда",
	"Жамод",
	"Жамсоры",
	"Заза",
	"Зазилькала",
	"Заиб",
	"Заната",
	"Заречное",
	"Заря Коммуны",
	"Захит",
	"Зеленоморск",
	"Зехида",
	"Зибирхали",
	"Зидьян",
	"Зидьян-Казмаляр",
	"Зизик",
	"Зило",
	"Зиль",
	"Зильбачи",
	"Зильдик",
	"Зильмукмахи",
	"Зирани",
	"Зиуриб",
	"Зориотар",
	"Зрых",
	"Зубанчи",
	"Зубутли",
	"Зубутли-Миатли",
	"Зурилаудимахи",
	"Зухрабкент",
	"Ибрагимотар",
	"Ивайлазда",
	"Игали",
	"Играх",
	"Изано",
	"Избербаш",
	"Изта",
	"Икра",
	"Иманалиросо",
	"имени Жданова",
	"имени Калинина",
	"имени Карла Маркса",
	"имени Кирова",
	"имени М. Горького",
	"имени Мичурина",
	"имени Шаумяна",
	"Имунный",
	"Ингердах",
	"Ингиши",
	"Индира",
	"Инзимахи",
	"Иниша",
	"Инквалита",
	"Инкучимахи",
	"Инхело",
	"Инхоквари",
	"Инчха",
	"Инчхе",
	"Ипута",
	"Ираги",
	"Ираки",
	"Иргали",
	"Ирганай",
	"Ириб",
	"Иричи",
	"Исоо",
	"Испик",
	"Итля",
	"Иха",
	"Ихрек",
	"Ицари",
	"Ицирах",
	"Иче",
	"Ичин",
	"Ичичали",
	"Иштибури",
	"Кабасида",
	"Кабир",
	"Кабир-Казмаляр",
	"Кавалар",
	"Кавкамахи",
	"Кадар",
	"Каддамахи",
	"Кадиркент",
	"Кадыротар",
	"Казмааул",
	"Кака",
	"Какамахи",
	"Кака-Шура",
	"Какмахи",
	"Кала",
	"Каладжух",
	"Калиб",
	"Калининаул",
	"Калиновка",
	"Калкни",
	"Калук",
	"Кальял",
	"Камахал",
	"Камаша",
	"Камилух",
	"Камкадамахи",
	"Канасираги",
	"Кандаураул",
	"Кани",
	"Капкайкент",
	"Кара",
	"Карабаглы",
	"Карабудахкент",
	"Карагас",
	"Карадаглы",
	"Карадах",
	"Карадла",
	"Каракюре",
	"Карамахи",
	"Каранайаул",
	"Карануб",
	"Карасу",
	"Карата",
	"Каратюбе",
	"Караузек",
	"Карацан",
	"Караша",
	"Карашимахи",
	"Караямахи",
	"Карбучимахи",
	"Кардамахи",
	"Кардиб",
	"Кардмахи",
	"Кардоновка",
	"Карекадани",
	"Каркаци",
	"Карлабко",
	"Карланюрт",
	"Карталай",
	"Картас-Казмаляр",
	"Карчаг",
	"Карша",
	"Каспийск",
	"Кассагумахи",
	"Касумкент",
	"Катросо",
	"Кафыр-Кумух",
	"Ках",
	"Кахабросо",
	"Кахиб",
	"Кахикал",
	"Кахцуг",
	"Качалай",
	"Качалкент",
	"Качкалык",
	"Качкилик",
	"Кая",
	"Каякент",
	"Квами",
	"Кванада",
	"Кванкеро",
	"Квантлада",
	"Кванхидатли",
	"Квардал",
	"Кверсатль",
	"Кегер",
	"Кеди",
	"Кемсиюрт",
	"Кенафный Завод",
	"Керимханар",
	"Керликент",
	"Кертукмахи",
	"Кесериб",
	"Кидеро",
	"Кижани",
	"Кизилюрт",
	"Кизляр",
	"Кикуни",
	"Килер",
	"Килятль",
	"Кимятли",
	"Кина",
	"Кирка",
	"Кирки",
	"Кироваул",
	"Кирцик",
	"Китлярта",
	"Китури",
	"Киче",
	"Кичи-Гамри",
	"Кишдатль",
	"Кища",
	"Кищамахи",
	"Киэних",
	"Кличхан",
	"Кокрек",
	"Коктюбей",
	"Коллективизатор",
	"Коло",
	"Колоб",
	"Коммуна",
	"Комсомольский",
	"Комсомольское",
	"Кондик",
	"Конциль",
	"Коркмаскала",
	"Корода",
	"Корш",
	"Кособ",
	"Косрода",
	"Костек",
	"Косякино",
	"Кохановское",
	"Кочали",
	"Кочубей",
	"Кочхюр",
	"Кошкент",
	"Крайновка",
	"Красноармейское",
	"Красное",
	"Красное Село",
	"Краснооктябрьское",
	"Краснопартизанск",
	"Красный Восход",
	"Красный Рыбак",
	"Куаниб",
	"Куба",
	"Кубатль",
	"Кубачи",
	"Кубра",
	"Кубримахи",
	"Куваг",
	"Кувиг",
	"Куг",
	"Кудагу",
	"Кудали",
	"Кудиябросо",
	"Кудутль",
	"Кужник",
	"Кузнецовское",
	"Куйбышево",
	"Куйсун",
	"Кукваз",
	"Кукер",
	"Кукни",
	"Кулегу",
	"Кулецма",
	"Кули",
	"Кулибухна",
	"Кулиг",
	"Кулиджа",
	"Кулиф",
	"Кулиямахи",
	"Кулкам",
	"Кулла",
	"Куллар",
	"Кулушац",
	"Кульзеб",
	"Кулькибекмахи",
	"Кума",
	"Кумамахи",
	"Кумли",
	"Кумук",
	"Кумух",
	"Кунбатар",
	"Кундах",
	"Кундурхе",
	"Кунды",
	"Кунзах",
	"Кунки",
	"Куппа",
	"Кураг",
	"Курах",
	"Курдул",
	"Курдюковское",
	"Куримахи",
	"Куркаби",
	"Куркак",
	"Куркент",
	"Куркимахи",
	"Куркли",
	"Курла",
	"Курми",
	"Курукал",
	"Куруш",
	"Кусур",
	"Кутих",
	"Кутиша",
	"Кутлаб",
	"Кутул",
	"Куфа",
	"Кучраб",
	"Куштиль",
	"Кчун-Казмаляр",
	"Кюрек",
	"Кюряг",
	"Кяхулай",
	"Лака",
	"Лаклакюрт",
	"Лакун",
	"Ланда",
	"Лахир",
	"Лахчайда",
	"Леваши",
	"Ленинаул",
	"Ленинкент",
	"Лидже",
	"Лища",
	"Лологонитль",
	"Лопуховка",
	"Лугувалу",
	"Луткун",
	"Лучек",
	"Львовский № 1",
	"Люксембург",
	"Ляхе",
	"Ляхля",
	"Маали",
	"Магар",
	"Магарамкент",
	"Магитль",
	"Мадала",
	"Маджалис",
	"Мазада",
	"Майданское",
	"Мака-Казмаляр",
	"Макаровское",
	"Малая Арешевка",
	"Малая Задоевка",
	"Маллакент",
	"Малое Казыревское",
	"Малый Урала",
	"Мамааул",
	"Мамедкала",
	"Манас",
	"Манасаул",
	"Манаскент",
	"Мангулаул",
	"Марага",
	"Матлас",
	"Махалотли",
	"Махачкала",
	"Мацеевка",
	"Мачада",
	"Мачар",
	"Машаты",
	"Маштада",
	"Мегеб",
	"Межгюль",
	"Мекеги",
	"Местерух",
	"Метрада",
	"Меусиша",
	"Мехельта",
	"Мехетури",
	"Миарсо",
	"Миатли",
	"Миглакасимахи",
	"Миджах",
	"Мижигли",
	"Микдатль",
	"Микик",
	"Микрасанамахи",
	"Микрах",
	"Мирзита",
	"Мирное",
	"Миси",
	"Мискинджа",
	"Митаги",
	"Митаги-Казмаляр",
	"Митлиуриб",
	"Митлуда",
	"Михеевское",
	"Мишлеш",
	"Могилевское",
	"Могох",
	"Могроб",
	"Мокода",
	"Мокок",
	"Моксоб",
	"Моксох",
	"Моллакент",
	"Морское",
	"Мочох",
	"Мощоб",
	"Мугарты",
	"Мугдаб",
	"Мугерган",
	"Муги",
	"Мугри",
	"Мугурух",
	"Мужукай",
	"Музаим",
	"Мукар",
	"Мукракари",
	"Мукутль",
	"Мулла-Али",
	"Муни",
	"Мурада",
	"Мургук",
	"Мурлатинамахи",
	"Мурух",
	"Мусклимахи",
	"Муслах",
	"Мусрух",
	"Мусультемахи",
	"Мухарх",
	"Мухах",
	"Муцалаул",
	"Мучула",
	"Мушули",
	"Мюрего",
	"Мюхрек",
	"Надар",
	"Наказух",
	"Накитль",
	"Нанибика",
	"Нариман",
	"Нарыш",
	"Наскент",
	"Нахада",
	"Нахки",
	"Наци",
	"Начада",
	"Некрасовка",
	"Нечаевка",
	"Нижнее Гаквари",
	"Нижнее Инхело",
	"Нижнее Инхо",
	"Нижнее Ишкарты",
	"Нижнее Казанище",
	"Нижнее Лабкомахи",
	"Нижнее Махаргимахи",
	"Нижнее Мулебки",
	"Нижнее Хваршини",
	"Нижнее Чугли",
	"Нижний Арадирих",
	"Нижний Арши",
	"Нижний Батлух",
	"Нижний Джалган",
	"Нижний Дженгутай",
	"Нижний Каранай",
	"Нижний Каршли",
	"Нижний Катрух",
	"Нижний Кегер",
	"Нижний Колоб",
	"Нижний Тогох",
	"Нижний Убекимахи",
	"Нижний Чиамахи",
	"Нижний Чирюрт",
	"Никабаркмахи",
	"Никар",
	"Никлида",
	"Нитаб",
	"Нитилсух",
	"Ницовкра",
	"Ничрас",
	"Новая Барша",
	"Новая Коса",
	"Новая Мака",
	"Новая Серебряковка",
	"Нововладимирское",
	"Новогагатли",
	"Новогеоргиевка",
	"Новогладовка",
	"Новодмитриевка",
	"Новое",
	"Новое Аргвани",
	"Новое Гадари",
	"Новое Каракюре",
	"Новое Лидже",
	"Новое Мугри",
	"Новое Хелетури",
	"Ново-Зубутли",
	"Новокаре",
	"Новокаякент",
	"Новокохановское",
	"Новокрестьяновское",
	"Новокули",
	"Новолакское",
	"Новомехельта",
	"Новомонастырское",
	"Новонадеждовка",
	"Новониколаевка",
	"Новоромановка",
	"Новосельское",
	"Новососитли",
	"Ново-Теречное",
	"Новочуртах",
	"Новые Викри",
	"Новый Аул",
	"Новый Бахтемир",
	"Новый Бирюзяк",
	"Новый Борч",
	"Новый Костек",
	"Новый Кяхулай",
	"Новый Мамрач",
	"Новый Сулак",
	"Новый Уркарах",
	"Новый Усур",
	"Новый Фриг",
	"Новый Хушет",
	"Новый Чечень",
	"Новый Чиркей",
	"Нойрух",
	"Нукуш",
	"Нурадилово",
	"Нуси",
	"Нухотколоб",
	"Нюгди",
	"Нютюг",
	"Обода",
	"Обонуб",
	"Обох",
	"Огузер",
	"Октябрьское",
	"Опытно-мелиоративная станция",
	"Орада Чугли",
	"Оразгулаул",
	"Оркачи",
	"Орота",
	"Ортаколо",
	"Орта-Стал",
	"Ортатюбе",
	"Оружба",
	"Османюрт",
	"Остров Чечень",
	"Отделения «Сельхозтехника»",
	"Оттал",
	"Охли",
	"Очло",
	"Падар",
	"Палисма",
	"Параул",
	"Пенджи",
	"Первокизлярское",
	"Первомайское",
	"Персидское",
	"Петраковское",
	"Пилиг",
	"Пиляки",
	"Пиперкент",
	"Плодопитомник",
	"Покровское",
	"Привольный",
	"Пригородное",
	"Приморский",
	"Пролетарское",
	"Птикент",
	"Пурримахи",
	"Пятилетка",
	"Равкватль",
	"Раздолье",
	"Ракутли",
	"Рассвет",
	"Ратлуб",
	"Рахата",
	"Рацитль",
	"Рачабулда",
	"Ретлоб",
	"Речное",
	"Риквани",
	"Ритляб",
	"Рича",
	"Ричаганих",
	"Родниковый",
	"Росноб",
	"Роста",
	"Росутль",
	"Рубас",
	"Ругельда",
	"Ругудж",
	"Ругуджа",
	"Ругун",
	"Рука",
	"Рукель",
	"Рульдаб",
	"Рутул",
	"Рушуха",
	"Рущуль",
	"Рыбалко",
	"Рыбзавод-51",
	"Сабда",
	"Сабнова",
	"Сагада",
	"Сагаси-Дейбук",
	"Садовое",
	"Саидкент",
	"Сайтаркент",
	"Салда",
	"Салик",
	"Салта",
	"Сальян",
	"Самилах",
	"Самур",
	"Санакари",
	"Сангар",
	"Сангиши",
	"Саниорта",
	"Санта",
	"Сантлада",
	"Санух",
	"Санчи",
	"Сараг",
	"Сараф",
	"Сардаркент",
	"Сар-Сар",
	"Саситли",
	"Сачада",
	"Сегелер",
	"Семгамахи",
	"Семендер",
	"Сентух",
	"Сергокала",
	"Серебряковка",
	"Сертиль",
	"Сех",
	"Сика",
	"Сикар",
	"Сикух",
	"Силта",
	"Сильди",
	"Симсир",
	"Синида",
	"Сираги",
	"Сиртич",
	"Сиух",
	"Смугул",
	"Советское",
	"Согратль",
	"Содаб",
	"Солнечное",
	"Сомода",
	"Средний Арадирих",
	"Стальское",
	"Старо-Теречное",
	"Старый Бавтугай",
	"Старый Сивух",
	"Степное",
	"Субахтымахи",
	"Судоремонтная Техническая Станция",
	"Сукиях",
	"Сулак",
	"Сулевкент",
	"Сулейбакент",
	"Султан-Янги-Юрт",
	"Сулутюбе",
	"Сумбатль",
	"Сумета",
	"Сумия",
	"Сургия",
	"Сур-Сурбачи",
	"Сурхавкент",
	"Сурхачи",
	"Сусакент",
	"Сутбук",
	"Суюткино",
	"Сюгут",
	"Тагада",
	"Тагзиркент",
	"Тагиркент",
	"Тагиркент-Казмаляр",
	"Тадиял",
	"Тадколо",
	"Тад-Магитль",
	"Такалай",
	"Талги",
	"Талитель",
	"Таловка",
	"Талсух",
	"Талух",
	"Тамазатюбе",
	"Тамазатюбе Старое",
	"Тамуда",
	"Тандо",
	"Тантари",
	"Танты",
	"Тануси",
	"Таратул-Меэр",
	"Тарки",
	"Тарланкак",
	"Тарлимахи",
	"Тарумовка",
	"Тасута",
	"Татарханкент",
	"Татаюрт",
	"Татиль",
	"Татляр",
	"Ташкапур",
	"Тебекмахи",
	"Текипиркент",
	"Телагу",
	"Телетль",
	"Темираул",
	"Темиргое",
	"Тенла",
	"Терекли-Мектеб",
	"Теречное",
	"территория Новострой",
	"Терутли",
	"Тидиб",
	"Тиланиб",
	"Тилутль",
	"Тинди",
	"Тинит",
	"Тинчуда",
	"Тисси",
	"Тисси-Ахитли",
	"Тихаб",
	"Тлайлух",
	"Тлезда",
	"Тленхори",
	"Тлибишо",
	"Тлиси",
	"Тлобзода",
	"Тлогоб",
	"Тлондода",
	"Тлох",
	"Тлядал",
	"Тлянада",
	"Тлянуб",
	"Тлярата",
	"Тляробазутль",
	"Тлярош",
	"Тлях",
	"Тляцуда",
	"Тотурбийкала",
	"Тох",
	"Тох-Орда",
	"Тохота",
	"Тпиг",
	"Трисанчи",
	"Тркал",
	"Тузламахи",
	"Тукита",
	"Тулизма",
	"Тумагари",
	"Тумеллер",
	"Тунзи",
	"Тураг",
	"Турага",
	"Туракаримахи",
	"Турзин",
	"Туруф",
	"Турци",
	"Туршунай",
	"Тутлар",
	"Тухчар",
	"Тушиловка",
	"Тюбе",
	"Убра",
	"Удок",
	"Уздалросо",
	"Узнимахи",
	"Узралмахи",
	"Уй-Салган",
	"Укал",
	"Украинское",
	"Укуз",
	"Уллуая",
	"Уллубийаул",
	"Уллугатаг",
	"Уллукимахи",
	"Уллутеркеме",
	"Уллучара",
	"Улуз",
	"Ульгеб",
	"Умайласулазда",
	"Умаротар",
	"Умашаул",
	"Уна",
	"Ункида",
	"Унты",
	"Унцукуль",
	"Унчукатль",
	"Ураги",
	"Урада",
	"Урадаколоб",
	"Урала",
	"Урари",
	"Урахи",
	"Урга",
	"Ургани",
	"Ургубамахи",
	"Ургула",
	"Уржагимахи",
	"Урзиг",
	"Ури",
	"Уриб",
	"Уркарах",
	"Уркутамахи-1",
	"Уркутамахи-2",
	"Урма",
	"Урсун",
	"Уртиль",
	"Урух-Сота",
	"Урхнища",
	"Урхулакар",
	"Урхучимахи",
	"Урцаки",
	"Урчиаб",
	"Урчух",
	"Усемикент",
	"Усиша",
	"Усуг",
	"Усухчай",
	"Утамыш",
	"Утлух",
	"Ухул",
	"Уцмиюрт",
	"Уцулимахи",
	"Учкент",
	"Ушнюг",
	"Фий",
	"Филя",
	"Фиргиль",
	"Фите",
	"Фурдаг",
	"Фучух",
	"Хабкаймахи",
	"Хадаги",
	"Хадаколоб",
	"Хаджалмахи",
	"Хаджалте",
	"Хадиял",
	"Хажнимахи",
	"Хазар",
	"Халаг",
	"Халах",
	"Халимбекаул",
	"Халих",
	"Хамавюрт",
	"Хамагиб",
	"Хамаитли",
	"Хамакал",
	"Хамаматюрт",
	"Хамар",
	"Ханаг",
	"Ханак",
	"Хандо",
	"Хантаколоб",
	"Ханцкаркамахи",
	"Хапиль",
	"Хараг",
	"Харада",
	"Харазма",
	"Харахи",
	"Харачи",
	"Харбук",
	"Хариб",
	"Хариколо",
	"Хархни",
	"Хасавюрт",
	"Хасакент",
	"Хасанай",
	"Хахита",
	"Хацуноб",
	"Хачта",
	"Хашархота",
	"Хвайни",
	"Хварада",
	"Хвартикуни",
	"Хварши",
	"Хвередж",
	"Хебатли",
	"Хебда",
	"Хебдиб",
	"Хебрела-Эла",
	"Хелетури",
	"Хели",
	"Хенда",
	"Хенклакар",
	"Хенох",
	"Хетох",
	"Хибиятль",
	"Хив",
	"Хидиб",
	"Хилих",
	"Химакоро",
	"Хиндах",
	"Хини",
	"Хинтида",
	"Хинтлимита",
	"Хинуб",
	"Хитаб",
	"Хиях",
	"Хкем",
	"Хлют",
	"Хнов",
	"Хнюх",
	"Хобох",
	"Ходжа-Казмаляр",
	"Хойми",
	"Хойхи",
	"Хонох",
	"Хопор",
	"Хора",
	"Хоредж",
	"Хорель",
	"Хорода",
	"Хорта",
	"Хосрех",
	"Хотлоб",
	"Хотода",
	"Хоточ",
	"Хпедж",
	"Хпюк",
	"Хрюг",
	"Хтун",
	"Хтун-Казмаляр",
	"Хубар",
	"Худиг",
	"Худуц",
	"Хулабаркмахи",
	"Хулисма",
	"Хуна",
	"Хунгия",
	"Хунзах",
	"Хунтли",
	"Хупри",
	"Хури",
	"Хурик",
	"Хурсатиль",
	"Хурукра",
	"Хурух",
	"Хурхи",
	"Хуршни",
	"Хустиль",
	"Хутрах",
	"Хутхул",
	"Хуты",
	"Хуцеевка",
	"Хучада",
	"Хучни",
	"Хушет",
	"Хуштада",
	"Хюрехюр",
	"Хюряк",
	"Цада",
	"Цадах",
	"Цалада",
	"Цалкита",
	"Цамла",
	"Цанак",
	"Цанатль",
	"Цантиль",
	"Цатаних",
	"Цахур",
	"Цвакилколо",
	"Цветковка",
	"Цекоб",
	"Цельмес",
	"Целягюн",
	"Цемер",
	"Ценеб",
	"Церхимахи",
	"Цехок",
	"Цибари",
	"Цибилта",
	"Цидатль",
	"Цизгари",
	"Цилитль",
	"Цимгуда",
	"Цирхе",
	"Цихалах",
	"Цицимах",
	"Цияб Ичичали",
	"Цияб-Цилитли",
	"Цияб-Цолода",
	"Цлак",
	"Цмур",
	"Цнал",
	"Цовкра-1",
	"Цовкра-2",
	"Цолода",
	"Цохок",
	"Цугни",
	"Цудахар",
	"Цудик",
	"Цудук",
	"Цулда",
	"Цуликана",
	"Цумада",
	"Цумада-Урух",
	"Цумали",
	"Цумилух",
	"Цунди",
	"Цундимахи",
	"Цунимахи",
	"Цунта",
	"Цураи",
	"Цуриб",
	"Цурмахи",
	"Цуртиль",
	"Цухдыг",
	"Цухта",
	"Цущар",
	"Цыйша",
	"Чаатли",
	"Чабазимахи",
	"Чабанмахи",
	"Чагаротар",
	"Чагни",
	"Чадаколоб",
	"Чалда",
	"Чало",
	"Чалях",
	"Чанаб",
	"Чанкаламахи",
	"Чанкаюрт",
	"Чанко",
	"Чанкурбе",
	"Чапаево",
	"Чаравали",
	"Чарах",
	"Чарода",
	"Чахдикна",
	"Чахимахи",
	"Чахрижи",
	"Чахчах-Казмаляр",
	"Чвадаб",
	"Червлённые Буруны",
	"Чере",
	"Черняевка",
	"Чилда",
	"Чиликар",
	"Чильдаб",
	"Чинар",
	"Чинимахи",
	"Чираг",
	"Чирката",
	"Чиркей",
	"Читаб",
	"Читль",
	"Читур",
	"Чишили",
	"Чодода",
	"Чондотль",
	"Чоноб",
	"Чонтаул",
	"Чорода",
	"Чох",
	"Чубутла",
	"Чувалар",
	"Чувек",
	"Чукна",
	"Чулат",
	"Чумли",
	"Чуни",
	"Чуниб",
	"Чурдаф",
	"Чуртах",
	"Чухверкент",
	"Шабдух",
	"Шава",
	"Шагада",
	"Шадни",
	"Шаитли",
	"Шаласи",
	"Шалиб",
	"Шамилькала",
	"Шамхал",
	"Шамхал-Термен",
	"Шамхал-Янги-Юрт",
	"Шангода",
	"Шапих",
	"Шара",
	"Шари",
	"Шаури",
	"Шахбулатотар",
	"Шахува",
	"Шивор",
	"Шидиб",
	"Шиланша",
	"Шиле",
	"Шиляги",
	"Шимихюр",
	"Шиназ",
	"Шинкалабухна",
	"Шинкбалакада",
	"Шитли",
	"Шихикент",
	"Шихшака",
	"Шия",
	"Школьное",
	"Шовкра",
	"Шодрода",
	"Шотода",
	"Штул",
	"Шубада",
	"Шугури",
	"Шукты",
	"Шулани",
	"Шулатута",
	"Шулерчи",
	"Шулькевич",
	"Шумли-Олик",
	"Шумхримахи",
	"Шуни",
	"Шурагат",
	"Шушановка",
	"Шушия",
	"Эбдалая",
	"Эбута",
	"Эгдада",
	"Эгеда",
	"Эдиге",
	"Экендиль",
	"Экибулак",
	"Элакатмахи",
	"Эльбок",
	"Эминхюр",
	"Эндирей",
	"Энсеруда",
	"Эрпели",
	"Эсетар",
	"Эчеда",
	"Юбилейное",
	"Южное",
	"Южно-Сухокумск",
	"Юный Пахарь",
	"Юргулиг",
	"Юрковка",
	"Юхари-Архит",
	"Юхари-Захит",
	"Юхарикартас",
	"Юхари-Стал",
	"Юхари-Ярак",
	"Ягдыг",
	"Ялак",
	"Ямансу",
	"Янгикент",
	"Янгылбай",
	"Яраг",
	"Яраг-Казмаляр",
	"Яраймахи",
	"Яргиль",
	"Яркуг",
	"Яруквалар",
	"Ясная Поляна"
];
// eslint-disable-next-line import/no-anonymous-default-export
export default {
	org,
	terr
};
