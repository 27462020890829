/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {Record} from "objectum-client";
import {store} from "../modules/mediator.js";
import {createReport, reportStyles} from "objectum-react";

export default class DeclarationModel  extends Record {
	async chiefReport () {
		let empRecord = await store.getRecord (this.emp);
		let examRecord = this.chiefExam ? await store.getRecord (this.chiefExam) : null;
		let userRecord = (await store.getRecords ({
			model: "objectum.user",
			filters: [
				["emp", "=", this.emp]
			]
		})) [0];
		let {
			eduRecords, trainingRecords, retrainingRecords, rewardRecords, crimeRecords, languageRecords, electionRecords, jobRecords
		} = await empRecord.loadChiefData ();
		let font = {
			name: "Arial",
			size: 10
		};
		let alignment = {
			vertical: "top",
			horizontal: "left",
			wrapText: true
		};
		Object.assign (reportStyles, {
			fio: {
				font: {name: "Arial", size: 14, bold: true}
			},
			title: {
				font: {name: "Arial", size: 12, bold: true}
			},
			border_bold: {
				font: {name: "Arial", size: 10, bold: true},
				border: {
					top: {style: "thin"},
					left: {style: "thin"},
					bottom: {style: "thin"},
					right: {style: "thin"}
				},
				alignment
			},
			border_bold_gray: {
				font: {name: "Arial", size: 10, bold: true},
				border: {
					top: {style: "thin"},
					left: {style: "thin"},
					bottom: {style: "thin"},
					right: {style: "thin"}
				},
				fill: {
					type: "pattern",
					pattern: "solid",
					fgColor: {argb: "e2e3e4"}
				},
				alignment
			},
			border_gray: {
				font: {name: "Arial", size: 10},
				border: {
					top: {style: "thin"},
					left: {style: "thin"},
					bottom: {style: "thin"},
					right: {style: "thin"}
				},
				fill: {
					type: "pattern",
					pattern: "solid",
					fgColor: {argb: "e2e3e4"}
				},
				alignment
			}
		});
		await store.getDict ("d.person.language");
		await store.getDict ("d.person.languageLevel");

		async function createRow (l) {
			let row = [];

			for (let i = 0; i < l.length; i ++) {
				let {l: label, r: record, p: property, cs: colSpan, npp, merge} = l [i];
				let value = record ? record [property] : "";

				if (value) {
					let model = store.getModel (record._model);
					let type = model.properties [property]?.type;

					if (type >= 1000) {
						if (!store.dict [type]) {
							await store.getDict (type);
						}
						value = store.dict [type][value]?.name;
					}
					if (type == 3) {
						value = typeof (value) == "string" ? new Date (value) : value;
						value = value.toLocaleDateString ();
					}
				}
				if (npp > 0) {
					row.push ({text: npp, style: "border_bold_gray", rowSpan: 3});
				}
				let labelColSpan = i ? 1 : 2;

				if (labelColSpan == 2 && npp >= 0) {
					labelColSpan = 1
				}
				if (merge) {
					labelColSpan = 2;
				}
				row.push ({text: label, style: "border_bold_gray", colSpan: labelColSpan});

				if (!merge) {
					row.push ({text: value, style: "border", colSpan});
				}
			}
			return row;
		}
		let rows = [
			[{text: `${empRecord.surname} ${empRecord.forename} ${empRecord.patronymic || ""}`, style: "fio", colSpan: 7}],
			[],
			await createRow ([
				{l: "Логин", r: userRecord, p: "login"},
				{l: "Прет. должность", r: empRecord, p: "allegedPost"},
				{l: "Дата диаг. упр. комп.", r: examRecord, p: "date"}
			]),
			await createRow ([
				{l: "ID", r: this, p: "id"},
				{l: "Прет. учреждение", r: empRecord, p: "allegedOrg"},
				{l: "Результат диаг. упр. комп.", r: this, p: "examScore"}
			], true),
			await createRow ([
				{l: "Роль", r: userRecord, p: "role"},
				{l: "Телефон", r: empRecord, p: "phone"},
				{l: "Адрес регистрации", r: empRecord, p: "regAddress"}
			]),
			await createRow ([
				{l: "Статус", r: this, p: "nomination"},
				{l: "Дата рождения", r: empRecord, p: "birthdate"},
				{l: "Адрес проживания", r: empRecord, p: "address"},
			], true),
			await createRow ([
				{l: "Email", r: userRecord, p: "email"},
				{l: "Место рождения", r: empRecord, p: "birthplace"},
				{l: "Педагогический стаж", r: empRecord, p: "pedExp"}
			]),
			[],
			[{text: "Образование", style: "title", colSpan: 7}],
			[]
		];
		if (!eduRecords.length) {
			rows = [
				...rows,
				[{text: "Нет сведений", style: "border", colSpan: 7}]
			];
		}
		for (let i = 0; i < eduRecords.length; i ++) {
			let record = eduRecords [i];

			rows = [
				...rows,
				await createRow ([
					{l: "Учебное заведение", r: record, p: "orgStr"},
					{l: "Квалификация", r: record, p: "qualificationStr"},
					{l: "Дата поступления", r: record, p: "startYear"}
				]),
				await createRow ([
					{l: "Направление", r: record, p: "direction"},
					{l: "Факультет", r: record, p: "faculty"},
					{l: "Дата окончания", r: record, p: "endYear"}
				], true)
			]
		}
		rows = [
			...rows,
			[],
			[{text: "Научная деятельность", style: "title", colSpan: 7}],
			[],
			await createRow ([
				{l: "Ученая степень", r: empRecord, p: "scienceDegree"},
				{l: "Дата присуждения", r: empRecord, p: "degreeDate"},
				{l: "Рег. номер", r: empRecord, p: "degreeRegNum"}
			]),
			await createRow ([
				{l: "Тема работы", r: empRecord, p: "degreeTheme", cs: 3},
				{l: "Город", r: empRecord, p: "degreeCity"}
			], true),
			[],
			await createRow ([
				{l: "Ученое звание", r: empRecord, p: "scienceRank"},
				{l: "Дата присуждения", r: empRecord, p: "rankDate"},
				{l: "Рег. номер", r: empRecord, p: "rankRegNum"}
			]),
			await createRow ([
				{l: "Тема работы", r: empRecord, p: "rankTheme", cs: 5}
			], true),
			[]
		];
		let articleUrl = JSON.parse (empRecord.articleUrl || "[]");

		for (let i = 0; i < articleUrl.length; i ++) {
			rows.push ([
				{text: "Статья", style: "border_bold", colSpan: 2},
				{text: articleUrl [i].article, style: "border", colSpan: 5}
			]);
			//], i % 2);
		}
		rows = [
			...rows,
			[],
			[{text: "Повышение квалификации", style: "title", colSpan: 7}],
			[]
		];
		if (!trainingRecords.length) {
			rows = [
				...rows,
				[{text: "Нет сведений", style: "border", colSpan: 7}]
			];
		}
		for (let i = 0; i < trainingRecords.length; i ++) {
			let record = trainingRecords [i];

			rows = [
				...rows,
				await createRow ([
					{l: "Программа курса", r: record, p: "name", cs: 3},
					{l: "Дата начала", r: record, p: "startDate"}
				], !(i % 2)),
				await createRow ([
					{l: "Учебное заведение", r: record, p: "place", cs: 3},
					{l: "Дата окончания", r: record, p: "endDate"}
				], i % 2),
				await createRow ([
					{l: "Рег. номер", r: record, p: "regNum"},
					{l: "Номер удостоверения", r: record, p: "docNum"},
					{l: "Кол. часов", r: record, p: "hours"}
				], !(i % 2))
			]
		}
		rows = [
			...rows,
			[],
			[{text: "Переподготовка", style: "title", colSpan: 7}],
			[]
		];

		if (!retrainingRecords.length) {
			rows = [
				...rows,
				[{text: "Нет сведений", style: "border", colSpan: 7}]
			];
		}
		for (let i = 0; i < retrainingRecords.length; i ++) {
			let record = retrainingRecords [i];

			rows = [
				...rows,
				await createRow ([
					{l: "Программа курса", r: record, p: "name", cs: 3},
					{l: "Дата начала", r: record, p: "startDate"}
				], !(i % 2)),
				await createRow ([
					{l: "Учебное заведение", r: record, p: "place", cs: 3},
					{l: "Дата окончания", r: record, p: "endDate"}
				], i % 2),
				await createRow ([
					{l: "Рег. номер", r: record, p: "regNum"},
					{l: "Номер удостоверения", r: record, p: "num"},
					{l: "Кол. часов", r: record, p: "hours"}
				], !(i % 2))
			];
		}
		rows = [
			...rows,
			[],
			[{text: "Сведения о дисциплинарной, материальной, гражданско-правовой, административнойи уголовной ответственности", style: "title", colSpan: 7}],
			[]
		];
		if (!crimeRecords.length) {
			rows = [
				...rows,
				[{text: "Нет сведений", style: "border", colSpan: 7}]
			];
		}
		for (let i = 0; i < crimeRecords.length; i ++) {
			let record = crimeRecords [i];

			rows = [
				...rows,
				await createRow ([
					{l: "Вид ответственности", r: record, p: "docType", cs: 5}
				], i % 2)
			];
		}
		rows = [
			...rows,
			[],
			[{text: "Награды и почетные звания", style: "title", colSpan: 7}],
			[]
		];
		if (!rewardRecords.length) {
			rows = [
				...rows,
				[{text: "Нет сведений", style: "border", colSpan: 7}]
			];
		}
		for (let i = 0; i < rewardRecords.length; i ++) {
			let record = rewardRecords [i];

			rows = [
				...rows,
				await createRow ([
					{l: "Уровень", r: record, p: "level"},
					{l: "Награда", r: record, p: "reward", cs: 3}
				], i % 2)
			];
		}
		rows = [
			...rows,
			[],
			[{text: "Владение иностранными языками", style: "title", colSpan: 7}],
			[]
		];
		if (!languageRecords.length) {
			rows = [
				...rows,
				[{text: "Нет сведений", style: "border", colSpan: 7}]
			];
		}
		for (let i = 0; i < languageRecords.length; i ++) {
			let record = languageRecords [i];

			rows = [
				...rows,
				[
					{text: store.dict ["d.person.language"][record.language]?.name, style: i % 2 ? "border_bold_gray" : "border_bold", colSpan: 2},
					{text: store.dict ["d.person.languageLevel"][record.languageLevel]?.name, style: i % 2 ? "border_gray" : "border"}
				]
			];
		}
		rows = [
			...rows,
			[],
			[{text: "Сведения о работе", style: "title", colSpan: 7}],
			[]
		];
		if (!jobRecords.length) {
			rows = [
				...rows,
				[{text: "Нет сведений", style: "border", colSpan: 7}]
			];
		}
		for (let i = 0; i < jobRecords.length; i ++) {
			let record = jobRecords [i];

			rows = [
				...rows,
				await createRow ([
					{l: "Место работы", r: record, p: "place", cs: 3, npp: i + 1},
					{l: "Дата поступления", r: record, p: "startDate"}
				], !(i % 2)),
				await createRow ([
					{l: "Должность", r: record, p: "postStr", cs: 3, npp: 0},
					record.untilNow ? {l: "По настоящее время", merge: true} : {l: "Дата увольнения", r: record, p: "endDate"}
				], i % 2),
				await createRow ([
					{l: "Основание", r: record, p: "reason", cs: 5, npp: 0}
				], !(i % 2))
			];
		}
		if (empRecord.noJob) {
			rows = [
				...rows,
				[
					{text: jobRecords.length + 1, style: "border_bold_gray"},
					{text: "Временно не работаю", style: "border_bold_gray", colSpan: 6}
				],
			];

		}
		rows = [
			...rows,
			[],
			[{text: "Сведения об участии в выборных органах государственной власти, муниципального управления", style: "title", colSpan: 7}],
			[]
		];
		if (!electionRecords.length) {
			rows = [
				...rows,
				[{text: "Нет сведений", style: "border", colSpan: 7}]
			];
		}
		for (let i = 0; i < electionRecords.length; i ++) {
			let record = electionRecords [i];

			rows = [
				...rows,
				[{text: record.docType, style: i % 2 ? "border_gray" : "border", colSpan: 7}]
			];
		}
/*
		rows = [
			...rows,
			[],
			[{text: "Текущее место работы", style: "title", colSpan: 7}],
			[],
			await createRow ([
				{l: "Территория", r: empRecord, p: "terr"},
				{l: "Учреждение", r: empRecord, p: empRecord.orgStr ? "orgStr" : "org"},
				{l: "Должность", r: empRecord, p: empRecord.postStr ? "postStr" : "post"}
			])
		];
*/
		rows = [
			...rows,
			[],
			[{text: "Справка об отсутствии судимости", style: "title", colSpan: 7}],
			[],
			[{text: empRecord.noCrimeFile ? "Прикреплена к заявлению" : "Не прикреплена к заявлению", style: "border", colSpan: 7}]
		];
		createReport ({
			rows,
			columns: [3, 20, 30, 20, 30, 25, 30],
			font,
			worksheetOpts: {
				pageSetup: {
					margins: {
						left: 0.4, right: 0.4,
						top: 0.4, bottom: 0.4,
						header: 0.1, footer: 0.1
					},
					orientation: "portrait", fitToWidth: 1
				}
			}
		});
	}

	async expertReport () {
		let data = [];
		let empRecord = await store.getRecord (this.emp);
		let post = empRecord.post ? (await store.getRecord (empRecord.post)).name : "";
		let expertRecords = await store.getRecords ({
			model: "t.declaration.emp",
			filters: [
				["declaration", "=", this.id]
			]
		});
		if (!expertRecords.length) {
			throw new Error ("Эксперты не назначены");
		}
		let unitRecords = await store.getRecords ({
			model: "t.declaration.unit",
			filters: [
				["declaration", "=", this.id]
			]
		});
		let { studentContestRecords, eduActResultRecords, personalContributionRecords } = await empRecord.loadPedagogData ();

		await store.getDict ("d.declaration.expertState");
		await store.getDict ("d.declaration.blockState");
		await store.getDict ("d.emp.eduActResult");
		await store.getDict ("d.emp.personalContribution");
		await store.getDict ("d.level");
		await store.getDict ("d.contest.result");

		for (let i = 0; i < expertRecords.length; i ++) {
			let expertRecord = expertRecords [i];
			let expertEmpRecord = await store.getRecord (expertRecord.emp);
			let rows = [], totalScore = 0, score = 0;

			eduActResultRecords.forEach (eduActResultRecord => {
				let unitRecord = unitRecords.find (unitRecord => unitRecord.eduActResult == eduActResultRecord.id);

				if (unitRecord && unitRecord.state == store.dict ["d.declaration.blockState"]["accepted"].id && unitRecord.score) {
					let record = store.dict ["d.emp.eduActResult"][eduActResultRecord.eduActResult];
					rows.push ({v0: record.code, v1: record.name, v2: unitRecord.score});

					score += unitRecord.score;

					if (score > 20) {
						score = 20;
					}
				}
			});
			totalScore += score;

			let studentContestData = {
				mun: {
					n: "2.1.1",
					name: "Результаты участия в олимпиадах, конкурсах, конференциях муниципального уровня",
					score: 0
				},
				region: {
					n: "2.1.2",
					name: "Результаты участия в олимпиадах, конкурсах, конференциях регионального уровня, утвержденные Минобрнауки РД РФ",
					score: 0
				},
				fed: {
					n: "2.1.3",
					name: "Результаты участия в олимпиадах, конкурсах, конференциях всероссийского уровня, утвержденные Минобрнауки РФ",
					score: 0
				},
				international: {
					n: "2.1.4",
					name: "Результаты участия в олимпиадах, конкурсах, конференциях международного уровня",
					score: 0
				}
			};
			score = 0;

			studentContestRecords.forEach (studentContestRecord => {
				let unitRecord = unitRecords.find (unitRecord => unitRecord.studentContest == studentContestRecord.id);

				if (unitRecord && unitRecord.state == store.dict ["d.declaration.blockState"]["accepted"].id &&
					studentContestRecord.level && studentContestRecord.result
				) {
					let map = {
						mun: {winner: 3, awardee: 2},
						region: {winner: 5, awardee: 3},
						fed: {winner: 7, awardee: 5},
						international: {winner: 10, awardee: 8}
					};
					let levelRecord = store.dict ["d.level"][studentContestRecord.level];
					let resultRecord = store.dict ["d.contest.result"][studentContestRecord.result];
					let score = map [levelRecord.code]?.[resultRecord.code];

					if (score && studentContestData [levelRecord.code].score < score) {
						studentContestData [levelRecord.code].score = score;
					}
				}
			});
			for (let level in studentContestData) {
				let o = studentContestData [level];

				if (o.score) {
					rows.push ({v0: o.n, v1: o.name, v2: o.score});

					score += o.score;

					if (score > 20) {
						score = 20;
					}
				}
			}
			totalScore += score;
			score = 0;

			personalContributionRecords.forEach (personalContributionRecord => {
				let unitRecord = unitRecords.find (unitRecord => unitRecord.personalContribution == personalContributionRecord.id);

				if (unitRecord && unitRecord.state == store.dict ["d.declaration.blockState"]["accepted"].id && unitRecord.score) {
					let record = store.dict ["d.emp.personalContribution"][personalContributionRecord.personalContribution];
					rows.push ({v0: record.code, v1: record.name, v2: unitRecord.score});

					score += unitRecord.score;

					if (score > 20) {
						score = 20;
					}
				}
			});
			totalScore += score;

			data.push ({
				emp: `${empRecord.surname} ${empRecord.forename} ${empRecord.patronymic || ""} ${post ? `, ${post}`: ""}`,
				date: new Date ().toLocaleDateString (),
				expert: `${expertEmpRecord.surname} ${expertEmpRecord.forename} ${expertEmpRecord.patronymic || ""}`,
				status: store.dict ["d.declaration.expertState"]["rejected"].id == expertRecord.state ? "об отказе в установлении" : "об установлении",
				a: rows,
				totalScore
			});
		}
		let response = await fetch ("/expert_report", {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify (data)
		});
		let blob = await response.blob ();
		let url = window.URL.createObjectURL (blob);
		let a = document.createElement ('a');
		a.href = url;
		a.download = data.length > 1 ? "experts.zip" : "expert.docx";
		document.body.appendChild (a);
		a.click ();
		a.remove ();
	}
}
