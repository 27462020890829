/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {useState, useEffect} from "react";
import PageTitle from "./PageTitle";
import {Action, Loading} from "objectum-react";
import {store} from "../modules/mediator";

export default function PostMap (props) {
	let [postRecords, setPostRecords] = useState ([]);
	let [postMapRecords, setPostMapRecords] = useState ([]);
	let [map, setMap] = useState ({});
	let [loading, setLoading] = useState (true);

	useEffect (() => {
		async function load () {
			let postRecords = await store.getRecords ({model: "d.emp.post"});
			postRecords.sort ((a, b) => a.name < b.name ? -1 : 1);
			setPostRecords (postRecords);
			let map = {};
			postRecords.forEach (record => map [record.id] = {});
			let postMapRecords = await store.getRecords ({model: "postMap"});
			setPostMapRecords (postMapRecords);
			postMapRecords.forEach (record => map [record.expertPost][record.pedagogPost] = true);
			setMap (map);
			setLoading (false);
		};
		load ();
	}, []);

	if (loading) {
		return <Loading />;
	}
	async function onSave ({progress}) {
		for (let i = 0; i < postRecords.length; i ++) {
			let expertPostRecord = postRecords [i];

			for (let j = 0; j < postRecords.length; j ++) {
				let postRecord = postRecords [j];
				let postMapRecord = postMapRecords.find (record => record.expertPost == expertPostRecord.id && record.pedagogPost == postRecord.id);
				let value = map [expertPostRecord.id][postRecord.id];

				if (postMapRecord) {
					if (!value) {
						await store.removeRecord (postMapRecord.id);
					}
				} else {
					if (value) {
						await store.createRecord ({
							_model: "postMap",
							expertPost: expertPostRecord.id,
							pedagogPost: postRecord.id
						});
					}
				}
			}
			progress ({value: i + 1, max: postRecords.length});
		}
		let records = await store.getRecords ({model: "d.emp.post"});
		records.sort ((a, b) => a.name < b.name ? -1 : 1);
		setPostRecords (records);
	};
	return <div>
		<PageTitle>Карта должностей</PageTitle>
		<div className="overflow-auto mb-2">
			<table className="table table-bordered table-sm table-striped">
				<tbody>
					<tr>
						<td colSpan={2} align="right"><b>Должность педагога</b></td>
						{postRecords.map ((postRecord, i) => {
							return <td key={i} rowSpan={2} className="sapr-vertical">{postRecord.name}</td>
						})}
					</tr>
					<tr>
						<td colSpan={2}><b>Должность эксперта</b></td>
					</tr>
					{postRecords.map ((expertPostRecord, i) => {
						return <tr key={i}>
							<td colSpan={2}>{expertPostRecord.name}</td>
							{postRecords.map ((postRecord, j) => {
								return <td key={j}>
									<input type="checkbox" checked={map [expertPostRecord.id][postRecord.id] || false} onChange={val => {
										map [expertPostRecord.id][postRecord.id] = val.target.checked;
										setMap ({...map});
									}} />
								</td>;
							})}
						</tr>;
					})}
				</tbody>
			</table>
		</div>
		<Action label="Сохранить изменения" store={store} onClick={onSave} transaction />
	</div>;
};
